import './BalanceFAQAccordion.css';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/joy';

const BalanceFAQAccordion = () => {

    return (
        <div className='balance-home-faq-container'>
            <Accordion>
                <AccordionSummary>
                    <span className='balance-accordion-header'>What does Balance do?</span>
                </AccordionSummary>
                <AccordionDetails>
                    <span className='balance-accordion-content'>For financial institutions that struggle with manual and fragmented client onboarding and account opening processes, Balance creates an intelligent Software as a Service (SaaS) for financial institutions that automate back-office tasks and centralize client data to streamline operations and client experiences.</span>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary>
                    <span className='balance-accordion-header'>What type of customer is best for Balance?</span>
                </AccordionSummary>
                <AccordionDetails>
                    <span className='balance-accordion-content'>The ideal customer for Balance is forward-thinking financial services organizations, ranging from fintech startups and credit unions to large established banks and enterprises, looking to modernize their operations and enhance efficiency. Our customers prioritize innovation in automating manual business processes, value cross-business collaboration, and seek scalable solutions to streamline operations such as onboarding (i.e., KYC, KYB), account opening, and cash management, thereby improving client experience and operational agility.</span>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary>
                    <span className='balance-accordion-header'>Why choose Balance?</span>
                </AccordionSummary>
                <AccordionDetails>
                    <span className='balance-accordion-content'>Balance stands out for these key reasons: <br></br><br></br><span className='balance-home-bold'> 1. Security and Privacy:</span> Your data's safety is our top priority.<br></br><span className='balance-home-bold'>2. Efficiency and Automation:</span> We save you time and resources.<br></br><span className='balance-home-bold'>3. Seamless Collaboration:</span> Foster teamwork across departments and clients.<br></br><span className='balance-home-bold'>4. User Friendly Design:</span> Easy to use from day one.<br></br><span className='balance-home-bold'>5. Innovation and Scalability:</span> Future proof your investment.<br></br><br></br>Balance is more than software. It's a trusted partner committed to your success in the modern financial services and customer experience landscape.</span>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary>
                    <span className='balance-accordion-header'>How do I sign up as a beta user?</span>
                </AccordionSummary>
                <AccordionDetails>
                    <span className='balance-accordion-content'>Signing up as a beta user with Balance is easy and straightforward. We welcome your interest in helping us shape the future of financial services operations and client experiences. To join as a beta user, follow these steps: <br></br><br></br><span className='balance-home-bold'>1. Visit Our Website:</span> Start by visiting our official website, where you'll find the sign-up form.<br></br><span className='balance-home-bold'>2. Complete the Sign-Up Form:</span> Fill out the required information on the beta user sign-up form. This includes your name, email address, company name, and any relevant details about your role in the financial services industry.<br></br><span className='balance-home-bold'>3. Agree to Terms and Conditions:</span> Review and accept our terms and conditions for Beta users. This step is crucial to ensure that you understand your role and responsibilities as a user.<br></br><span className='balance-home-bold'>4. Start Using:</span> Once completed, you'll gain access to our beta platform. You can begin exploring its features, providing feedback, and collaborating with our team to refine and improve the platform.<br></br><br></br>Balance is more than software. It's a trusted partner committed to your success in the modern financial services and customer experience landscape.</span>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default BalanceFAQAccordion;