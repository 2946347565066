import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useUserStore from "./store/useUserStore";

const PrivateRoute = ({ childRoute }) => {
    const navigate = useNavigate();
    const { userToken } = useUserStore();

    useEffect(() => {
        if (!userToken) {
            console.log('Navigating home!');
            navigate("/Login");
        }
    }, [])

    // if (!token) {
    //     console.log('Navigating home!');
    //     navigate("/");
    //     return null;
    // }

    return childRoute;
};

export default PrivateRoute;