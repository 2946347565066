// react pdf viewer
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { useState, useEffect } from 'react';
import '@react-pdf-viewer/core/lib/styles/index.css';
import './PopupGlobal.css';
import './DocumentPopup.css';
import PDF from '../../assets/PDF/TradeNameRegistration_example.pdf';
import driversLicense from '../../assets/PDF/sample_drivers_license.png';
import ServerClient from '../../services/api-client';

const DocumentPopup = ({idNum, fileInfo}) => {
  console.log('Document idNum is: ', idNum);
  const [isPDF, setIsPDF] = useState(false);
  const [signedUrl, setSignedUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const getSignedUrl = async () => {
      console.log('fileInfo is: ', fileInfo);
      try {
        setIsLoading(true);
        if (fileInfo?.uniqueFilename.slice(-4) === '.pdf') {
          setIsPDF(true);
        } else {
          setIsPDF(false);
        }
        const serverClient = new ServerClient('/api/S3PresignedURL');
        const req = { uniqueFilename: fileInfo?.uniqueFilename, bucket: fileInfo?.bucket }
        const res = await serverClient.post(req);
        console.log('presigned res is: ', res);
        setSignedUrl(res.data);
      } catch (err) {
        setError(true);
        console.log('err is: ', err);
      } finally {
        setIsLoading(false);
      }
    }
    getSignedUrl();
  }, [])

  if (isLoading) {
    return (
    <div className='balance-document-popup-container'>
      <div className='balance-document-popup-document balance-doocument-popup-document-loading'>
        Loading...
      </div>
    </div>
    )
  }

  if (error) {
    return (
      <div className='balance-document-popup-container'>
        <div className='balance-document-popup-document balance-doocument-popup-document-loading'>
          Unable to open this document. Please try again later.
        </div>
      </div>
      )
  }

  return ( 
    <div className='balance-document-popup-container'>
        <div className='balance-document-popup-inner-container'>
          {/* {isPDF && <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
            <Viewer fileUrl={signedUrl} />
          </Worker>} */}
          {isPDF && <div className='balance-document-popup-png-object-container'>
            <object data={signedUrl} type='application/pdf' width={'100%'} height={'100%'} ><p>Alternative text - include a link <a href={signedUrl} >to the PDF!</a></p>
            </object>
            </div>}
          {!isPDF && <div className='balance-document-popup-not-png-container'>
            <img className='balance-document-popup-png' src={signedUrl} alt='document' />
          </div>}
        </div>
    </div>
  );
  
};

export default DocumentPopup;
