import { useState, useEffect } from 'react';
import { nanoid } from 'nanoid';
import { Icon } from '@iconify/react';
import Button from '../../../../components/Button/Button';
import Laptop from '../../../../assets/Image/ClientPortal/Laptop.png';
import AppImage from '../../../../assets/Image/ClientPortal/App.png';
import './ClientPortal.css';

const ClientPortal = () => {
  const data1 = [
    {
      id: nanoid(),
      sub_title: 'Guided Application Process',
      feature_title_1: 'Step-by-Step Guidance',
      feature_title_2: 'Tooltips and Help Sections',
      feature_desc_1:
        'Navigate the application process with ease. Our portal provides clear, detailed instructions for each step, ensuring you complete your application correctly and confidently.',
      feature_desc_2:
        'Need extra help? Interactive tooltips and help sections offer additional information, examples, and clarification whenever you need it.',
      icon_1: 'fluent-mdl2:step',
      icon_2: 'material-symbols:help-outline',
    },
    {
      id: nanoid(),
      sub_title: 'Document Upload and Management',
      feature_title_1: 'Easy Upload',
      feature_title_2: 'Document Management',
      feature_title_3: 'Document Checklist',
      feature_desc_1:
        'Upload  required documents directly to the portal with support for multiple file formats including PDF, JPEG, and PNG.',
      feature_desc_2: 'Manage uploaded documents effortlessly. View, replace, or delete files as needed.',
      feature_desc_3:
        'Stay organized with our document checklist. Keep track of all required documents and know exactly what has been uploaded and what’s still needed.',
      icon_1: 'mingcute:upload-line',
      icon_2: 'ep:document',
      icon_3: 'solar:checklist-bold',
    },
    {
      id: nanoid(),
      sub_title: 'Progress Tracking',
      feature_title_1: 'Application Status',
      feature_title_2: 'Progress Indicators',
      feature_desc_1:
        'Keep tabs on a application status in real-time. See exactly where your application stands from submission to approval.',
      feature_desc_2: "Visual progress bars and percentage completions show you how much you've completed and what's left to do.",
      icon_1: 'lets-icons:status-list',
      icon_2: 'carbon:progress-bar',
    },
    {
      id: nanoid(),
      sub_title: 'Automated Notifications',
      feature_title_1: 'Submission Confirmations',
      feature_title_2: 'Status Updates',
      feature_title_3: 'Deadline Reminders',
      feature_desc_1:
        'Receive automated confirmation emails upon successful submission, including a summary of your submitted information.',
      feature_desc_2:
        'Stay informed with email notifications for any status changes, approvals, rejections, or requests for additional information.',
      feature_desc_3: 'Never miss a deadline with automated reminders for upcoming actions or required submissions.',
      icon_1: 'line-md:confirm-circle',
      icon_2: 'ic:baseline-update',
      icon_3: 'akar-icons:bell',
    },
  ];

  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <div className="balance-features-client-portal-container">
      {/* section 1 */}
      <div className="balance-features-client-portal-section-1">
        <div className="balance-features-client-portal-title-container">
          <h1 className="balance-features-client-portal-title">Client Portal</h1>
          <p className="balance-features-client-portal-desc">
            Our Client Portal is designed to provide a seamless, guided experience for clients to complete and submit applications
            effortlessly.
            <br />
            Here's how it can help you.
          </p>
          <div className={`balance-features-client-portal-image-container ${isVisible ? 'fade-in' : ''}`}>
            <img src={Laptop} alt="Image" className="balance-features-client-portal-image-laptop" />
            <img src={AppImage} alt="Image" className="balance-features-client-portal-image-tablet" />
          </div>
        </div>
      </div>
      {/* section 2 */}
      <div className="balance-features-client-portal-section-2">
        <div className="balance-features-client-portal-left-container">
          <div className="balance-features-client-portal-section-2-title-container">
            <h1 className="balance-features-client-portal-section-2-title">Features</h1>
          </div>
          <div>
            {data1.map((feature, index) => (
              <div className="balance-features-client-portal-section-2-inner-container">
                <h1 className="balance-features-client-portal-section-2-sub-title">{feature.sub_title}</h1>
                <p className="balance-features-client-portal-section-2-desc">{feature.desc}</p>
                <div className="balance-features-client-portal-box-container">
                  <div className="feature-box">
                    <div className="feature-box-title-container">
                      <Icon icon={feature.icon_1} className="balance-client-portal-feature-box-icon" />
                      <h3>{feature.feature_title_1}</h3>
                    </div>
                    <p className="feature-box-desc">{feature.feature_desc_1}</p>
                  </div>
                  <div className="feature-box">
                    <div className="feature-box-title-container">
                      <Icon icon={feature.icon_2} className="balance-client-portal-feature-box-icon" />
                      <h3>{feature.feature_title_2}</h3>
                    </div>
                    <p className="feature-box-desc">{feature.feature_desc_2}</p>
                  </div>
                  <div className="feature-box">
                    <div className="feature-box-title-container">
                      <Icon icon={feature.icon_3} className="balance-client-portal-feature-box-icon" />
                      <h3>{feature.feature_title_3}</h3>
                    </div>
                    <p className="feature-box-desc">{feature.feature_desc_3}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="balance-features-client-portal-section-2-inner-container balance-features-client-portal-section-2-right-container">
          <img src={AppImage} alt="Image" className="image" />
        </div>
      </div>
      {/* section 3 */}
      <div className="balance-features-client-portal-section-3">
        <div className="balance-features-client-portal-section-3-left-container"></div>
        <div className="balance-features-client-portal-section-3-right-container">
          <h1 className="balance-features-client-portal-section-3-title">Enhance your experience</h1>
          <p className="balance-features-client-portal-section-3-desc">
            The Client Portal is here to make your clients application process smoother and more efficient. By providing clear
            guidance, easy document management, real-time progress tracking, and timely notifications, we ensure that your clients
            have everything they need to complete your financial institutions application with ease.
            <br />
            <br />
            Explore the Client Portal today and experience a new level of convenience in your application process.
          </p>
          <div className="balance-features-client-portal-section-2-button-container">
            {/* <Button
              buttonName="Get started"
              className="balance-features-client-portal-section-3-button balance-global-movable-button"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientPortal;
