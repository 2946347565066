import React from 'react';
import useModalStore from '../../store/useModalStore';
import './PopupGlobal.css';
import TemplateIconBlue from '../../assets/Icon/Template_Icon_Blue.svg';

const CreatePopup = () => {
  const setSelectedTemplate = useModalStore(
    (state) => state.setSelectedTemplate
  );

  // when click navigates to create type popup
  const handleSelectTemplate = () => {
    setSelectedTemplate('CreateTypePopup');
  };

  return (
    <>
      <div className='balance-create-popup-title-container'>
        <h2 className='balance-create-popup-title balance-create-popup-title-center'>
          Create a new application template
        </h2>
      </div>
      <div className='balance-create-popup-button-container'>
        <div
          className='balance-create-popup-button'
          onClick={handleSelectTemplate}
        >
          <img src={TemplateIconBlue} alt='Icon' />
          <div className='balance-create-popup-button-title-container'>
            <p className='balance-create-popup-button-title'>
              Start from a Balance template
            </p>
            <p className='balance-create-popup-button-sub-title'>
              Kickstarting your process with Balance
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePopup;
