import './FormLogo.css';
import BalanceBigLogo from '../../assets/Logo/Balance_Big_Logo.svg';

const FormLogo = () => {
  return (
    <div className='balance-main-logo-default-container'>
      <div className='balance-main-logo-default-logo-container'>
        <img className='balance-main-logo-default-logo' src={BalanceBigLogo} alt='Logo' />
      </div>
    </div>
  );
};

export default FormLogo;
