import useFormBuilderStore from '../../../../../store/useFormBuilderStore';
import { useHashNestTab } from '../../../../../hooks/useHashTab';
import './ShareLeft.css';
import '../FormBuilder.css';

const ShareLeft = () => {
  const { selectedOption, setSelectedOption } = useFormBuilderStore();

  // set # to link hook
  const { options } = useHashNestTab(
    selectedOption,
    setSelectedOption,
    'Email',
    'Link'  // this opetion was removed in useHashTab.js
  );

  const handleClickSelectShare = (option) => {
    setSelectedOption(option);
  };

  return (
    <div className='balance-sidebar-left-share-container'>
      {options.map((option) => (
        <div
          key={option}
          onClick={() => handleClickSelectShare(option)}
          className={`balance-sidebar-left-share-link-container ${
            selectedOption === option ? 'share-selected' : ''
          }`}
        >
          <a
            href={`#${option}-Form`}
            className='balance-form-builder-header-middle-share balance-share-link'
          >
            Share by {option.toLowerCase()}
          </a>
        </div>
      ))}
    </div>
  );
};

export default ShareLeft;
