import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import './index.css';
// 2 routes
import App from './App';
import TemplateRoutes from './TemplateRoutes';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path='/*' element={<AppAndTemplateRoutes />} />
        </Routes>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);

function AppAndTemplateRoutes() {
  const isTemplateRoute = window.location.pathname.includes('/Templates');

  return <>{isTemplateRoute ? <TemplateRoutes /> : <App />}</>;
}
