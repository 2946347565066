import './RedirectButton.css';
import { useNavigate } from 'react-router-dom';

const RedirectButton = ({ pathName, buttonText }) => {
  const navigate = useNavigate();
  return (
    <div className="redirect-button-container">
      <button className="redirect-button" onClick={() => navigate(pathName)}>
        {buttonText}
      </button>
    </div>
  );
};

export default RedirectButton;
