import { create } from 'zustand';

const useModalStore = create((set) => ({
  isModalOpen: false,
  isSelectedTemplate: null,
  selectedStatus: null,

  openModal: () => set({ isModalOpen: true }),
  closeModal: () => set({ isModalOpen: false }),
  setSelectedTemplate: (template) => set({ isSelectedTemplate: template }),
  setSelectedStatus: (status) => set({ selectedStatus: status }),
}));

export default useModalStore;
