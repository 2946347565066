import { useMutation } from '@tanstack/react-query';
import ServerClient from '../services/api-client';
import useUserStore from '../store/useUserStore';

// update card
export function useUpDatePaymentMethod() {
  const { userEmail } = useUserStore();

  return useMutation({
    mutationFn: async ({ paymentMethodId }) => {
      const serverClient = new ServerClient('/api/updatePaymentMethod');
      const res = await serverClient.post({ userEmail, paymentMethodId});
      return { data: res.data };
    },
    onSuccess: () => {
      console.log('updatePaymentMethod hooks OK.');
    },
    onError: (error) => {
      console.error('Error updatePaymentMethod hooks.', error);
    },
  });
}
