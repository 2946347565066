import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import ServerClient from '../../../services/api-client';
import useUserStore from '../../../store/useUserStore';
import useDropdown from '../../../hooks/useDropdown';
import Button from '../../../components/Button/Button';
import Dropdown from '../../../components/Dropdown/Dropdown';
import './UserPermissions.css';
import '../../../components/Grid/Grid.css';
import DotIcon from '../../../assets/Icon/3Dot_Button_Icon.svg';

const UserPermissions = () => {
  const { userId, userData, setUserData, setSelectedUserId, userCompany } = useUserStore();
  // custom hook
  const { openDropdown, handleClickDot, handleClickOutside, dropdownRef } = useDropdown();

  const navigate = useNavigate();

  // retrieve coworkers
  useEffect(() => {
    const fetchData = async () => {
      try {
        const serverClient = new ServerClient('/api/retrieveCoworkers');
        const res = await serverClient.post({
          userId: userId,
          companyId: userCompany,
        });
        if (res?.data?.data) {
          setUserData(res.data.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleUserClick = (selectedUserId) => {
    // console.log('selectedUserId:', selectedUserId);
    setSelectedUserId(selectedUserId);
    navigate(`/Hub/Settings/User-Permissions/User-Access`);
  };

  // test use
  const handleCheckboxChange = (userId) => {
    console.log('Checked userId:', userId);
  };
  // console.log('userData:', userData);

  return (
    <div className="balance-user-permissions-container balance-settings-container" onClick={handleClickOutside}>
      <div className="balance-settings-back-button-container" onClick={() => navigate('/Hub/Settings')}>
        <Icon icon="ic:sharp-arrow-back-ios" className="balance-settings-back-button" />
      </div>
      <div>
        <h1>User Permissions</h1>
        <p className="balance-user-permissions-description">Assign, Remove, View and Manage User Role Permissions.</p>
      </div>
      {/* grid table */}
      <div className="balance-grid-user-permissons-table">
        <div className="balance-grid-dashboard-header">Name</div>
        <div className="balance-grid-dashboard-header">Role</div>
        <div className="balance-grid-dashboard-header">Info</div>
        <div className="balance-grid-dashboard-header"></div>
        {/* grid record */}
        {userData?.length > 0 &&
          userData.map((user, index) => {
            const dropdownButtons = [
              {
                label: 'Edit',
                onClick: () => handleUserClick(user.userId),
              },
            ];
            return (
              <React.Fragment key={index}>
                <div className="balance-grid-cell">
                  <input type="checkbox" className="balance-user-grid-user-permissions-checkbox" onChange={() => handleCheckboxChange(user.userId)} />
                  <span className="balance-grid-user-permissions-name" onClick={() => handleUserClick(user.userId)}>
                    {user.name}
                  </span>
                </div>
                <div className="balance-grid-cell">
                  <span className="balance-grid-user-permissions-role">{user.role}</span>
                </div>
                <div className="balance-grid-cell">
                  <span className="balance-grid-user-permissons-info">{user.info}</span>
                </div>
                <div className="balance-grid-cell">
                  <Button icon={DotIcon} iconPosition="after" className="balance-grid-dot-button" onClick={(e) => handleClickDot(user.userId, e)} />
                  {/* dropdown */}
                  {openDropdown[user.userId] && <Dropdown item={openDropdown[user.userId]} openDropdown={openDropdown} dropdownRef={dropdownRef} dropdownButtons={dropdownButtons} />}
                </div>
              </React.Fragment>
            );
          })}
      </div>
      {/* table footer */}
      <div className="balance-grid-user-permissions-footer">
        <div className="balance-grid-footer-pages">Page 1 of 1</div>
        <div className="balance-grid-footer-button">
          <Button buttonName="Previous" className="balance-grid-footer-previous-button" />
          <Button buttonName="Next" className="balance-grid-footer-next-button" />
        </div>
      </div>
    </div>
  );
};

export default UserPermissions;
