import React from 'react';
import Button from '../../../components/Button/Button';
import './PriceCard.css';
import CheckIcon from '../../../assets/Icon/Check_Icon.svg';
import { useNavigate } from 'react-router-dom';

const cardsData = [
  {
    id: 1,
    name: 'Free',
    price: '$0',
    desc: 'For small financial institutions and startups.',
    buttonName: 'Start for free',
    buttonStyle: 'button-free',
    buttonOnClickLink: '/Sign-up',  
    color: 'var(--gray-5)',
    features: [
      'Max 5 users.',
      '10 applications.',
      'Enterprise-level security protocols, including end-to-end encryption and compliance with major US regulations (e.g., GLBA, SOX).',
      'Basic usage statistics.',
      'Basic data export.',
    ],
  },
  {
    id: 2,
    name: 'Pro',
    price: '$99',
    desc: 'For growing financial institutions needing advanced features and integrations.',
    buttonName: 'Get started',
    buttonStyle: 'button-pro',
    buttonOnClickLink: '/Pro', 
    color: '#00488E',
    features: [
      ' Access to a wider range of application templates.',
      '100 applications.',
      'Enterprise-level security protocols, including end-to-end encryption and compliance with major US regulations (e.g., GLBA, SOX).',
      'Integrations with popular CRMs + Slack.',
      'Download reports as CSV and basic API access for data export.',
    ],
  },
  {
    id: 3,
    name: 'Enterprise',
    price: 'Contact us',
    desc: 'For large institutions requiring comprehensive solutions and extensive integrations.',
    buttonName: 'Contact sales',
    buttonStyle: 'button-enterprise',
    buttonOnClickLink: '/Sign-up', 
    color: 'var(--blue-1)',
    features: [
      'Access to all application templates and the ability to create custom templates',
      'Enterprise-level security protocols, including end-to-end encryption and compliance with major US regulations (e.g., GLBA, SOX) and industry standards (e.g., SOC 2, ISO 27001).',
      'Included integrations with popular CRMs and other financial systems.',
      'Comprehensive analytics with custom reporting and real-time insights.',
      'Full API access for real-time data export and integration with any system.',
      'Unlimited application processing.',
    ],
  },
];

const redirectEnterpriseClick = () => {
  window.open('https://calendly.com/yem-ade/30min', '_blank');
};

const PriceCard = () => {
  const navigate = useNavigate();
  return (
    <>
      {cardsData.map((card, index) => (
        <div key={card.id} className="card-container">
          <div className="card-inner-container">
            <p className="card-name" style={{ color: card.color }}>
              {card.name}
            </p>
            <div className="card-price-container">
              <span className="card-price" style={{ fontSize: card.price === 'Contact us' ? '18px' : '24px' }}>
                {card.price}
                {(index === 0 || index === 1) && <span className="card-per-month">/ per month</span>}
              </span>
            </div>
            <p className="card-desc">{card.desc}</p>
            {card.name !== 'Enterprise' ? <Button buttonName={card.buttonName} className={`${card.buttonStyle} balance-global-movable-button`} onClick={() => navigate(card.buttonOnClickLink)} /> : <Button buttonName={card.buttonName} className={card.buttonStyle} onClick={redirectEnterpriseClick} />}
          </div>
          {card.features.map((feature, index) => (
            <div key={index} className="card-features-container">
              <img src={CheckIcon} alt="Icon" />
              <span>{feature}</span>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default PriceCard;
