import useNotificationStore from '../store/useNotificationStore';

export function usePagination(pageNumber, pageSize) {
  const { notifications } = useNotificationStore();

  const startPage = (pageNumber - 1) * pageSize;
  const endPage = Math.min(startPage + pageSize, notifications?.length);
  const paginated = notifications?.slice(startPage, endPage);

  return {
    data: {
      data: paginated,
      totalCount: notifications?.length,
    },
  };
}
