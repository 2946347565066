import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useForm } from 'react-hook-form';
import { useRecieveEmails } from '../../../hooks/useEmailHooks';
import Button from '../../../components/Button/Button';
import Modal from '../../../components/Popup/Modal';
import FormSentPopup from '../../../components/Popup/FormSentPopup';
import FormSentFailedPopup from '../../../components/Popup/FormSentFailedPopup';
import LoadingPopup from '../../../components/Popup/LoadingPopup';
import './Support.css';
import './Settings.css';

const Support = () => {
  const [isSentModalOpen, setSentModalOpen] = useState(false);
  const [errorSendingForm, setErrorSendingForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { mutate: emailMutate } = useRecieveEmails();

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitted },
  } = useForm({ mode: 'onChange' });

  const onEmailSubmit = (data) => {
    // console.log('data', data);
    setIsLoading(true);

    emailMutate(data, {
      onSuccess: () => {
        console.log('Email sent.');

        setIsLoading(false);
        setSentModalOpen(true);
        reset();
      },
      onError: (error) => {
        console.error('Email sending failed:', error);
        setErrorSendingForm(true);
        setIsLoading(false);
      },
    });
  };
  // close modal
  const closeSentModal = () => {
    setSentModalOpen(false);
  };

  return (
    <div className="support-page-container balance-settings-container">
      <div className="balance-settings-back-button-container" onClick={() => navigate('/Hub/Settings')}>
        <Icon icon="ic:sharp-arrow-back-ios" className="balance-settings-back-button" />
      </div>
      <div className="support-page-title-container">
        <h1 className="balance-settings-title">Support and Feedback</h1>
        <p className="balance-settings-description">Access our support systems to enahnce your Balance experience.</p>
        {/* line */}
        <div className="balance-settings-line"></div>
      </div>
      <div className="support-page-subtitle-container">
        <h3 className="support-page-subtitle">Contact Us</h3>
        <p className="support-page-desc">To contact our support team, please fill our the form below.</p>
      </div>
      <div className="support-page-form-section-container">
        <div className="support-page-form-container">
          <form onSubmit={handleSubmit(onEmailSubmit)} className="support-page-form">
            {/* email */}
            <label htmlFor="email" className="support-page-email-label">
              Email
              {isSubmitted && errors.email && <span className="balance-validation-error-marker">*</span>}
            </label>
            <input type="text" id="email" className="balance-global-input support-page-input" {...register('email', { required: true })} />
            {/* subject */}
            <label htmlFor="subject" className="support-page-email-label">
              Subject
              {isSubmitted && errors.subject && <span className="balance-validation-error-marker">*</span>}
            </label>
            <input type="text" id="subject" className="balance-global-input support-page-input" {...register('subject', { required: true })} />
            {/* message */}
            <label htmlFor="message" className="support-page-email-label">
              Message
              {isSubmitted && errors.message && <span className="balance-validation-error-marker">*</span>}
            </label>
            <textarea id="message" rows={10} cols={50} {...register('message', { required: true })} />
            <Button buttonName="Send" className="support-page-send-button balance-global-blue-button" />
          </form>
        </div>
      </div>
      {/* popup after email sent */}
      {isSentModalOpen && (
        <Modal onClose={closeSentModal}>
          <FormSentPopup isSupport={true} />
        </Modal>
      )}
      {errorSendingForm && (
        <Modal onClose={closeSentModal}>
          <FormSentFailedPopup />
        </Modal>
      )}
      {isLoading && (
        <Modal onClose={closeSentModal}>
          <LoadingPopup />
        </Modal>
      )}
    </div>
  );
};

export default Support;
