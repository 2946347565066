import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import './Integration.css';
import './Settings.css';

const Integrations = () => {
  const navigate = useNavigate();

  return (
    <div className="integration-settings-container balance-settings-container">
      <div className="balance-settings-back-button-container" onClick={() => navigate('/Hub/Settings')}>
        <Icon icon="ic:sharp-arrow-back-ios" className="balance-settings-back-button" />
      </div>
      <div className="integration-settings-title-container">
        <h1 className="balance-settings-title">Integrations</h1>
        <p className="balance-settings-description">Manage which apps you have integrated with Balance.</p>
        {/* line */}
        <div className="balance-settings-line"></div>
      </div>
    </div>
  );
};

export default Integrations;
