import { create } from 'zustand';

const useFormBuilderStore = create((set) => ({
  sections: [{ sectionTitle: '', sectionDesc: '', sectionInputs: [] }],
  activeCreateTab: 'Create',
  activeShareTab: 'Share',
  leftSidebarOpen: true, // toggle form builder left bar
  rightSidebarOpen: true, // toggle form builder right bar
  editTemplateName: 'Create New',
  showFormModal: false,
  templateType: '', // BusinessChecking or BusinessFinancing
  businessEntityType: '', // for checking, is it: LLC, Sole proprietorship, etc
  country: '',
  inputFields: [],
  inputTypes: [], // short text type, long text type
  selectedOption: 'email', // email tab or link tab
  selectedSectionIndex: 0,
  selectedQuestionIndex: null,
  success: '', // toast success
  linkData: {}, // generated link
  publishTime: '', // the time template was created
  templatesData: [], // saved form data from mongo
  selectedCountry: '', // selected country from dropdown
  selectedData: [], // selected data from checkbox
  selectedEditData: [], // selected data from edit dropdown
  selectedOpenData: [], // selected data from open dropdown
  selectedViewData: [], // selected data from view dropdown
  usersToShareWith: [], // array of users we want to share the newly created form with
  selectedStatusTab: 'Templates',

  addSection: () => set((state) => ({ sections: [...state.sections, blankSection] })),
  setActiveCreateTab: (tab) => set({ activeCreateTab: tab }),
  setActiveShareTab: (tab) => set({ activeShareTab: tab }),
  setSections: (newSections) => set({ sections: newSections }),
  toggleLeftSidebar: () => set((state) => ({ leftSidebarOpen: !state.leftSidebarOpen })), // leftSidebarOpen
  // setLeftSidebarOpen: (isOpen) => set({ leftSidebarOpen: isOpen }),
  toggleRightSidebar: () => set((state) => ({ rightSidebarOpen: !state.rightSidebarOpen })), // rightSidebarOpen
  // setRightSidebarOpen: (isOpen) => set({ rightSidebarOpen: isOpen }),
  setTemplateType: (type) => set({ templateType: type }),
  setBusinessEntityType: (type) => set({ businessEntityType: type }),
  setCountry: (country) => set({ country: country }),
  setEditTemplateName: (newName) => set({ editTemplateName: newName }),
  setShowFormModal: (newForm) => set({ showFormModal: newForm }),
  setInputFields: (newInputFields) => set({ inputFields: newInputFields }),
  setSelectedOption: (option) => set({ selectedOption: option }),
  resetQuestionIndex: () => set({ selectedQuestionIndex: null }),
  setQuestionIndex: (newIndex) => set({ selectedQuestionIndex: newIndex }),
  setSectionIndex: (newIndex) => set({ selectedSectionIndex: newIndex }),
  setSuccess: (message) => set({ success: message }), // toast success
  setUsersToShareWith: (newArray) => set({ usersToShareWith: newArray }),
  resetTemplate: () => {
    set(() => ({
      sections: [{ sectionTitle: '', sectionDesc: '', sectionInputs: [] }],
      templateType: '',
      businessEntityType: '',
      selectedCountry: '',
      selectedSectionIndex: 0,
      selectedQuestionIndex: null,
      usersToShareWith: [],
    }));
  },
  // resetTemplate: () => {set(() => ({sections: [{ sectionTitle: '', sectionDesc: '', sectionInputs: [] }], editTemplateName: 'Create New', businessEntityType: '', country: '', selectedSectionIndex: 0, selectedQuestionIndex: null}))},
  setLinkData: (newLinkData) => set({ linkData: newLinkData }), // generated link data
  setPublishTime: (time) => set({ publishTime: time }), // the time template was created
  setTemplatesData: (data) => set({ templatesData: data }), // saved form data from mongo
  setSelectedCountry: (country) => set({ selectedCountry: country }), // selected country from dropdown
  setSelectedData: (data) => set({ selectedData: data }), // selected data from checkbox
  setSelectedEditData: (data) => set({ selectedEditData: data }), // selected data from edit dropdown
  setSelectedOpenData: (data) => set({ selectedOpenData: data }), // selected data from open dropdown
  setSelectedViewData: (data) => set({ selectedViewData: data }), // selected data from view dropdown
  setSelectedStatusTab: (tab) => set({ selectedStatusTab: tab }),

  setSectionTitle: (index, text) =>
    set((state) => ({
      sections: [
        ...state.sections.slice(0, index),
        { ...state.sections[index], sectionTitle: text },
        ...state.sections.slice(index + 1),
      ],
    })),
  setSectionDesc: (index, text) =>
    set((state) => ({
      sections: [
        ...state.sections.slice(0, index),
        { ...state.sections[index], sectionDesc: text },
        ...state.sections.slice(index + 1),
      ],
    })),
  // if you want to add the question not at the end STILL NEEDS WORK! addNewQuestion: (sectionIndex, questionIndex, newInputField) => set((state) => ({ sections: [...state.sections.slice(0, sectionIndex), {...state.sections[sectionIndex], sectionInputs: [...state.sections[sectionIndex].sectionInputs.slice(0, questionIndex), newInputField, ...state.sections[sectionIndex].sectionInputs.slice(questionIndex + 1)]}, ...state.sections.slice(sectionIndex + 1)] }))
  addNewQuestion: (sectionIndex, newInputField) =>
    set((state) => ({
      sections: [
        ...state.sections.slice(0, sectionIndex),
        {
          ...state.sections[sectionIndex],
          sectionInputs: [...state.sections[sectionIndex].sectionInputs, newInputField],
        },
        ...state.sections.slice(sectionIndex + 1),
      ],
    })),
  setQuestionTitle: (sectionIndex, questionIndex, text) =>
    set((state) => ({
      sections: [
        ...state.sections.slice(0, sectionIndex),
        {
          ...state.sections[sectionIndex],
          sectionInputs: [
            ...state.sections[sectionIndex].sectionInputs.slice(0, questionIndex),
            {
              ...state.sections[sectionIndex].sectionInputs[questionIndex],
              inputTitle: text,
            },
            ...state.sections[sectionIndex].sectionInputs.slice(questionIndex + 1),
          ],
        },
        ...state.sections.slice(sectionIndex + 1),
      ],
    })),
  toggleQuestionRequired: (sectionIndex, questionIndex, isRequired) =>
    set((state) => ({
      sections: [
        ...state.sections.slice(0, sectionIndex),
        {
          ...state.sections[sectionIndex],
          sectionInputs: [
            ...state.sections[sectionIndex].sectionInputs.slice(0, questionIndex),
            {
              ...state.sections[sectionIndex].sectionInputs[questionIndex],
              inputIsRequired: isRequired,
            },
            ...state.sections[sectionIndex].sectionInputs.slice(questionIndex + 1),
          ],
        },
        ...state.sections.slice(sectionIndex + 1),
      ],
    })),
  removeSection: (sectionIndex) =>
    set((state) => ({
      sections: [...state.sections.slice(0, sectionIndex), ...state.sections.slice(sectionIndex + 1)],
    })),
  removeQuestion: (sectionIndex, questionIndex) =>
    set((state) => ({
      sections: [
        ...state.sections.slice(0, sectionIndex),
        {
          ...state.sections[sectionIndex],
          sectionInputs: [
            ...state.sections[sectionIndex].sectionInputs.slice(0, questionIndex),
            ...state.sections[sectionIndex].sectionInputs.slice(questionIndex + 1),
          ],
        },
        ...state.sections.slice(sectionIndex + 1),
      ],
    })),
}));

const blankSection = { sectionTitle: '', sectionDesc: '', sectionInputs: [] };

export default useFormBuilderStore;
