import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import ServerClient from '../services/api-client';
import useUserStore from '../store/useUserStore';

// retrieve prebuilt templates
export function useRetrieveTemplates() {
  const { userId } = useUserStore();
  return useQuery({
    queryKey: ['templates'],
    queryFn: async () => {
      const serverClient = new ServerClient('/api/prebuiltTemplates');
      const res = await serverClient.post({ userId: userId });
      return res.data;
    },
    onError: (error) => {
      console.error('Error fetching data:', error);
    },
  });
}

// retrieve own templates
export function useGetOwnTemplates() {
  const { userId } = useUserStore();
  return useQuery({
    queryKey: ['ownTemplates', userId],
    queryFn: async () => {
      const serverClient = new ServerClient('/api/getOwnTemplates');
      const res = await serverClient.post({ userId: userId });
      return res.data;
    },
    onError: (error) => {
      console.error('Error fetching data:', error);
    },
  });
}

// retrieve shared templates
export function useGetSharedTemplates() {
  const { userId } = useUserStore();
  return useQuery({
    queryKey: ['sharedTemplates'],
    queryFn: async () => {
      const serverClient = new ServerClient('/api/db/getSharedTemplates');
      const res = await serverClient.post({ userId: userId });
      return res.data;
    },
    onError: (error) => {
      console.error('Error fetching data:', error);
    },
  });
}

// retrieve coworkers
export function useRetrieveCoworkers() {
  const { userId, userCompany } = useUserStore();
  return useQuery({
    queryKey: ['retrieveCoworkers'],
    queryFn: async () => {
      const serverClient = new ServerClient('/api/retrieveCoworkers');
      const res = await serverClient.post({ userId: userId, companyId: userCompany });
      return res.data;
    },
    onError: (error) => {
      console.error('Error fetching data:', error);
    },
  });
}

// delete own templates
export function useDeleteOwnTemplates() {
  const { userId } = useUserStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ uuid }) => {
      const serverClient = new ServerClient('/api/db/deleteOwnTemplates');
      const res = await serverClient.post({ userId, uuid });
      return { data: res.data, uuid };
    },
    onSuccess: (res) => {
      console.log(`${res.uuid} Deleted.`);
      queryClient.invalidateQueries(['ownTemplates']);
    },
    onError: (error) => {
      console.error('Error deleting template:', error);
    },
  });
}

// delete shared templates
export function useDeleteSharedTemplates() {
  const { userId } = useUserStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ uuid }) => {
      const serverClient = new ServerClient('/api/db/deleteSharedTemplates');
      const res = await serverClient.post({ uuid, userId });
      return { data: res.data, uuid };
    },
    onSuccess: (res) => {
      console.log(`${res.uuid} Deleted.`);
      queryClient.invalidateQueries(['sharedTemplates']);
    },
    onError: (error) => {
      console.error('Error deleting template:', error);
    },
  });
}

// save prebuilt templates
export function usePrebuiltTemplates() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ templateId, userId, userName }) => {
      const serverClient = new ServerClient('/api/savePrebuiltTemplates');
      const res = await serverClient.post({ templateId, userId, userName });
      return res.data;
    },
    onSuccess: (data) => {
      console.log('Prebuilt Application is created.', data);
      queryClient.invalidateQueries(['ownTemplates']);
    },
    onError: (error) => {
      console.error('Error creating prebuilt application:', error);
    },
  });
}
