import './PopupGlobal.css';
import './LoadingPopup.css';

const LoadingPopup = () => {
  return (
    <div className='balance-form-loading-popup-container'>
      <p className='balance-form-sent-popup-message'>
        Loading...
      </p>
      <div className='lds-dual-ring'></div>
    </div>
  );
};

export default LoadingPopup;