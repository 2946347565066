import React from 'react';
import useFormBuilderStore from '../../../../../store/useFormBuilderStore';
import Section from './Section';

const DesktopView = () => {
  const { sections } = useFormBuilderStore();

  return (
    <div className='desktop-view-preview-container'>
      {sections.map((section, index) => (
        <div key={index}>
          <Section section={section} sectionIndex={index} key={index} />
        </div>
      ))}
    </div>
  );
};

export default DesktopView;
