import Select from 'react-select';
import useClientStore from '../../store/useClientStore';
import useUserStore from '../../store/useUserStore';
import useModalStore from '../../store/useModalStore';
// components
import Button from '../Button/Button';
// css
import './PopupGlobal.css';

const ChangeStatusPopup = ({ onClose, closeDropdown, handleChangeStatus, isClientsPage, isTaskPage }) => {
  const { selectedUniqueId } = useClientStore();
  const { taskData } = useUserStore();
  const { selectedStatus, setSelectedStatus } = useModalStore();

  // clients page dropdown options
  const clientsOptions = [
    { value: 'Under Review', label: 'Under Review' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Denied', label: 'Denied' },
  ];
  // tasks page dropdown options
  const tasksOptions = [
    { value: 'Complete', label: 'Complete' },
    { value: 'In-progress', label: 'In-progress' },
    { value: 'Incomplete', label: 'Incomplete' },
  ];

  // do not include current status in dropdown
  const currentTask = taskData?.find((task) => task.uniqueId === selectedUniqueId);
  const currentStatus = currentTask?.status;
  const filteredTasksOptions = tasksOptions.filter((option) => option.value !== currentStatus);

  const onChangeStatus = async () => {
    // console.log(selectedStatus);
    if (!selectedStatus) return;
    try {
      await handleChangeStatus(selectedStatus.value);
      onClose(); // close popup
      if (closeDropdown) {
        closeDropdown(selectedUniqueId);
      } // close dropdown
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  // console.log('selectedUniqueId', selectedUniqueId);
  // console.log('taskData', taskData);
  // console.log('currentTask', currentTask);
  // console.log('filteredTasksOptions', filteredTasksOptions);

  return (
    <div className="balance-change-status-popup-container">
      <p className="balance-change-status-popup-title">Application Status Change</p>
      <div className="balance-change-status-popup-dropdown-container">
        <label htmlFor="status" className="balance-change-status-popup-dropdown-label">
          Status
        </label>
        {isClientsPage && (
          <Select
            id="status"
            options={clientsOptions}
            className="balance-change-status-popup-dropdown-select"
            onChange={setSelectedStatus}
          />
        )}
        {isTaskPage && (
          <Select
            id="status"
            options={filteredTasksOptions}
            className="balance-change-status-popup-dropdown-select"
            onChange={setSelectedStatus}
          />
        )}
      </div>
      <div className="balance-confirm-popup-button-container">
        <Button buttonName="Cancel" onClick={onClose} className="balance-confirm-popup-cancel-button" />
        <Button buttonName="Done" onClick={onChangeStatus} className="balance-confirm-popup-confirm-button" />
      </div>
    </div>
  );
};

export default ChangeStatusPopup;
