import useTaskStore from '../../store/useTaskStore';
import Button from '../Button/Button';
import './PopupGlobal.css';

const RejectionPopup = ({ checkConfirm, onClose, onConfirm, invalidText, handleChangeText }) => {
  const { textarea } = useTaskStore();

  return (
    <div className="balance-rejection-popup-container">
      <div className="balance-rejection-title-container">
        <p className="balance-rejection-message">{checkConfirm}</p>
      </div>
      {invalidText && <p className="reject-invalid-error">{invalidText}</p>}
      <div className="balance-rejection-textarea-container">
        <textarea className="balance-rejection-textarea" value={textarea} onChange={handleChangeText}></textarea>
      </div>
      <div className="balance-rejection-popup-button-container">
        <Button buttonName="Return" className="balance-confirm-popup-cancel-button" onClick={onClose} />
        <Button buttonName="Confirm" className="balance-confirm-popup-confirm-button" onClick={onConfirm} />
      </div>
    </div>
  );
};

export default RejectionPopup;
