import { useMutation } from '@tanstack/react-query';
import ServerClient from '../services/api-client';
import useUserStore from '../store/useUserStore';

export function useAccountDeactivate() {
  const { userId } = useUserStore();

  return useMutation({
    mutationFn: async () => {
      const serverClient = new ServerClient('/api/db/accountDeactivate');
      const res = await serverClient.post({ userId });
      return res.data;
    },
    onSuccess: () => {
      console.log('useAccountDeactivate hooks OK.');
    },
    onError: (error) => {
      console.error('Error deactivating account.', error);
    },
  });
}
