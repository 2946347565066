import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ServerClient from '../../../services/api-client';
import { Icon } from '@iconify/react';
import useUserStore from '../../../store/useUserStore';
import Button from '../../../components/Button/Button';
import './UserAccess.css';
import SaveIcon from '../../../assets/Icon/Save_Icon.svg';
const UserAccess = () => {
  // * * * commented for the purpose of use
  const [activePermissionTab, setActivePermissionTab] = useState('Templates'); // selected current tab
  const [checkedItemsTemplates, setCheckedItemsTemplates] = useState({}); // templates
  const [checkedItemsClientDataHub, setCheckedItemsClientDataHub] = useState({}); // client data hub
  const [checkedItemsTasks, setCheckedItemsTasks] = useState({}); // tasks
  const {
    userName,
    templatePermissions,
    setTemplatePermissions,
    clientPermissions,
    setClientPermissions,
    taskPermissions,
    setTaskPermissions,
    isAdmin,
    userCreated,
    userData,
    setUserData,
    selectedUserId,
  } = useUserStore();

  const inputLabels = [
    {
      id: 'Edit',
      name: 'Edit',
    },
    // {
    //   id: 'Read',
    //   name: 'Read',
    // },
    {
      id: 'Delete',
      name: 'Delete',
    },
    {
      id: 'Share',
      name: 'Share',
    },
  ];

  const navigate = useNavigate();

  // retrieve coworkers by selectedUserId
  useEffect(() => {
    const fetchCoworkerData = async () => {
      try {
        const serverClient = new ServerClient('/api/retrieveCoworkersById');
        const res = await serverClient.post({ selectedUserId: selectedUserId });
        const result = res.data.data[0];
        const companyResult = res.data.companyData[0];
        // * * * test use
        // console.log('res.data.companyResult', res.data.companyResult);
        // console.log('res.data.data', res.data.data);
        // console.log('companyResult', companyResult);
        // console.log('userData in fetch', userData);
        setUserData({
          ...userData,
          name: result.name,
          created_at: result.created_at,
          companyName: companyResult.companyName,
        });
        setTemplatePermissions(result);
        setClientPermissions(result);
        setTaskPermissions(result);
        setCheckedItemsTemplates({
          Edit: result.canEditTemplates || false,
          Read: result.canReadTemplates || false,
          Delete: result.canDeleteTemplates || false,
          Share: result.canShareTemplates || false,
        });
        setCheckedItemsClientDataHub({
          Edit: result.canEditClients || false,
          Read: result.canReadClients || false,
          Delete: result.canDeleteClients || false,
          Share: result.canShareClients || false,
        });
        setCheckedItemsTasks({
          Edit: result.canEditTasks || false,
          Read: result.canReadTasks || false,
          Delete: result.canDeleteTasks || false,
          Share: result.canShareTasks || false,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchCoworkerData();
  }, [
    selectedUserId,
    setUserData,
    setTemplatePermissions,
    setClientPermissions,
    setTaskPermissions,
    setCheckedItemsTemplates,
    setCheckedItemsClientDataHub,
    setCheckedItemsTasks,
  ]);
  const handleChangeCheckbox = (id, tab) => {
    if (tab === 'Templates') {
      setCheckedItemsTemplates((prevCheckedItems) => ({
        ...prevCheckedItems,
        [id]: !prevCheckedItems[id],
      }));
    } else if (tab === 'ClientDataHub') {
      setCheckedItemsClientDataHub((prevCheckedItems) => ({
        ...prevCheckedItems,
        [id]: !prevCheckedItems[id],
      }));
    } else if (tab === 'Tasks') {
      setCheckedItemsTasks((prevCheckedItems) => ({
        ...prevCheckedItems,
        [id]: !prevCheckedItems[id],
      }));
    }
  };
  // save button
  const handleClickSave = async () => {
    try {
      let updatedTemplatePermissions = { ...templatePermissions };
      let updatedClientPermissions = { ...clientPermissions };
      let updatedTaskPermissions = { ...taskPermissions };
      if (activePermissionTab === 'Templates') {
        updatedTemplatePermissions = {
          canEditTemplates: checkedItemsTemplates['Edit'] === true,
          canReadTemplates: checkedItemsTemplates['Read'] === true,
          canDeleteTemplates: checkedItemsTemplates['Delete'] === true,
          canShareTemplates: checkedItemsTemplates['Share'] === true,
        };
      } else if (activePermissionTab === 'ClientDataHub') {
        updatedClientPermissions = {
          canEditClients: checkedItemsClientDataHub['Edit'] === true,
          canReadClients: checkedItemsClientDataHub['Read'] === true,
          canDeleteClients: checkedItemsClientDataHub['Delete'] === true,
          canShareClients: checkedItemsClientDataHub['Share'] === true,
        };
      } else if (activePermissionTab === 'Tasks') {
        updatedTaskPermissions = {
          canEditTasks: checkedItemsTasks['Edit'] === true,
          canReadTasks: checkedItemsTasks['Read'] === true,
          canDeleteTasks: checkedItemsTasks['Delete'] === true,
          canShareTasks: checkedItemsTasks['Share'] === true,
        };
      }
      const serverClient = new ServerClient('/api/db/savePermissions');
      const req = {
        userId: selectedUserId,
        templatePermissions: updatedTemplatePermissions,
        clientPermissions: updatedClientPermissions,
        taskPermissions: updatedTaskPermissions,
      };
      const res = await serverClient.post(req);

      setTemplatePermissions(updatedTemplatePermissions);
      setClientPermissions(updatedClientPermissions);
      setTaskPermissions(updatedTaskPermissions);
    } catch (error) {
      console.error('Error saving!', error);
    }
  };
  return (
    <div className="balance-user-access-container balance-settings-container">
      {isAdmin && (
        // admin
        <div className="balance-user-access-top-container">
          <div className="balance-settings-back-button-container" onClick={() => navigate('/Hub/Settings/User-Permissions')}>
            <Icon icon="ic:sharp-arrow-back-ios" className="balance-settings-back-button" />
          </div>
          {userData && (
            <div>
              <h1 className="balance-user-access-name">{userData.name}</h1>
              <p className="balance-user-access-company">{userData.companyName}</p>
              <div className="balance-user-access-info-container">
                <div>
                  <p className="balance-user-access-info-title">User since</p>
                  <p className="balance-user-access-info-date">{new Date(userData.created_at).toLocaleDateString()}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {!isAdmin && <h1 className="balance-settings-title">Permissions</h1>}
      {/* permissions */}
      <div className="balance-user-access-permissions-container">
        <div className="balance-settings-back-button-container" onClick={() => navigate('/Hub/Settings')}>
          <Icon icon="ic:sharp-arrow-back-ios" className="balance-settings-back-button" />
        </div>
        <div className="balance-user-access-permissions-title-container">
          <div>
            {isAdmin && <h1 className="balance-user-access-permissions-title">Permissions</h1>}
            <p className="balance-user-access-permissions-description">View user permissions across the product suite.</p>
          </div>
          {isAdmin && (
            <div className="balance-user-access-permissions-button-container">
              <Button
                buttonName="Cancel"
                className="balance-user-access-permissions-cancel-button balance-global-movable-button"
              />
              <Button
                buttonName="Save"
                icon={SaveIcon}
                iconPosition="before"
                onClick={handleClickSave}
                className="balance-user-access-permissions-save-button balance-global-blue-button balance-global-movable-button"
              />
            </div>
          )}
        </div>
        {/* status tabs */}
        <div className="balance-permissions-tab-container">
          <div
            onClick={() => {
              setActivePermissionTab('Templates');
            }}
            className={`item-line ${activePermissionTab === 'Templates' ? 'active-tab' : ''}`}
          >
            <a href="#Templates">Templates</a>
          </div>
          <div
            onClick={() => {
              setActivePermissionTab('ClientDataHub');
            }}
            className={`item-line ${activePermissionTab === 'ClientDataHub' ? 'active-tab' : ''}`}
          >
            <a href="#ClientDataHub">Client Data Hub</a>
          </div>
          <div
            onClick={() => {
              setActivePermissionTab('Tasks');
            }}
            className={`item-line ${activePermissionTab === 'Tasks' ? 'active-tab' : ''}`}
          >
            <a href="#Tasks">Tasks</a>
          </div>
        </div>
        <div className="balance-permissions-edit-container">
          {/* templates tab */}
          {activePermissionTab === 'Templates' && (
            <>
              {isAdmin ? (
                <>
                  <p className="balance-permissions-checkbox-title">Select or Unselect.</p>
                  {inputLabels.map((label, index) => (
                    <div key={index} className="balance-permissions-checkbox-container">
                      <input
                        id={`label.${index}`}
                        type="checkbox"
                        className="balance-permissions-checkbox"
                        checked={checkedItemsTemplates[label.id] || false}
                        onChange={() => handleChangeCheckbox(label.id, activePermissionTab)}
                      />
                      <label htmlFor={`label.${index}`} className="balance-permissions-label">
                        {label.name}
                      </label>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {inputLabels.map((label, index) => (
                    <div key={index} className="balance-permissions-checkbox-container">
                      <input
                        id={`label.${index}`}
                        type="checkbox"
                        className="balance-permissions-checkbox"
                        checked={checkedItemsTemplates[label.id] || false}
                        // disabled
                      />
                      <label htmlFor={`label.${index}`} className="balance-permissions-label">
                        {label.name}
                      </label>
                    </div>
                  ))}
                </>
              )}
            </>
          )}
          {/* client data hub */}
          {activePermissionTab === 'ClientDataHub' && (
            <>
              {isAdmin ? (
                <>
                  <p className="balance-permissions-checkbox-title">Select or Unselect.</p>
                  {inputLabels.map((label, index) => (
                    <div key={index} className="balance-permissions-checkbox-container">
                      <input
                        id={`label.${index}`}
                        type="checkbox"
                        className="balance-permissions-checkbox"
                        checked={checkedItemsClientDataHub[label.id] || false}
                        onChange={() => handleChangeCheckbox(label.id, activePermissionTab)}
                      />
                      <label htmlFor={`label.${index}`} className="balance-permissions-label">
                        {label.name}
                      </label>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {inputLabels.map((label, index) => (
                    <div key={index} className="balance-permissions-checkbox-container">
                      <input
                        id={`label.${index}`}
                        type="checkbox"
                        className="balance-permissions-checkbox"
                        checked={checkedItemsClientDataHub[label.id] || false}
                        // disabled
                      />
                      <label htmlFor={`label.${index}`} className="balance-permissions-label">
                        {label.name}
                      </label>
                    </div>
                  ))}
                </>
              )}
            </>
          )}
          {/* tasks */}
          {activePermissionTab === 'Tasks' && (
            <>
              {isAdmin ? (
                <>
                  <p className="balance-permissions-checkbox-title">Select or Unselect.</p>
                  {inputLabels.map((label, index) => (
                    <div key={index} className="balance-permissions-checkbox-container">
                      <input
                        id={`label.${index}`}
                        type="checkbox"
                        className="balance-permissions-checkbox"
                        checked={checkedItemsTasks[label.id] || false}
                        onChange={() => handleChangeCheckbox(label.id, activePermissionTab)}
                      />
                      <label htmlFor={`label.${index}`} className="balance-permissions-label">
                        {label.name}
                      </label>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {inputLabels.map((label, index) => (
                    <div key={index} className="balance-permissions-checkbox-container">
                      <input
                        id={`label.${index}`}
                        type="checkbox"
                        className="balance-permissions-checkbox"
                        checked={checkedItemsTasks[label.id] || false}
                        // disabled
                      />
                      <label htmlFor={`label.${index}`} className="balance-permissions-label">
                        {label.name}
                      </label>
                    </div>
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default UserAccess;
