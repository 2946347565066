import './TestPage.css';
import RedirectButton from '../components/RedirectButton';
import { useState, useEffect } from 'react';
import ServerClient from '../services/api-client';
import useUserStore from '../store/useUserStore';

import {loadStripe} from '@stripe/stripe-js';

const TestPage = () => {
    // const [retrievedJSON, setRetrievedJSON] = useState([]);
    // const [price, setPrice] = useState('');
    const [serverGetEndpoint, setServerGetEndpoint] = useState('');
    const [serverPostEndpoint, setServerPostEndpoint] = useState('');
    const [returnedValue, setReturnedValue] = useState('');

    useEffect(() => {
        try {
            const params = new URLSearchParams(window.location.search);
            const code = params.get('code');

            const getToken = async (code) => {
                console.log('attempting to grab token...');
                const serverClient = new ServerClient('/api/auth/outlook/token');
                const res = await serverClient.post({ code: code });
                // console.log('res is: ', res);
                return res.data.accessToken;
            }
            const sendEmail = async (token) => {
                console.log('attempting to send email with token...', token);
                const serverClient = new ServerClient('/api/sendOAuthEmail');
                const res = await serverClient.post({ accessToken: token });
                console.log('sendEmail res is: ', res);
                return res;
            }

            const grouped = async (code) => {
                const token = await getToken(code);
                const emailResult = sendEmail(token);
            }

            if (code) {
                console.log('code found: ', code);
                const answer = grouped(code);
            } else {
                console.log('no code supplied');
            }
            
        } catch (err) {

        }
    }, [])

    const testPayment = async (price) => {
        try {
            const stripe = await loadStripe(process.env.REACT_APP_STRIPE);
            const serverClient = new ServerClient('/api/stripeTest');

            const body = {
                price: price,
            }

            const res = await serverClient.post(body);

            const result = await stripe.redirectToCheckout({
                sessionId: res.data?.id,
            })

            console.log('stripe result is: ', result);
        } catch (err) {
            console.log('stripe err is: ', err);
        }
        
    }

    const retrieveGetData = async (endpoint) => {
        try {
            // const res = await fetch(`http://54.196.114.241/api/mongoMovie`, req);
            // const data = await res.json();
            // console.log('res GET data is: ', data);
            const serverClient = new ServerClient(endpoint);
            const res = await serverClient.get();
            if (endpoint === "/api/mongoMovie") {
                const parsedMovie = await JSON.parse(res.data);
                console.log('A random movie is: ', parsedMovie);
            } else {
                console.log('serverClient res is: ', res);
            }
        } catch (err) {
            console.log('err caught: ', err);
        }
    }
    const retrievePostData = async (endpoint) => {
        try {
            const req = {
                // method: 'Post',
                // test: 'hello!',
            }
            // const res = await fetch(`http://54.196.114.241/api/mongoMovie`, req);
            // const data = await res.json();
            // console.log('res GET data is: ', data);
            const serverClient = new ServerClient(endpoint);
            const res = await serverClient.post(req);
            console.log('serverClient post res is: ', res);
            if (res.data.url) {
                window.location.href = res.data.url;
            }
            // if (endpoint === "/api/mongoMovie") {
            //     const parsedMovie = await JSON.parse(res.data);
            //     console.log('A random movie is: ', parsedMovie);
            // } else {
            //     console.log('serverClient res is: ', res);
            // }
        } catch (err) {
            console.log('err caught: ', err);
        }
    }
    // const openAiEndpoint = async (content) => {
    //     try {
    //         const req = {
    //             message: content,
    //         }
    //         const serverClient = new ServerClient('/api/ai/test');
    //         const res = await serverClient.post(req);
    //         console.log('serverClient res is: ', res);
    //         setReturnedValue(res?.data?.message?.content);
    //     } catch (err) {
    //         console.log('err caught: ', err);
    //     }
    // }
    return (
        // <div style={{position: 'relative', paddingBottom: '100rem', height: '0',}}><iframe src="https://app.supademo.com/embed/clyreym7f0g1bz9kdb39ahbiq" loading="lazy" title="Balanceapp Demo" allow="clipboard-write" frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen style={{position: 'absolute', top: '0', left: '0', width: '50%', height: '100%',}}></iframe></div>
        <div className='testpage-container'>
            <div className='testpage-title-container'>
                <h1 className='testpage-title'>Test Page Title!</h1>
                <p className='testing-message'>Check console log to ensure connection to server works.</p>
            </div>
            <div className='testpage-server-endpoint-test-container'>
                <form className='testpage-server-endpoint-form'>
                    <input className='testpage-server-endpoint-test-input' value={serverGetEndpoint} onChange={(e) => setServerGetEndpoint(e.target.value)} />
                    <button type='button' onClick={() => retrieveGetData(serverGetEndpoint)} >Call Backend Server for GET</button>
                </form>
            </div>
            <div className='testpage-server-endpoint-test-container'>
                <form className='testpage-server-endpoint-form'>
                    <input className='testpage-server-endpoint-test-input' value={serverPostEndpoint} onChange={(e) => setServerPostEndpoint(e.target.value)} />
                    <button type='button' onClick={() => retrievePostData(serverPostEndpoint)} >Call Backend Server for POST</button>
                </form>
            </div>
            {/* <div className='testpage-server-endpoint-test-container'>
                <form className='testpage-server-endpoint-form'>
                    <input className='testpage-server-endpoint-test-input' value={aiMessage} onChange={(e) => setAIMessage(e.target.value)} />
                    <button type='button' onClick={() => openAiEndpoint(aiMessage)} >Put a message for ChatGPT in the box!</button>
                </form>
                {returnedValue && <div className='ai-returned-value'>{returnedValue}</div>}
            </div> */}
            <div className='testpage-server-endpoint-test-container'>
                <button type='button' onClick={() => testPayment('price_1Pb3k408wjiZbZAUokvezljV')} >Monthly Test Stripe</button>
            </div>
            <div className='testpage-server-endpoint-test-container'>
                <button type='button' onClick={() => testPayment('price_1Pb4yh08wjiZbZAURgHgEHCA')} >Yearly Test Stripe</button>
            </div>
            <div className='testpage-button-container'>
                <RedirectButton pathName='/' buttonText='Back to Home!' />
            </div>
        </div>
    )
}

export default TestPage;