import React from 'react';
import useModalStore from '../../store/useModalStore';
import useFormBuilderStore from '../../store/useFormBuilderStore';
import { getPopupData } from '../../lib/data/getPopupData';
import './PopupGlobal.css';

const CreateTypePopup = () => {
  const setSelectedTemplate = useModalStore(
    (state) => state.setSelectedTemplate
  );

  const { setTemplateType } = useFormBuilderStore();

  // when click navigates to create application popup
  const handleClickSelectTemplate = (item) => {
    if (item.buttonName === 'Business Checking template') {
      setTemplateType('BusinessChecking');
    } else if (item.buttonName === 'Business Financing template') {
      setTemplateType('BusinessFinancing');
    }
    setSelectedTemplate('CreateApplication');
  };

  return (
    <>
      <div className='balance-create-popup-title-container'>
        <h2 className='balance-create-popup-title'>
          What type of Template do
          <br />you want to create?
        </h2>
        <p className='balance-create-popup-which-template'>
          I want to create a...
        </p>
      </div>
      {/* template buttons */}
      {getPopupData().map((item, index) => (
        <div key={item.id} className='balance-create-popup-button-container'>
          <div
            className='balance-create-popup-button'
            onClick={() => handleClickSelectTemplate(item)}
          >
            <img src={item.icon} alt={`Icon ${index}`} />
            <div className='balance-create-popup-button-title-container'>
              <p>{item.buttonName}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default CreateTypePopup;
