import { useEffect, useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ServerClient from '../../../../services/api-client';
import useFormBuilderStore from '../../../../store/useFormBuilderStore';
import useUserStore from '../../../../store/useUserStore';
import { useHashTab } from '../../../../hooks/useHashTab';
import Modal from '../../../../components/Popup/Modal';
import Button from '../../../../components/Button/Button';
import Section from './pages/Section';
import SectionsLeft from './SideLeft/SideLeft';
import SideRight from './SideRight/SideRight';
import AddQuestionsModal from '../CreateTemplates/AddFormModal';
import ConfirmPopup from '../../../../components/Popup/ConfirmPopup';
import './FormBuilder.css';
import './FormBuilderLayout.css';
import {
  blankShortText,
  blankLongText,
  blankSuffixesDropD,
  blankStateDropD,
  blankCitizenshipDropD,
  blankDocument,
} from './BlankInputObjects';
import EyeIcon from '../../../../assets/Icon/Eye_Icon_Dark.svg';
import SideLeftIcon from '../../../../assets/Icon/Side_Left_Icon.svg';
import SideRightIcon from '../../../../assets/Icon/Side_Right_Icon.svg';

const FormBuilder = () => {
  const [pendingPublish, setPendingPublish] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  // store
  const {
    sections,
    addSection,
    setSections,
    selectedCountry, // selected country from dropdown
    templateType,
    businessEntityType,
    editTemplateName,
    setEditTemplateName,
    showFormModal,
    setShowFormModal,
    selectedSectionIndex,
    setSectionIndex,
    selectedQuestionIndex,
    addNewQuestion,
    setSuccess, // toast
    setTemplatesData, // form data in mongo
    activeCreateTab, // create tab
    setActiveCreateTab, // create tab
    /* * * sidebar form builder * * */
    leftSidebarOpen,
    rightSidebarOpen,
    toggleLeftSidebar,
    toggleRightSidebar,
    /* * * * * * * * */
    selectedEditData,
    resetTemplate,
    usersToShareWith,
    selectedStatusTab,
  } = useFormBuilderStore();

  const { userId, userToken, userName } = useUserStore();

  // ref
  const templateNameRef = useRef(null);
  const sectionRef = useRef([]);

  const navigate = useNavigate();

  const isEditPage = selectedEditData && selectedEditData.length > 0;

  // set # to share tab link hook
  useHashTab(setActiveCreateTab, 'Create');

  // get prefilled form when BusinessChecking and Sole Proprietorship is selected
  useEffect(() => {
    const fetchFormData = async () => {
      try {
        if (templateType === 'BusinessChecking' && businessEntityType?.value === 'sole-proprietorship') {
          const serverClient = new ServerClient('/api/templates');
          const res = await serverClient.get();
          // setFormData(res.data);
          const targetId = '660e0cac4341ac47413b6dd2';
          const testData = res.data.find((data) => data._id === targetId);
          const sectionsData = JSON.parse(testData?.sectionsData);
          // console.log('test data found as: ', JSON.parse(testData?.sectionsData));
          if (sectionsData) {
            setSections(sectionsData);
            setSectionIndex(0);
          }
        } else return;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchFormData();
  }, []);

  // get prefilled form when edit in dropdown is selected
  useEffect(() => {
    const fetchEditData = async () => {
      try {
        const serverClient = new ServerClient('/api/templates');
        const res = await serverClient.get();

        if (selectedEditData && selectedEditData?.length > 0) {
          const targetId = selectedEditData[0];
          const editData = res.data.find((data) => data.uniqueId === targetId);
          const sectionsData = JSON.parse(editData?.sectionsData);
          if (sectionsData) {
            setSections(sectionsData);
            setSectionIndex(0);
          }
          const { formTitle: editTemplateName } = editData;
          setEditTemplateName(editTemplateName);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchEditData();
  }, [selectedEditData]);

  // useEffect(() => {
  //   resetTemplate();
  // }, []);

  // edit template name
  const handleChangeEditTemplateName = (e) => {
    setEditTemplateName(e.target.value);
  };
  // enter key and save template name
  const handleKeyDownSave = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      templateNameRef.current.blur();
    }
  };
  // mutbale input box length
  // const inputWidth = editTemplateName.length * 10;

  const toggleAddQuestionsModal = () => {
    setShowFormModal(!showFormModal);
  };
  const handleClickAddQuestionButton = () => {
    toggleAddQuestionsModal();
  };

  // add section
  const handleClickAddSection = () => {
    addSection();
    sectionRef.current.push(null);
  };

  // adding input to build form
  const handleClickCreateInput = (type) => {
    console.log('This is called when short or long text is clicked!');
    console.log(`Adding ${type} question to Section ${selectedSectionIndex}! (Question Index is: ${selectedQuestionIndex})...`);
    if (type === 'shortText') {
      addNewQuestion(selectedSectionIndex, blankShortText);
    } else if (type === 'longText') {
      addNewQuestion(selectedSectionIndex, blankLongText);
    } else if (type === 'suffixDropD') {
      addNewQuestion(selectedSectionIndex, blankSuffixesDropD);
    } else if (type === 'citizenshipDropD') {
      addNewQuestion(selectedSectionIndex, blankCitizenshipDropD);
    } else if (type === 'stateDropD') {
      addNewQuestion(selectedSectionIndex, blankStateDropD);
    } else if (type === 'documents') {
      addNewQuestion(selectedSectionIndex || 0, blankDocument);
    }
    toggleAddQuestionsModal();
  };

  // click eye icon
  const handleClickPreview = () => {
    navigate('/Hub/CreateTemplatesInitial/Preview');
  };

  const handleClickPublish = async () => {
    // setPendingPublish(true);
    setConfirmModalOpen(true);
  };

  // click confirm button
  const handleClickConfirm = async () => {
    try {
      setPendingPublish(true);
      const serverClient = new ServerClient('/api/saveForm');
      let req;

      const urlParams = new URLSearchParams(window.location.search);
      const editId = urlParams.get('id');

      // if in edit template page : )
      if (editId) {
        const serverClient = new ServerClient('/api/templates');
        const res = await serverClient.get();

        const existingData = res.data.find((data) => data.uniqueId === editId);

        if (existingData) {
          existingData.sectionsData = JSON.stringify(sections);

          req = {
            sectionsData: JSON.stringify(sections),
            formTitle: editTemplateName,
            // selectedCountry: selectedCountry,
            updatedDate: new Date().toISOString(),
            userId: userId,
            uniqueId: editId,
            editId: editId,
            userName: userName,
          };
        } else {
          console.log('No existing data found : ( ');
          return;
        }
        // if in new create template page : )
      } else {
        const uuid = uuidv4();
        req = {
          sectionsData: JSON.stringify(sections),
          templateType: templateType,
          businessEntityType: businessEntityType?.text || '-',
          formTitle: editTemplateName,
          selectedCountry: selectedCountry,
          publishDate: new Date().toISOString(),
          updatedDate: '-',
          userId: userId,
          uniqueId: uuid,
          userName: userName,
          creatorDeleted: false,
        };
      }

      const res = await serverClient.post(req);

      if (res.status === 200) {
        if (res?.data?.uuid && usersToShareWith?.length > 0) {
          // if an id was returned and we have users to share with
          console.log(
            `you created a new template with id: ${res?.data?.uuid}, and you want to share it with: ${usersToShareWith}`
          );
          const shareServerClient = new ServerClient('/api/db/shareTemplate');
          const res2 = await shareServerClient.post({ templateId: res?.data?.uuid, userEmailsArray: usersToShareWith });
          if (res2?.data?.unsuccessfulAdds?.length > 0) {
            alert(`Could not share template with ${res2?.data?.unsuccessfulAdds}`);
          }
        }
        await navigate('/Hub/CreateTemplatesInitial');
        resetTemplate();
        setSuccess('Success!');
      } else {
        setSuccess('Failed');
      }
    } catch (err) {
      console.log('Error publishing to saveForm: ', err);
      setSuccess('Failed');
    } finally {
      setPendingPublish(false);
    }
  };

  // close cancel cofirm modal
  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  // back button from edit page
  const handleClickBack = () => {
    if (selectedStatusTab === 'My Applications') {
      navigate('/Hub/CreateTemplatesInitial#MyApplications');
    } else if (selectedStatusTab === 'Shared') {
      navigate('/Hub/CreateTemplatesInitial#Shared');
    } else {
      navigate('/Hub/CreateTemplatesInitial#Templates');
    }
  };

  return (
    <div className="balance-form-builder-container">
      <header className="balance-form-builder-header">
        {/* header - my application templates / create new */}
        <div className="balance-form-builder-header-left-container">
          {isEditPage && (
            <Icon icon="ic:sharp-arrow-back-ios" className="balance-settings-back-button" onClick={handleClickBack} />
          )}
          <span className="balance-form-builder-header-left">My application templates /</span>
          {/* editable template name */}
          <input
            ref={templateNameRef}
            type="text"
            value={editTemplateName}
            onChange={(e) => handleChangeEditTemplateName(e)}
            onKeyDown={handleKeyDownSave}
            className="balance-form-builder-header-template-name"
            // style={{ width: `${inputWidth}px` }}
          />
        </div>
        {/* header - create, share pages */}
        <div className="balance-form-builder-header-middle-container">
          <div
            onClick={() => setActiveCreateTab('Create')}
            className={`item ${activeCreateTab === 'Create' ? 'active-page' : ''}`}
          >
            <a href="#Create" className="balance-form-builder-header-middle-create">
              Create
            </a>
          </div>
          {/* <div
            onClick={() => setActivePage('Share')}
            className={`item ${activePage === 'Share' ? 'active-page' : ''}`}
          >
            <a
              href='#Share'
              className='balance-form-builder-header-middle-share'
            >
              Share
            </a>
          </div> */}
        </div>
        {/* header - eye button, publish button */}
        <div className="balance-form-header-button-container">
          <Button icon={EyeIcon} iconPosition="after" className="balance-form-header-eye-button" onClick={handleClickPreview} />
          <Button
            buttonName="Publish"
            className="balance-form-header-publish-button balance-global-blue-button"
            onClick={handleClickPublish}
            disabled={pendingPublish}
          />
        </div>
      </header>
      {/* left side bar */}
      <div className={`balance-grid-layout-sidebar balance-grid-layout-sidebar-left ${!leftSidebarOpen ? 'closed' : ''}`}>
        {activeCreateTab === 'Create' && (
          <SectionsLeft handleClickAddQuestion={handleClickAddQuestionButton} handleClickAddSection={handleClickAddSection} />
        )}
        {/* {activeCreateTab === 'Share' && <ShareLeft />} */}
      </div>
      <div className={`balance-grid-layout-content ${leftSidebarOpen ? '' : 'closed'}`}>
        {/* contents */}
        <div className="page-rendering">
          {activeCreateTab === 'Create' &&
            sections.map((section, index) => {
              // console.log('section', section);
              return (
                // "add your first section here"
                <Section section={section} sectionIndex={index} key={index} />
              );
            })}
          {activeCreateTab === 'Create' && showFormModal && <AddQuestionsModal handleClickCreateInput={handleClickCreateInput} />}
          {/* {activeCreateTab === 'Share' && <ShareTab />} */}
        </div>
        {/* buttons left, right */}
        {activeCreateTab === 'Create' && (
          <div className="balance-grid-layout-sidebar-nav-icon-container">
            <Button icon={SideLeftIcon} iconPosition="after" onClick={toggleLeftSidebar} className="balance-sidebar-icon" />
            <Button icon={SideRightIcon} iconPosition="after" onClick={toggleRightSidebar} className="balance-sidebar-icon" />
          </div>
        )}
      </div>
      {/* right side bar */}
      <div
        className={`balance-grid-layout-sidebar balance-grid-layout-sidebar-right ${
          rightSidebarOpen && activeCreateTab === 'Create' ? '' : 'closed'
        }`}
      >
        {activeCreateTab === 'Create' && <SideRight />}
      </div>
      {isConfirmModalOpen && (
        <Modal width="30rem" onClose={closeConfirmModal}>
          <ConfirmPopup
            onClose={closeConfirmModal}
            onConfirm={handleClickConfirm}
            checkConfirm="Confirm you want to save your application"
          />
        </Modal>
      )}
    </div>
  );
};

export default FormBuilder;
