import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCheckNewUserEmail, useSendSignupEmails } from '../../hooks/useEmailHooks';
import BalanceFAQAccordion from './WebsiteComponents/BalanceFAQAccordion';
import PrivacyPolicy from './WebsiteComponents/PrivacyPolicy';
import TermsOfUse from './WebsiteComponents/TermsOfUse';
import bigLogo from '../../assets/Logo/Balance_Big_Logo.svg';
import smallLogo from '../../assets/Logo/Balance_Logo.svg';
import Button from '../../components/Button/Button';
import ThankYouPage from '../FormPortal/ThankYouPage';
import './BalanceHome.css';
import ConsciousVentureLogo from './WebsiteAssets/ConsciousVentureLogo.png';
import MarylandTechLogo from './WebsiteAssets/MarylandTechLogo.png';
import TechstarsLogo from './WebsiteAssets/TechstarsFounderLogo.png';
import MarylandInnovationLogo from './WebsiteAssets/MarylandInnovationLogo.png';
import BurgerIcon from './WebsiteAssets/burger-menu.svg';
import BlueForm from './WebsiteAssets/blue-form.png';
import BlueAddPerson from './WebsiteAssets/blue-add-person.png';
import BlueDataFolder from './WebsiteAssets/blue-data-folder.png';
import BlueWorkflow from './WebsiteAssets/blue-workflow.png';
import BalanceDashboard from './WebsiteAssets/BalanceDashboard-Play.png';
// import PlayButton from './WebsiteAssets/play-button.svg';

const BalanceHome = () => {
  const dropdown = [
    {
      name: 'Application Builder',
      path: '/Features/ApplicationBuilder',
    },
    {
      name: 'Client Data Hub',
      path: '/Features/ClientDataHub',
    },
    {
      name: 'Client Portal',
      path: '/Features/ClientPortal',
    },
    {
      name: 'Task Workflow Manager',
      path: '/Features/TaskWorkflowManager',
    },
  ];

  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isMobileNavbarOpen, setIsMobileNavbarOpen] = useState(false);
  const [emailButtonLoading, setEmailButtonLoading] = useState(false);
  const [email, setEmail] = useState(''); // new user email input
  const [invalidEmail, setInvalidEmail] = useState(''); // empty input
  const [usedEmail, setUsedEmail] = useState(''); // email already in use
  const [excludedEmail, setExcludedEmail] = useState(''); // email excluded domain
  const [isThankYouPage, setIsThankYouPage] = useState(false); // thank you page
  const [isDemoOpen, setIsDemoOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { mutate: sendEmailMutate } = useSendSignupEmails();
  const { mutate: checkNewUserEmailMutate } = useCheckNewUserEmail();

  const navigate = useNavigate();
  const dropdownRef = useRef(null); 

  const productFeatures = [
    {
      icon: BlueForm,
      title: 'Application Builder',
      text: 'Automate accurate financial service applications creation and delivery',
    },
    { icon: BlueAddPerson, title: 'Client Portal', text: 'Guided portal for clients to complete and submit applications' },
    { icon: BlueDataFolder, title: 'Client Data Hub', text: 'Centralize client data to enhance data access and visibility' },
    { icon: BlueWorkflow, title: 'Workflow Manager', text: 'Assign and manage tasks for efficient workflows' },
  ];

  const scrollToElementById = (elementId) => {
    let element = document.getElementById(elementId);
    // element.scrollIntoView({block: 'start', behavior: 'smooth'});
    const pos = element.style.position;
    const top = element.style.top;
    element.style.position = 'relative';
    element.style.top = '-70px';
    element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    element.style.top = top;
    element.style.position = pos;
    setIsMobileNavbarOpen(false);
  };

  const redirectButtonClick = () => {
    window.open('https://calendly.com/yem-ade/30min', '_blank');
  };

  const redirectBetaUserClick = () => {
    window.open('https://forms.gle/L8KpCtTpMPd7S1vp9', '_blank');
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    setInvalidEmail('');
    setUsedEmail('');
    setExcludedEmail('');
  };

  // join
  const handleClickJoin = async () => {
    if (!email) {
      setInvalidEmail('Email is required.');
      return;
    }
    setInvalidEmail('');
    setEmailButtonLoading(true);

    const imagePath = '/Logo.png';

    try {
      const response = await fetch(imagePath);
      if (!response.ok) {
        throw new Error('Failed to fetch image.');
      }
      console.log('Fetched response:', response);

      const blob = await response.blob();
      // console.log('Blob:', blob);

      if (blob.type.startsWith('text')) {
        throw new Error('Fetched file is not an image.');
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const imageData = reader.result;
        // console.log('Base64:', imageData);

        checkNewUserEmailMutate(
          { email },
          {
            onSuccess: () => {
              console.log('email', email);
              sendEmailMutate(
                { email, image: imageData },
                {
                  onSuccess: () => {
                    console.log('Email sent to the user.');
                    setEmail('');
                    setIsThankYouPage(true);
                  },
                  onError: (error) => {
                    if (error) {
                      setExcludedEmail('Please enter your work email.');
                    } else {
                      console.log('Email sending failed:', error);
                    }
                  },
                }
              );
            },
            onError: (error) => {
              if (error.response?.status === 401) {
                // console.log('Email already in use.');
                setUsedEmail('Email already in use.');
              } else {
                console.log('Email check failed:', error);
              }
            },
          }
        );
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error('Error loading image:', error);
    }
    await new Promise((resolve) => setTimeout(resolve, 1500));
    setEmailButtonLoading(false);
  };

  if (isThankYouPage) {
    return <ThankYouPage />;
  }

  // dropdown features
  const handleClickDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };
  const handleClickFeatures = (path) => {
    navigate(path);
    setDropdownOpen(false);
  };
  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setDropdownOpen(false);
    }
  };

  return (
    <div className="balance-home-main-container" onClick={handleClickOutside}>
      {/* NavBar */}
      <div className="balance-home-navbar">
        <div className="balance-home-navbar-inner-container">
          <div className="balance-home-navbar-links-container">
            <div className="balance-home-navbar-logo-container">
              <img className="balance-home-navbar-logo" src={smallLogo} alt="logo" />
            </div>
            <div className={`balance-home-navbar-links ${isMobileNavbarOpen ? 'mobile-navbar-open' : ''}`}>
              <p className="balance-home-navbar-link" onClick={() => scrollToElementById('home')}>
                Home
              </p>
              <p className="balance-home-navbar-link" onClick={() => scrollToElementById('solutions')}>
                Solutions
              </p>
              <p className="balance-home-navbar-link" onClick={() => scrollToElementById('company')}>
                Company
              </p>
              {/* <p className="balance-home-navbar-link" onClick={() => navigate('/Pricing')}>
                Pricing
              </p> */}
              <p className="balance-home-navbar-link" onClick={() => navigate('/Security')}>
                Security
              </p>
              <p className="balance-home-navbar-link" onClick={handleClickDropdown}>
                Features
              </p>
              <p className="balance-home-navbar-link" onClick={() => scrollToElementById('faq')}>
                FAQ
              </p>
            </div>
            <div
              className="balance-home-navbar-mobile-hamburger-icon-container"
              onClick={() => setIsMobileNavbarOpen(!isMobileNavbarOpen)}
            >
              {/* <p className='balance-home-navbar-mobile-hamburger-icon'>Menu</p> */}
              <img src={BurgerIcon} className="balance-home-navbar-mobile-hamburger-icon" />
            </div>
          </div>
          <div className="balance-home-navbar-buttons-container">
            <p className="balance-home-navbar-link balance-login-link" onClick={() => navigate('Login')}>
              Log in
            </p>
            <Button
              className="balance-global-blue-button balance-global-homepage-button balance-global-navbar-button"
              buttonName={'Schedule a Demo'}
              onClick={() => redirectButtonClick()}
            />
          </div>
        </div>
      </div>
      {isTermsOpen && (
        <div className="privacy-policy-container">
          <TermsOfUse closeModalOnClick={() => setIsTermsOpen(false)} />
        </div>
      )}
      {isPrivacyOpen && (
        <div className="privacy-policy-container">
          <PrivacyPolicy closeModalOnClick={() => setIsPrivacyOpen(false)} />
        </div>
      )}
      {isDemoOpen && (
        <div className="privacy-policy-container">
          <DemoPopup closePopup={() => setIsDemoOpen(false)} />
        </div>
      )}
      {/* First Section */}
      <div className="balance-home-intro-mobile-logo-container-container">
        <div className="balance-home-intro-mobile-logo-container">
          <img className="balance-home-intro-mobile-logo" src={bigLogo} alt="logo" />
        </div>
      </div>
      <section className="balance-home-intro-container" id="home">
        <div className="balance-home-intro-info-container">
          <div className="balance-home-intro-info-title-container">
            <h1 className="balance-home-intro-info-title">
              <span className="balance-home-bold">Intelligent Onboarding</span> for Financial Services
            </h1>
            {/* <h1 className='balance-home-intro-info-title'>Transform your Bank</h1> */}
          </div>
          <div className="balance-home-intro-info-subtitle-container">
            <h4 className="balance-home-intro-info-subtitle">
              Balance automates back-office tasks and centralizes client data to streamline manual and fragmented onboarding and
              account opening operations.
            </h4>
            <h4 className="balance-home-intro-info-subtitle">Empower your Team. Transform your Organization.</h4>
          </div>
          <div className="balance-home-intro-info-schedule-button-container">
            <Button
              className="balance-global-blue-button balance-global-homepage-button"
              buttonName={'Schedule a Demo'}
              onClick={() => redirectButtonClick()}
            />
          </div>
        </div>
        <div className="balance-home-intro-logo-container-container">
          <div className="balance-home-intro-logo-container">
            <img className="balance-home-intro-logo" src={bigLogo} alt="logo" />
          </div>
          <div className="balance-home-intro-dashboard-image-container" onClick={() => setIsDemoOpen(true)}>
            {/* <img className='balance-home-intro-dashboard-image'  src={BalanceDashboard} /> */}
            {/* <img className='balance-home-intro-playbutton' src={PlayButton} /> */}
          </div>
        </div>
      </section>
      {/* Second Section */}
      <section className="balance-home-solutions-container" id="solutions">
        <div className="balance-home-solutions-title-container">
          <h2 className="balance-home-solutions-title">
            <span className="balance-home-bold">Seamless</span> Account Onboarding and Account Opening,{' '}
            <span className="balance-home-bold">Simplified</span>
          </h2>
        </div>
        <p className="balance-home-solutions-solutions-title">Product</p>
        <p className="balance-home-solutions-solutions-title-subtext">
          Introducing Atlas, an intelligent platform for financial service teams, tackling legacy tech and manual processes to
          enhance onboarding, streamline account opening, and unify data for better operations and client satisfaction.{' '}
        </p>
        <p className="balance-home-solutions-solutions-title-subtext">
          Atlas tailors integrated services to manage, connect, and automate processes for every team member in financial
          onboarding and account opening.
        </p>
        <p className="balance-home-solutions-solutions-title">Features</p>
        <div className="balance-home-solutions-solutions-container">
          {productFeatures.map((item, index) => (
            <div key={index} className="balance-home-single-solution-container">
              <div className="balance-home-single-solution-title-container">
                <div className="balance-home-single-solution-logo-container">
                  <img src={item?.icon} className="balance-home-single-solution-logo" />
                </div>
                <h4 className="balance-home-single-solution-title">{item?.title}</h4>
              </div>
              <div className="balance-home-single-solution-text-container">
                <p className="balance-home-single-solution-text">{item?.text}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* Third Section */}
      <section className="balance-home-company-info-container" id="company">
        <h3 className="balance-home-company-info-title">COMPANY</h3>
        <p className="balance-home-company-info-info-text">
          At Balance, we believe a sustainable future requires seamless access to financial services. We exist to eliminate the
          friction in financial services operations so that retail and commercial clients can solve their financial needs with
          ease and confidence.
        </p>
        <div className="balance-home-company-info-accepted-container">
          <p className="balance-home-company-info-accepted-title">Accepted into</p>
          <div className="balance-home-company-info-accepted-list">
            <div className="balance-home-company-info-accepted-logo-container">
              <img className="balance-home-company-info-accepted-logo" src={ConsciousVentureLogo} alt="accepted company logo" />
            </div>
            <div className="balance-home-company-info-accepted-logo-container">
              <img className="balance-home-company-info-accepted-logo" src={MarylandTechLogo} alt="accepted company logo" />
            </div>
            <div className="balance-home-company-info-accepted-logo-container">
              <img className="balance-home-company-info-accepted-logo" src={TechstarsLogo} alt="accepted company logo" />
            </div>
            <div className="balance-home-company-info-accepted-logo-container">
              <img className="balance-home-company-info-accepted-logo" src={MarylandInnovationLogo} alt="accepted company logo" />
            </div>
          </div>
        </div>
      </section>
      {/* Fourth Section */}
      {/* sign up */}
      <section className="balance-home-share-email-container">
        <div className="balance-home-share-email-half">
          <h2 className="balance-home-share-email-title">
            Join our platform today and be a part of
            <br />
            the future in financial services.
          </h2>
          <h4 className="balance-home-share-email-subtitle">
            Sign up for free now and stay updated with our latest developments.
          </h4>
          {/* <h2 className="balance-home-share-email-title">Become a Balance beta user</h2>
          <h4 className="balance-home-share-email-subtitle">Test exciting new releases and give feedback</h4> */}
        </div>
        {/* <div className="balance-home-share-email-half">
          <Button className="balance-global-blue-button balance-global-homepage-button balance-home-google-form-button" buttonName={'Get started'} onClick={() => redirectBetaUserClick()} />
        </div> */}
        <div className="balance-home-signup-input-container">
          <div>
            <input
              type="email"
              placeholder="Enter your work email"
              className="balance-home-signup-input"
              value={email}
              onChange={handleChangeEmail}
            />
            <Button
              buttonName={emailButtonLoading ? 'Loading...' : 'Join'}
              className="balance-global-blue-button balance-home-join-button"
              disabled={emailButtonLoading}
              onClick={handleClickJoin}
            />
          </div>
          {invalidEmail && <p className="balance-home-required-label">{invalidEmail}</p>}
          {usedEmail && <p className="balance-home-required-label">{usedEmail}</p>}
          {excludedEmail && <p className="balance-home-required-label">{excludedEmail}</p>}
          <div className="balance-home-policy-description-container">
            <span className="balance-home-policy-description">
              We care about your data in our{' '}
              <a className="balance-home-policy-link" onClick={() => setIsPrivacyOpen(true)}>
                privacy policy
              </a>
              .
            </span>
          </div>
        </div>
      </section>
      {/* DEMO Section */}
      <section className="balance-home-demo-section"></section>
      {/* Fifth (FAQ) Section */}
      <section className="balance-home-faq-section-container" id="faq">
        <p className="balance-home-faq-minitext">FAQ</p>
        <h2 className="balance-home-faq-title">Frequently asked Questions</h2>
        <h4 className="balance-home-faq-subtitle">Everything you need to know about us.</h4>
        <div className="balance-home-faq-container">
          <BalanceFAQAccordion />
        </div>
      </section>
      {/* Sixth Section */}
      <section className="balance-home-ending-section">
        <h2 className="balance-home-ending-title">Still have questions?</h2>
        <h4 className="balance-home-ending-subtitle">Can't find the answer you're looking for? Please connect with our team!</h4>
        <div className="balance-home-ending-button-container">
          <Button
            className="balance-global-blue-button balance-home-ending-section-button"
            buttonName={'Get in Touch'}
            onClick={() => redirectButtonClick()}
          />
        </div>
        <div className="balance-home-ending-section-footer-container">
          <div className="balance-home-ending-section-footer-left">
            <p className="balance-home-ending-section-text">&copy; 2024 Balance Copyright and rights reserved</p>
          </div>
          <div className="balance-home-ending-section-footer-right">
            <p className="balance-home-ending-section-text" onClick={() => setIsTermsOpen(true)}>
              Terms and Conditions
            </p>
            <p className="balance-home-ending-section-text" onClick={() => setIsPrivacyOpen(true)}>
              Privacy Policy
            </p>
          </div>
        </div>
      </section>
      {/* features dropdown */}
      {dropdownOpen && (
        <div ref={dropdownRef} className="balance-home-features-dropdown-container">
          <ul>
            {dropdown.map((list, index) => (
              <li key={index} onClick={() => handleClickFeatures(list.path)}>
                {list.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const DemoPopup = ({ closePopup }) => {
  return (
    <div className="balance-home-demo-popup-container">
      <div className="balance-home-demo-popup-top-bar">
        <p className="balance-home-demo-popup-x" onClick={closePopup}>
          X
        </p>
      </div>
      <div className="balance-home-demo-popup-demo-container">
        <div style={{ position: 'relative', paddingBottom: '30rem', height: '100%', width: '55rem' }}>
          <iframe
            src="https://app.supademo.com/embed/clyreym7f0g1bz9kdb39ahbiq"
            loading="lazy"
            title="Balanceapp Demo"
            allow="clipboard-write"
            frameborder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowfullscreen
            className="balance-demo-object"
            style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '108%' }}
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default BalanceHome;
