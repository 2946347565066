import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import ServerClient from '../services/api-client';
import useUserStore from '../store/useUserStore';

export function useChangeStatus() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ uniqueId, applicationStatus }) => {
      const serverClient = new ServerClient('/api/updateTasksStatus');
      const res = await serverClient.post({ uniqueId, applicationStatus });
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['myTasks', 'assignedTasks']);
      console.log('Status changed.');
    },
    onError: (error) => {
      console.error('Error changing status.', error);
    },
  });
}

export function useGetClientHubForms() {
  const { userId } = useUserStore();

  return useQuery({
    queryKey: ['clientCompletedForm'],
    queryFn: async () => {
      const serverClient = new ServerClient('/api/getClientHubForms');
      const res = await serverClient.post({ userId: userId });
      return res.data;
    },
    onError: (error) => {
      console.error('Error fetching completed form:', error);
    },
  });
}
