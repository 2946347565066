import Button from '../../components/Button/Button';
import './PopupGlobal.css';

const ArchivePopup = ({ onClose, onConfirm, checkConfirm }) => {
  // close modal
  const handleClickCloseModal = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className="balance-confirm-popup-container">
      <p className="balance-confirm-popup-message">{checkConfirm}</p>
      <div className="balance-confirm-popup-button-container">
        <Button buttonName="Return" onClick={handleClickCloseModal} className="balance-confirm-popup-cancel-button" />
        <Button buttonName="Archive" onClick={onConfirm} className="balance-confirm-popup-confirm-button" />
      </div>
    </div>
  );
};

export default ArchivePopup;
