import './Search.css';
import Button from '../../components/Button/Button';
import SearchIcon from '../../assets/Icon/Search_Icon_Dark.svg';

const Search = ({ placeholder, searchTerm, handleSearch }) => {
  return (
    <div className='balance-search-container'>
      <Button
        icon={SearchIcon}
        iconPosition='before'
        iconStyle='balance-search-icon'
        className='balance-search-button'
      />
      <input
        type='search'
        id='search'
        placeholder={placeholder}
        className='balance-search-input'
        value={searchTerm}
        onChange={handleSearch}
      />
    </div>
  );
};

export default Search;
