import React from 'react';
import Button from '../Button/Button';
import Arrow from '../../assets/Icon/Arrow_Down_Gray_Icon.svg';
import './Pagination.css';

const Pagination = ({
  currentPage,
  handleChangePage,
  pageSize,
  totalCount,
}) => {
  // console.log('currentPage', currentPage);

  const totalPages = Math.ceil(totalCount / pageSize);

  const handleClickPrevious = () => {
    handleChangePage(currentPage - 1);
  };
  const handleClickNext = () => {
    handleChangePage(currentPage + 1);
  };

  const renderPageNumbers = () => {
    const pages = [];

    for (let i = 0; i < totalPages; i++) {
      const pageNumber = i + 1;
      const pageStyle =
        currentPage === pageNumber
          ? 'balance-pagination-active-page'
          : 'balance-pagination-pages';
      pages.push(
        <Button
          key={pageNumber}
          buttonName={String(pageNumber)}
          className={pageStyle}
          onClick={() => handleChangePage(pageNumber)}
        />
      );
    }
    return pages;
  };

  return (
    <div className='balance-pagination-container'>
      {/* previous button */}
      <Button
        buttonName='Previous'
        icon={Arrow}
        iconPosition='before'
        iconStyle='balance-pagination-previous-icon'
        onClick={handleClickPrevious}
        className='balance-pagination-previous-button'
        disabled={currentPage === 1}
      />
      {/* pages */}
      <div className='balance-pagination-pages-container'>
        {renderPageNumbers()}
      </div>
      {/* next button */}
      <div className='balance-pagination-next-button-container'>
        <Button
          buttonName='Next'
          icon={Arrow}
          iconPosition='after'
          iconStyle='balance-pagination-next-icon'
          onClick={handleClickNext}
          className='balance-pagination-next-button'
          disabled={currentPage >= totalPages}
        />
      </div>
    </div>
  );
};

export default Pagination;
