import './FormFooter.css';

const FormFooter = () => {
  return (
    <footer>
      <p>&copy; TM</p>
    </footer>
  );
};

export default FormFooter;
