import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useModalStore from '../../store/useModalStore';
import useFormBuilderStore from '../../store/useFormBuilderStore';
import Select from 'react-select';
import Button from '../Button/Button';
import './PopupGlobal.css';

const CreatePopup = () => {
  const {
    setEditTemplateName,
    businessEntityType,
    setBusinessEntityType,
    templateType,
    selectedCountry,
    setSelectedCountry,
  } = useFormBuilderStore();
  const [countries, setCountries] = useState([]);
  const [selectedEntityType, setSelectedEntityType] = useState('');

  const businessEntityTypesArray = [
    { text: 'Sole Proprietorship', value: 'sole-proprietorship' },
    { text: 'Partnership', value: 'partnership' },
    { text: 'LLC/LLP', value: 'llc/llp' },
    { text: 'Corporation', value: 'corporation' },
    {
      text: 'Unincorporated Organization',
      value: 'unincorporated organization',
    },
    { text: 'Non-Profit Organization', value: 'non-profit-organization' },
  ];

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitted },
  } = useForm({ mode: 'onChange' });

  const onSubmit = (data) => {
    console.log(data);
    setSelectedTemplate('ShareTeam');
  };

  const setSelectedTemplate = useModalStore(
    (state) => state.setSelectedTemplate
  );

  // get country data
  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const res = await fetch(process.env.REACT_APP_ENDPOINT);
        if (!res.ok) {
          throw new Error('Failed');
        }
        const data = await res.json();
        setCountries(data);
      } catch (error) {
        console.error('Fetch Error:', error);
      }
    };
    fetchCountryData();
  }, []);

  // get selected country
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setValue('region', selectedOption.value);
    // setCountry(selectedOption);
  };

  const handleBusinessEntityChange = (selectedType) => {
    setSelectedEntityType(selectedType);
    setBusinessEntityType(selectedType);
  };

  return (
    <>
      <div className='balance-create-popup-title-container'>
        <h2 className='balance-create-popup-title balance-create-popup-title-center'>
          Bring your application template to life
        </h2>
      </div>
      <div className='balance-create-popup-application-container'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='balance-create-popup-application-input'>
            <label
              htmlFor='name'
              className='balance-create-popup-application-input-label label-first'
            >
              Give it a name
              {isSubmitted && !errors.password && (
                <span className='balance-validation-error-marker'>*</span>
              )}
            </label>
            <input
              id='name'
              type='text'
              placeholder='e.g Application template'
              className='balance-global-input'
              {...register('name', { required: true })}
              onChange={(e) => setEditTemplateName(e.target.value)}
            />
          </div>
          {templateType === 'BusinessChecking' && (
            <div className='balance-create-popup-application-input'>
              <label
                htmlFor='entityType'
                className='balance-create-popup-application-input-label'
              >
                Business Entity
                {isSubmitted && !errors.password && (
                  <span className='balance-validation-error-marker'>*</span>
                )}
              </label>
              <Select
                id='region'
                value={selectedEntityType}
                onChange={handleBusinessEntityChange}
                options={businessEntityTypesArray}
                getOptionLabel={(option) => option.text}
                getOptionValue={(option) => option.value}
                placeholder='Select a business entity type'
                className='balance-create-popup-selection'
              />
            </div>
          )}
          <div className='balance-create-popup-application-input'>
            <label
              htmlFor='region'
              className='balance-create-popup-application-input-label'
            >
              Application region
              {isSubmitted && !errors.password && (
                <span className='balance-validation-error-marker'>*</span>
              )}
            </label>
            <Select
              id='region'
              value={selectedCountry}
              onChange={handleCountryChange}
              options={countries}
              getOptionLabel={(option) => option.text}
              getOptionValue={(option) => option.value}
              placeholder='Select an application template region'
              className='balance-create-popup-selection'
            />
          </div>
          <Button
            buttonName='Continue'
            className='balance-global-blue-button balance-global-movable-button'
          />
        </form>
      </div>
    </>
  );
};

export default CreatePopup;
