import { Icon } from '@iconify/react';
import './ProgressBar.css';

const ProgressBar = () => {
  return (
    <div className="balance-progress-bar-container">
      <div className="balance-progress-bar-step completed">
        <div className="balance-progress-bar-circle">
          <Icon icon="mdi:transit-connection-variant" className="balance-progress-bar-icon" />
        </div>
        <div className="balance-progress-bar-line"></div>
      </div>
      <div className="balance-progress-bar-step completed">
        <div className="balance-progress-bar-circle">
          <Icon icon="mdi:encryption-outline" className="balance-progress-bar-icon" />
        </div>
        <div className="balance-progress-bar-line"></div>
      </div>
      <div className="balance-progress-bar-step completed">
        <div className="balance-progress-bar-circle">
          <Icon icon="material-symbols:cloud-outline" className="balance-progress-bar-icon" />
        </div>
        <div className="balance-progress-bar-line"></div>
      </div>
      <div className="balance-progress-bar-step">
        <div className="balance-progress-bar-small-circle"></div>
      </div>
    </div>
  );
};

export default ProgressBar;
