import React from 'react';
import ForgotForm from '../Forgot/ForgotForm';

const ForgotUserName = () => {
  return (
    <div>
      <ForgotForm formTitle='Forgot your username?' subText='No worries! Enter your email below to receive your username.' questionText='Remember your username?' />
    </div>
  );
};

export default ForgotUserName;
