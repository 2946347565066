import { create } from 'zustand';

const useSignUpStore = create((set) => ({
  decodedEmail: '',
  firstName: '',
  lastName: '',
  jobTitle: '',
  jobLevel: '',
  jobDepartment: '',
  password: '',
  confirmPassword: '',
  companyName: '',

  setDecodedEmail: (email) => set({ decodedEmail: email }),
  setFirstName: (name) => set({ firstName: name }),
  setLastName: (name) => set({ lastName: name }),
  setJobTitle: (title) => set({ jobTitle: title }),
  setJobLevel: (level) => set({ jobLevel: level }),
  setJobDepartment: (department) => set({ jobDepartment: department }),
  setPassword: (key) => set({ password: key }),
  setConfirmPassword: (key) => set({ confirmPassword: key }),
  setCompanyName: (name) => set({ companyName: name }),
}));

export default useSignUpStore;
