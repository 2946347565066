import './PopupGlobal.css';

const FormSentFailedPopup = () => {
  return (
    <div className='balance-form-sent-popup-container'>
      <p className='balance-form-sent-popup-message'>
        Email unable to send at this time. Please try again later.
      </p>
    </div>
  );
};

export default FormSentFailedPopup;