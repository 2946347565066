import './PaidPage.css';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import Select from 'react-select';
import ServerClient from '../../../services/api-client';
import {loadStripe} from '@stripe/stripe-js';
import BalanceLogo from '../../../assets/Logo/Balance_Big_Logo.svg';
import SmallLogo from '../../../assets/Logo/Balance_Logo.svg';

const PaidPage = () => {
    const [hasAccount, setHasAccount] = useState(false);
    const navigate = useNavigate();

    // if (hasAccount) {
    //     return (
    //     <div className='balance-paid-page-container'>
    //         <PreStripeCheck />
    //     </div>
    //     )
    // }
    return (
        <div className='balance-paid-page-container'>
            <div className="balance-pricing-light-background">
                <img src={BalanceLogo} alt="Logo" className='balance-pricing-logo' />
            </div>
           {!hasAccount ? <div className='balance-paid-page-subcontainer'>
                <h1 className='balance-paid-page-title'>Thank you for choosing <img src={SmallLogo} className='balance-paid-page-inline-logo' /><br></br>To continue the process, select from below...</h1>
                <div className='balance-paid-page-button-container'>
                    <Button buttonName={'I have an account'} className={'balance-paid-page-button balance-paid-page-good-button'} onClick={() => setHasAccount(true)} />
                    <a href='https://balanceapp.io/Sign-up' className='balance-paid-page-button balance-paid-page-link' target='_blank'>Sign Up</a>
                    {/* <Button buttonName={'Sign Up'} className={'balance-paid-page-button'} onClick={() => navigate('/Sign-up')} /> */}
                </div>
            </div> : <div className='balance-paid-page-subcontainer'><PreStripeCheck /></div>}
        </div>
    )
}

const PreStripeCheck = () => {
    // const [numSeats, setNumSeats] = useState({seatLabel: '1', seatValue: 1});
    const [userEmail, setUserEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const buttonOnSubmit = async (event, price) => {
        event.preventDefault();
        setEmailError('');
        setIsLoading(true);
        try {
            const serverClient = new ServerClient('/api/checkPaymentPlan');
            const response = await serverClient.post({ email: userEmail});

            console.log('response is: ', response);

            if (response.data?.paymentPlanError) {
                throw new Error('Unable to find account.');
            }

            if (response.data?.paymentPlan === 'Pro' || response.data?.paymentPlan === 'Enterprise') {
                throw new Error('Already a paid user.')
            }

            // if (!response.data?.isCompanyCreator) {
            //     setEmailError('Entered email does not match an email verified to make purchases. Please contact your administrative team.')
            //     return;
            // }

            const stripeServerClient = new ServerClient('/api/stripeSessionCreation');
            const body = {
                price: price,
                quantity: 1,
                userEmail: userEmail,
            }

            const res = await stripeServerClient.post(body);

            const stripe = await loadStripe(process.env.REACT_APP_STRIPE);
            const result = await stripe.redirectToCheckout({
                sessionId: res.data?.id,
            })

        } catch (err) {
            setEmailError(err.message);
            console.log('stripe err is: ', err);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className='balance-prestripe-check-container'>
            <div className='balance-prestripe-check-email-container'>
                <form className='balance-prestripe-form'>
                    <label htmlFor='prestripe-input' className='balance-prestripe-check-label'>Account Email: </label>
                    <input className='balance-global-input balance-prestripe-check-input' id='prestripe-input' type='text' value={userEmail} onChange={(e) => setUserEmail(e.target.value)} autoFocus />
                    <Button buttonName={isLoading ? 'Loading...' : 'Continue'} className={'balance-paid-page-button balance-global-movable-button balance-paid-page-good-button'} onClick={(event) => buttonOnSubmit(event, 'price_1Pb3k408wjiZbZAUokvezljV')} disabled={isLoading} />
                    <p className='balance-prestripe-check-email-error-text'>{emailError}</p>
                </form>
            </div>
        </div>
    )
}

export default PaidPage;