import { create } from 'zustand';

const useStore = create((set) => ({
  // state
  openNav: true,
  // update state
  setOpenNav: () => set((state) => ({ openNav: !state.openNav })),
}));

export default useStore;
