import ForgotForm from './ForgotForm';
import './ForgotForm.css';

const ResetPassword = () => {
  return (
    <div>
      <ForgotForm formTitle="Create New Password" isResetPassword={true} />
    </div>
  );
};

export default ResetPassword;
