import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import Button from '../../../components/Button/Button';
import './Account.css';
import './Settings.css';

const Account = () => {
  const navigate = useNavigate();

  return (
    <div className="account-settings-container balance-settings-container">
      <div className="balance-settings-back-button-container" onClick={() => navigate('/Hub/Settings')}>
        <Icon icon="ic:sharp-arrow-back-ios" className="balance-settings-back-button" />
      </div>
      <div className="account-settings-title-container">
        <h1 className="balance-settings-title">Account</h1>
        <p className="balance-settings-description">Manage your account and billing information.</p>
        {/* line */}
        <div className="balance-settings-line"></div>
      </div>
      <div className="account-settings-information-container">
        {/* top */}
        <div className="top-container">
          <div className="balance-account-plan-details-container">
            <div className="balance-account-plan-details-title-container">
              <h3 className="balance-account-title">Plan Details</h3>
              <h3 className="balance-account-title">Features</h3>
              <Button buttonName="Change Plan" className="balance-account-change-button balance-global-movable-button" />
            </div>
            <div className="balance-account-plan-details">
              <p className="balance-account-plan-name">Free Plan / $0</p>
              <p className="balance-account-next-payment">
                Renew at<span>July 4th, 2024</span>
              </p>
            </div>
          </div>
        </div>
        {/* bottom */}
        <div className="bottom-container">
          <div className="balance-billing-container">
            <h3 className="balance-account-title">Billing Information</h3>
            <div className="balance-account-billing-information-container">
              <p className="billing-name">John Doe</p>
              <p className="billing-email">abc@gmail.com</p>
              <p className="billing-number">808-243-1111</p>
              <p className="billing-address">Kaloha St. Honolulu, HI</p>
              <p className="billing-info">Visa ending ****1234</p>
            </div>
            <div>
              <Button buttonName="Update billing information" className="balance-global-blue-button balance-update-payment-button" />
            </div>
          </div>
          {/* <div className="balance-subscription-status-container">
            <h3 className="balance-account-title">Subscription Status</h3>
            <div></div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Account;
