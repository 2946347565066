import './FormHeader.css';
import BalanceLogo from '../../assets/Logo/Balance_Logo.svg';

const FormHeader = () => {
  return (
    <header className='balance-form-header'>
      <img src={BalanceLogo} alt='Logo' />
    </header>
  );
};

export default FormHeader;
