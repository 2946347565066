import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// for single tab use
export function useHashTab(setActiveTab, tabName) {
  const { hash } = useLocation();
  useEffect(() => {
    const cleanHash = hash.replace('#', '');
    if (cleanHash === tabName || cleanHash === tabName) {
      setActiveTab(cleanHash);
    }
  }, [hash]);
}

// for mutiple tabs use
export function useHashNestTab(selectedOption, setSelectedOption, emailOption, linkOption) {
  const { hash } = useLocation();

  useEffect(() => {
    let option = null;
    if (hash.includes(`${emailOption}-Form`)) {
      option = emailOption;
    } else if (hash.includes(`${linkOption}-Form`)) {
      option = linkOption;
    }

    // console.log("current hash:", hash);
    // console.log("current option:", option);

    if (option !== selectedOption) {
      setSelectedOption(option);
    }
  }, [hash, emailOption, linkOption, selectedOption, setSelectedOption]);

  // const options = [emailOption, linkOption];
  const options = [emailOption];
  return { options };
}
