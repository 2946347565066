import { create } from 'zustand';

const useClientStore = create((set) => ({
  clientName: '',
  clientData: [],
  sharedClientData: [],
  selectedUniqueId: null, // change status data

  setClientName: (name) => set({ clientName: name }),
  setClientData: (data) => set({ clientData: data }),
  setSelectedUniqueId: (id) => set({ selectedUniqueId: id }), // change status data
  setSharedClientData: (data) => set({ sharedClientData: data }),
}));

export default useClientStore;
