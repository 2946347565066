import Select from 'react-select';
import './PopupGlobal.css';
import DocumentUploadInputCompleted from '../../pages/FormPortal/DocumentUploadInputCompleted';

const ApplicationPopup = ({ formData }) => {
  const options = [{ value: 'Citizen', label: 'Citizen' }];

  // const formData = formData[0];
  // return ( <div className='holder'>For now...</div>)
  return (
    <div className='balance-application-popup-container'>
      {formData?.map((data, dataIndex) => (
        <div key={dataIndex}>
          {/* sections */}
          {data?.map((section, sectionIndex) => (
              <div key={sectionIndex} className='section-container'>
                <p className='form-portal-form-title'>
                  {section?.sectionTitle}
                </p>
                <p className='form-portal-form-description'>
                  {section?.sectionDesc}
                </p>
                {/* inputs */}
                {section?.sectionInputs.map((input, inputIndex) => (
                  <div
                    key={inputIndex}
                    className='form-portal-form-input-container'
                  >
                    {/* all label */}
                    <label className='form-portal-form-input-title'>
                      {input?.inputTitle}
                    </label>
                    {/* short text */}
                    {input?.inputType === 'shortText' && (
                      <input
                        type='text'
                        name={input.inputTitle}
                        placeholder={input.inputPlaceholder}
                        value={input?.inputUserAnswer}
                        className='short-text-style'
                        disabled
                      />
                    )}
                    {/* long text */}
                    {input.inputType === 'longText' && (
                      <input
                        type='text'
                        name={input.inputTitle}
                        placeholder={input.inputPlaceholder}
                        value={input?.inputUserAnswer}
                        className='long-text-style'
                        disabled
                      />
                    )}
                    {/* dropdown */}
                    {input.inputType === 'longDropD' && (
                      <input
                        type='text'
                        name={input.inputTitle}
                        value={input?.inputUserAnswer.toUpperCase()}
                        className='long-text-style'
                        disabled
                      />
                      // <Select
                      //   name={input.inputTitle}
                      //   defaultInputValue={input?.inputUserAnswer}
                      //   // options={[input?.inputUserAnswer]}
                      //   // isDisabled={true}
                      //   className='dropdown-style'
                      // />
                    )}
                    {/* documents */}
                    {input.inputType === 'documents' && (
                      
                      <>
                        {/* <div>{typeof(input?.inputUserAnswer)}</div> */}
                        <DocumentUploadInputCompleted fileData={input?.inputUserAnswer} />
                      </>
                    )
                    }
                  </div>
                ))}
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};

export default ApplicationPopup;
