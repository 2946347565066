import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Icon } from '@iconify/react';
// components
import PaymentUpdateForm from './PaymentUpdateForm';
import './PaymentMethods.css';

const stripeLoad = loadStripe(process.env.REACT_APP_STRIPE);

const PaymentMethods = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState({ text: '', type: 'success' });

  return (
    <div className="balance-payment-methods-container balance-settings-container">
      <div className="balance-settings-back-button-container" onClick={() => navigate('/Hub/Settings')}>
        <Icon icon="ic:sharp-arrow-back-ios" className="balance-settings-back-button" />
      </div>
      <div className="support-page-title-container">
        <h1 className="balance-settings-title">Payment Methods</h1>
        <p className="balance-settings-description">View and edit your saved payment methods for transactions.</p>
        <div className="balance-settings-line"></div>
      </div>
      <div className="balance-payment-methods-contents-container">
        <div className="balance-payment-methods-contents-inner-container">
          <h3 className="balance-payment-methods-title"></h3>
          <Elements stripe={stripeLoad} options={{ locale: 'en' }}>
            <PaymentUpdateForm setMessage={setMessage} />
          </Elements>
          {message.text && (
            <p className="balance-payment-methods-message">
              <Icon
                icon={message.type === 'success' ? 'weui:done2-filled' : 'ic:baseline-error-outline'}
                style={{ color: message.type === 'success' ? '#02CB96' : '#FF0000', width: '24px', height: '24px' }}
              />
              {message.text}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentMethods;
