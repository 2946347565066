import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRetrieveNotifications } from '../../../hooks/useNotificationHooks';
import useUserStore from '../../../store/useUserStore';
import { useGetClientHubForms } from '../../../hooks/useClientHooks';
import Button from '../../../components/Button/Button';
import Search from '../../../components/Search/Search';
import FilterButton from '../../../components/Button/FilterButton';
import './Dashboard.css';
import '../../../components/Grid/Grid.css';
import DotIcon from '../../../assets/Icon/3Dot_Button_Icon.svg';
import Avatar from '../../../assets/Avatar/Avatar.svg';
import UpgradeAccountPopup from '../../../components/Popup/UpgradeAccountPopup';
import Modal from '../../../components/Popup/Modal';

const Dashboard = () => {
  const { userName } = useUserStore();
  const { data: retrieveNotificationsData } = useRetrieveNotifications();
  const { data: getClientHubFormsData } = useGetClientHubForms();
  const navigate = useNavigate();

  const getTypeStyle = (status) => {
    switch (status) {
      case 'Approved':
        return 'status-1';
      case 'Under Review':
        return 'status-2';
      case 'Denied':
        return 'status-3';
      default:
        return '';
    }
  };

  const timeAgo = (date) => {
    const now = new Date();
    const difference = now - new Date(date);
    const seconds = Math.floor(difference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds < 90) {
      return 'Just now';
    } else if (minutes < 60) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    }
  };

  const formatDate = (date) => {
    const dateObj = new Date(date);
    return dateObj.toString().slice(4, 15);
  };

  const latestNotifications = retrieveNotificationsData?.slice(0, 5) || [];
  const latestClientHubForms = getClientHubFormsData?.completedForms.slice(0, 5) || [];

  return (
    <div className="balance-dashboard-container">
      <div className="balance-dashboard-title-container">
        <h1 className="balance-dashboard-title">Welcome back, {userName || 'User'}</h1>
        <p className="balance-dashboard-title-description">Track and manage your clients here.</p>
      </div>
      <div className="balance-dashboard-overview-container">
        <p className="balance-dashboard-overview">Overview</p>
        <div className="over-view-border"></div>
      </div>
      {/* <Modal width={'40rem'} loader={true}>
        <UpgradeAccountPopup message={'Maximum number of applications sent.'} onClose={() => console.log('Closing worked!')} />
      </Modal> */}

      <div className="balance-dashboard-contents-container">
        {/* left container */}
        <div className="balance-workflow-container">
          <header className="balance-dashboard-contents-header">
            <h3 className="balance-dashboard-header-title">Workflow activity</h3>
            <Button
              buttonName="View more"
              className="balance-dashboard-view-more-button"
              onClick={() => navigate('/Hub/Notifications')}
            />
          </header>
          <div className="balance-dashboard-table-container">
            <p className="balance-dashboard-workflow-title">Activities</p>
            {/* <div className="balance-dashboard-workflow-activity-container"></div> */}
            {latestNotifications.length > 0 ? (
              latestNotifications.map((notification, index) => (
                <div key={index}>
                  <div className="balance-dashboard-workflow-activity-container">
                    <div className="balance-dashboard-workflow-description-container">
                      <p className="balance-dashboard-workflow-description">
                        <span className="balance-dashboard-workflow-sender-name">
                          {notification.type === 'clientApplicationSubmission'
                            ? notification.clientName
                            : notification.senderName}
                        </span>
                        <span className="balance-dashboard-space">
                          {(notification.type === 'taskAssigned' && (
                            <span className="balance-dashboard-workflow-notification">
                              assigned a
                              <span className="balance-notifications-link">
                                <Link to="/Hub/Tasks" className='dashboard-link'>task</Link>
                              </span>
                              to you
                            </span>
                          )) ||
                            (notification.type === 'statusChange' && (
                              <span className="balance-dashboard-workflow-notification">
                                changed a status in your
                                <span className="balance-notifications-link">
                                  <Link to="/Hub/Tasks" className='dashboard-link'>task</Link>
                                </span>
                              </span>
                            )) ||
                            (notification.type === 'clientApplicationSubmission' && (
                              <span className="balance-dashboard-workflow-notification">
                                submitted your
                                <span className="balance-notifications-link">
                                  <Link to="/Hub/Clients" className='dashboard-link'>application</Link>
                                </span>
                              </span>
                            )) ||
                            (notification.type === 'shareTemplates' && (
                              <span className="balance-dashboard-workflow-notification">
                                shared a
                                <span className="balance-notifications-link">
                                  <Link to="/Hub/CreateTemplatesInitial" className='dashboard-link'>template</Link>
                                </span>
                                with you
                              </span>
                            )) ||
                            (notification.type === 'taskRejected' && (
                              <span className="balance-dashboard-workflow-notification">
                                rejected your
                                <span className="balance-notifications-link">
                                  <Link to="/Hub/Tasks" className='dashboard-link'>task</Link>
                                </span>
                              </span>
                            )) ||
                            (notification.type === 'Application' && (
                              <span className="balance-dashboard-workflow-notification">
                                shared an
                                <span className="balance-notifications-link">
                                  <Link to="/Hub/Clients" className='dashboard-link'>application</Link>
                                </span>
                                with you
                              </span>
                            )) ||
                            (notification.type === 'Document' && (
                              <span className="balance-dashboard-workflow-notification">
                                shared a
                                <span className="balance-notifications-link">
                                  <Link to="/Hub/Clients" className='dashboard-link'>application</Link>
                                </span>
                                with you
                              </span>
                            ))}
                        </span>
                        <span className="balance-dashboard-workflow-link-name">{notification.application}</span>
                      </p>
                      <p className="balance-dashboard-workflow-time">{timeAgo(notification.assignedDate)}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="balance-grid-no-records-container">
                <p className="balance-grid-no-records">No records to display</p>
              </div>
            )}
          </div>
        </div>
        {/* right table */}
        <div className="balance-recent-applications-container">
          <header className="balance-dashboard-contents-header">
            <h3 className="balance-dashboard-header-title">Recently submitted applications</h3>
            <Button
              buttonName="View more"
              className="balance-dashboard-view-more-button"
              onClick={() => navigate('/Hub/Clients')}
            />
          </header>
          <div className="balance-grid-dashboard-table">
            <div className="balance-grid-dashboard-header">Application</div>
            <div className="balance-grid-dashboard-header">Client</div>
            <div className="balance-grid-dashboard-header">Date Submitted</div>
            <div className="balance-grid-dashboard-header">Status</div>
            <div className="balance-grid-dashboard-header"></div>
            {latestClientHubForms.length > 0 ? (
              latestClientHubForms.map((item, index) => (
                <React.Fragment key={index}>
                  <div className="balance-grid-cell">
                    <span className="balance-grid-dashboard-application-name">{item.formTitle}</span>
                  </div>
                  <div className="balance-grid-cell">
                    <span className="balance-grid-dashboard-client-name">{item.clientName}</span>
                  </div>
                  <div className="balance-grid-cell">
                    <span className="balance-grid-dashboard-date">{formatDate(item?.timeSubmitted)}</span>
                  </div>
                  <div className="balance-grid-cell">
                    <span className={`${getTypeStyle(item.applicationStatus || 'Under Review')}`}>
                      {item.applicationStatus || 'Under Review'}
                    </span>
                  </div>
                  <div className="balance-grid-cell">
                    <Button icon={DotIcon} iconPosition="after" className="balance-grid-dot-button" />
                  </div>
                </React.Fragment>
              ))
            ) : (
              <div className="balance-grid-no-records-container">
                <p className="balance-grid-no-records">No records to display</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
