import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { useLocation } from 'react-router-dom';
import useUserStore from './store/useUserStore';
import useNotificationStore from './store/useNotificationStore';

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const { userId } = useUserStore();
  const { addNotification, hasNotification } = useNotificationStore();
  const socket = useRef(null);

  const location = useLocation();

  useEffect(() => {
    // console.log('socket.current before', socket.current);
    if (!socket.current) {
      socket.current = io('https://linkappnyc.com', { withCredentials: true }); // prod use
      // socket.current = io('http://localhost:5000'); // local use
      // console.log('socket.current after', socket.current);
    }
    // connect
    const handleConnect = async () => {
      // console.log('Connected to the server!');
      // for client portal to stay connect
      const params = new URLSearchParams(location.search);
      const userIdParam = params.get('user');
      const registerToId = userIdParam || userId;
      socket.current.emit('register', registerToId);
      setIsSocketConnected(true);
    };

    const handleUpdatedNotification = (data) => {
      // console.log('Received notification:', data);
      // prevent duplicate notification
      if (!hasNotification(data.uniqueId)) {
        addNotification(data);
      }
    };

    socket.current.on('connect', handleConnect);
    socket.current.on('updatedNotification', handleUpdatedNotification);

    // clean up
    return () => {
      socket.current.off('connect', handleConnect);
      socket.current.off('updatedNotification', handleUpdatedNotification);
    };
  }, [location.search, userId, addNotification, hasNotification]);

  // disconnect when logout
  const handleLogout = () => {
    if (socket.current) {
      socket.current.emit('logout', userId);
      socket.current.disconnect();
      // console.log(`User ${userId} logged out.`);
    }
  };

  return <SocketContext.Provider value={{ socket: socket.current, handleLogout, isSocketConnected }}>{children}</SocketContext.Provider>;
};
