import './ThankYouPage.css';

const ErrorPage = () => {
    return (
      <div className='balance-thank-you-page-container'>
        <div className='balance-thank-you-page-main'>
          <h1>Error.</h1>
          <p className='balance-thank-you-page-description'>
            We apologize, an error has occured and we could not submit your form. <br />
            Please try again later, if the error persists, contact your banking representative.
          </p>
        </div>
      </div>
    );
  };
  
  export default ErrorPage;