import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import ServerClient from '../services/api-client';
import useUserStore from '../store/useUserStore';

// asign tasks
export function useAssignTasks() {
  const setTaskData = useUserStore((state) => state.setTaskData);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newTask) => {
      const serverClient = new ServerClient('/api/assignTasks');
      const res = await serverClient.post({ ...newTask });
      // console.log('newTask', newTask);
      return res.data;
    },
    onSuccess: (data) => {
      setTaskData(data);
      console.log('Inserted.');
      queryClient.invalidateQueries(['assignedTasks']);
    },
    onError: (error) => {
      console.error('Error inserting task:', error);
    },
  });
}

// retrieve assigned tasks
export function useGetAssignedTasks() {
  const { userId } = useUserStore();
  return useQuery({
    queryKey: ['assignedTasks'],
    queryFn: async () => {
      const serverClient = new ServerClient('/api/db/getAssignedTasks');
      const res = await serverClient.post({ userId: userId });
      return res.data;
    },
    onError: (error) => {
      console.error('Error fetching assigned tasks:', error);
    },
  });
}

// retrieve my tasks
export function useGetMyTasks() {
  const { userId } = useUserStore();
  return useQuery({
    queryKey: ['myTasks'],
    queryFn: async () => {
      const serverClient = new ServerClient('/api/myTasks');
      const res = await serverClient.post({ userId: userId });
      return res.data;
    },
    onError: (error) => {
      console.error('Error fetching my tasks:', error);
    },
  });
}

// delete tasks
export function useDeleteTasks() {
  const { userId } = useUserStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ uuid }) => {
      const serverClient = new ServerClient('/api/db/deleteTasks');
      const res = await serverClient.post({ assignedToId: userId, uuid });
      return { data: res.data, uuid };
    },
    onSuccess: (res) => {
      console.log(`${res.uuid} Deleted.`);
      queryClient.invalidateQueries(['MyTasks']);
    },
    onError: (error) => {
      console.error('Error deleting task:', error);
    },
  });
}

// delete assigned tasks
export function useDeleteAssignedTasks() {
  const { userId } = useUserStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ uuid }) => {
      const serverClient = new ServerClient('/api/db/deleteAssignedTasks');
      const res = await serverClient.post({ userId, uuid });
      return { data: res.data, uuid };
    },
    onSuccess: (res) => {
      console.log(`${res.uuid} Deleted.`);
      queryClient.invalidateQueries(['MyTasks']);
    },
    onError: (error) => {
      console.error('Error deleting task:', error);
    },
  });
}

// delete archived tasks
export function useDeleteArchivedTasks() {
  const { userId } = useUserStore();
  // const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ uuid }) => {
      const serverClient = new ServerClient('/api/db/deleteArchivedTasks');
      const res = await serverClient.post({ assignedToId: userId, uuid });
      return { data: res.data, uuid };
    },
    onSuccess: (res) => {
      console.log(`${res.uuid} Deleted.`);
      // queryClient.invalidateQueries(['archivedTasks']);
    },
    onError: (error) => {
      console.error('Error deleting task:', error);
    },
  });
}

// archive tasks set to true
export function useArchiveTasks() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ assignedToId, uuid }) => {
      const serverClient = new ServerClient('/api/db/archiveTasks');
      const res = await serverClient.post({ assignedToId, uuid });
      return { data: res.data, uuid };
    },
    onSuccess: (res) => {
      console.log(`${res.uuid} Archived hooks OK.`);
      queryClient.invalidateQueries(['MyTasks']);
    },
    onError: (error) => {
      console.error('Error archiving task:', error);
    },
  });
}

// retrieve archived tasks
export function useRetrieveArchivedTasks() {
  const { userId } = useUserStore();
  return useQuery({
    queryKey: ['archivedTasks'],
    queryFn: async () => {
      const serverClient = new ServerClient('/api/retrieveArchivedTasks');
      const res = await serverClient.post({ userId: userId });
      return res.data;
    },
    onError: (error) => {
      console.error('Error fetching my tasks:', error);
    },
  });
}
