import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ServerClient from '../../../services/api-client';
import useClientStore from '../../../store/useClientStore';
import useUserStore from '../../../store/useUserStore';
import useDropdown from '../../../hooks/useDropdown';
// components
import Button from '../../../components/Button/Button';
import Modal from '../../../components/Popup/Modal';
import Dropdown from '../../../components/Dropdown/Dropdown';
import ShareTeam from '../../../components/Popup/ShareTeam';
import ChangeStatusPopup from '../../../components/Popup/ChangeStatusPopup';
import Loading from '../../../components/Loading/Loading';
import Error from '../../../components/Loading/Error';
import DocumentPopup from '../../../components/Popup/DocumentPopup';
import ApplicationPopup from '../../../components/Popup/ApplicationPopup';
import TemplatePopups from '../Templates/CreateTemplates/TemplatePopups';
// css
import '../../../components/Grid/Grid.css';
import './ClientsProfile.css';
// svg
import ArrowDown from '../../../assets/Icon/Arrow_Down_Gray_Icon.svg';
import DotIcon from '../../../assets/Icon/3Dot_Button_Icon.svg';
// import EditIcon from "../../../assets/Icon/Editable_Icon.svg";
// import SearchIcon from "../../../assets/Icon/Search_Icon_Dark.svg";
// import ExportIcon from "../../../assets/Icon/Export_Icon.svg";
// import ShareIcon from "../../../assets/Icon/Share_Icon.svg";

const ClientsProfile = () => {
  const [searchTerm, setSearchTerm] = useState('');
  // const [templatesData, setTemplatesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedButton, setSelectedButton] = useState('View all');
  const [isViewOpen, setViewOpen] = useState(false);
  const [isShareTeamOpen, setShareTeamOpen] = useState(false);
  const [isChangeStatusOpen, setChangeStatusOpen] = useState(false);
  const [documentType, setDocumentType] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pdfIdNum, setPdfIdNum] = useState(null); // USING THIS AS A QUICK FIX FOR DEMO!
  const [fileInfo, setFileInfo] = useState({});
  const [applicationSectionData, setApplicationSectionData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [selectedClientShareData, setSelectedClientShareData] = useState({}); // for SHARING
  const [isSharedClient, setIsSharedClient] = useState(false); // for when the data IS SHARED
  const [sharedData, setSharedData] = useState({}); // for when the data IS SHARED
  // store
  // const { selectedViewData, setSelectedViewData } = useFormBuilderStore();
  const { clientData, sharedClientData } = useClientStore();
  const { userName, userId } = useUserStore();
  // custom hook
  const { openDropdown, handleClickDot, handleClickOutside, dropdownRef } = useDropdown();

  // console.log('clientData', clientData)

  const navigate = useNavigate();

  // * * * test use
  // const targetID = "6644dc1b6fa088e5ed71f2a3";
  // const testData = templatesData?.find((data) => data._id === targetID);

  useEffect(() => {
    if (clientData?.length === 0) {
      navigate('/Hub/Clients');
    }
  }, []);

  // for render
  useEffect(() => {
    const fetchCompletedForm = async () => {
      setLoading(true);
      try {
        const serverClient = new ServerClient('/api/db/getCompletedForms');
        const res = await serverClient.post({ formIdArray: [clientData?.[0]?.uniqueId] });
        // const data = res.data.filter((item) => item._id === clientData?.[0]?.uniqueId);
        // console.log('res is: ', res);
        const parseFileUploadsAndSaveData = async (data) => {
          // uploaded document(s) data comes as a string, need to parse it
          const parsedUploads = [];
          for (const completedForm of data) {
            // possible we have more than one form
            if (completedForm?.uploadedFiles) {
              // if the key exists
              const parsedArray = await JSON.parse(completedForm.uploadedFiles); // parse the initial string to be an array of string'd objects
              for (const unparsedUpload of parsedArray) {
                // then for every string'd object
                const parsedAgain = await JSON.parse(unparsedUpload); // parse it
                parsedUploads.push(parsedAgain); // add it back to an array
              }
              completedForm.uploadedFiles = parsedUploads; // now switch the old array to the new one with parsed objects
            }
          }
          setFilteredData(data);
        };
        const parseSectionsDataAndSaveData = async (data) => {
          const parsedSections = [];
          for (const completedForm of data) {
            if (completedForm?.sectionsData) {
              const parsedData = completedForm?.sectionsData;
              parsedSections.push(parsedData);
            }
          }
          setApplicationSectionData(parsedSections);
        };
        parseFileUploadsAndSaveData(res?.data);
        parseSectionsDataAndSaveData(res?.data);
        // setFilteredData(res?.data);
      } catch (error) {
        console.error('Error fetching fetchCompletedForm:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchCompletedForm();
  }, []);

  useEffect(() => {
    const grabBankerName = async (sharedClientInfo) => {
      console.log('sharedClientInfo is: ', sharedClientInfo);
      try {
        const serverClient = new ServerClient('/api/getBankerNameById');
        const res = await serverClient.post({ bankerId: sharedClientInfo?.originalBankerId });
        // console.log('res in banker name is: ', res);
        setSharedData({ ...sharedClientInfo, originalBankerName: res.data.name });
      } catch (err) {
        console.log('err is: ', err);
      }
    };
    const possibleSharedData = sharedClientData.filter((data) => data?.applicationId === clientData?.[0]?.uniqueId);
    // console.log('possibleSharedData is: ', possibleSharedData);

    if (possibleSharedData.length > 0) {
      setIsSharedClient(true);
      // setSharedData(possibleSharedData[0]);
      grabBankerName(possibleSharedData[0]);
    }
  }, [userId]);

  // for click done
  const handleUpdateStatus = async (status) => {
    try {
      const serverClient = new ServerClient('/api/updateClientStatus');
      const res = await serverClient.post({
        mongoId: selectedData?._id,
        applicationStatus: status,
      });
      setFilteredData((prevData) => prevData.map((item) => (item._id === selectedData?._id ? { ...item, applicationStatus: status } : item)));
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  // search
  // filter keyword
  // const handleSearch = (e) => {
  //   const keyword = e.target.value.toLowerCase();
  //   setSearchTerm(keyword);
  //   const filtered = filteredData.filter(
  //     (item) =>
  //       item.formTitle.toLowerCase().includes(keyword) &&
  //       (selectedButton === 'View all' || item.document === selectedButton)
  //   );
  //   setFilteredData(filtered);
  // };
  // filter file name
  // useEffect(() => {
  //   // console.log('filteredData is: ', filteredData);
  //   if (selectedButton === 'View all') {
  //     setFilteredData(filteredData);
  //   } else {
  //     const filtered = filteredData.filter(
  //       (item) => item.document === selectedButton
  //     );
  //     setFilteredData(filtered);
  //     console.log(filtered);
  //   }
  // }, [selectedButton]);

  // * * * dropdopwn view, share, close
  // view
  const handleClickDocumentPopup = (documentType, pdfID, documentInfo) => {
    setDocumentType(documentType);
    if (documentInfo) {
      setFileInfo(documentInfo);
    }
    if (pdfID) {
      setPdfIdNum(pdfID);
    }
    setViewOpen(true);
  };
  const closeDocumentPopup = () => {
    setViewOpen(false);
  };
  // share
  const handleClickShareTeamPopup = (application, document, documentType) => {
    setSelectedData(application);
    setSelectedClientShareData({ applicationId: application?.uniqueId, originalBankerId: application?.destinationBankerId, document: document });
    setShareTeamOpen(true);
    setDocumentType(documentType);
  };
  const closeShareTeamPopup = () => {
    setSelectedData({});
    setSelectedClientShareData({});
    setShareTeamOpen(false);
  };
  // change status
  const handleClickStatus = (item) => {
    setSelectedData(item);
    setChangeStatusOpen(true);
  };
  const closeChangeStatus = () => {
    setChangeStatusOpen(false);
  };

  // table status labels
  const getTypeStyle = (status) => {
    switch (status) {
      case 'Approved':
        return 'status-1';
      case 'Under Review':
        return 'status-2';
      case 'Denied':
        return 'status-3';
      default:
        return 'status-2';
    }
  };

  return (
    <div className="balance-clients-profile-container" onClick={handleClickOutside}>
      <>
        {loading ? (
          <Loading />
        ) : error ? (
          <Error />
        ) : (
          <>
            <>
              {/* header */}
              <header className="balance-clients-profile-header">
                <span className="balance-clients-profile-index">Clients /</span>
                <span className="balance-clients-profile-index-name">{clientData?.[0]?.clientName || 'Client'}</span>
              </header>
              {/* client info */}
              <div className="balance-clients-profile-top-container">
                <div className="balance-clients-profile-top-section">
                  <div>
                    <h1 className="balance-clients-profile-name">{clientData?.[0]?.clientName || 'Client'}</h1>
                    <p className="balance-clients-profile-company">{clientData?.[0]?.companyName}</p>
                  </div>
                  {/* <div> // removed for Yem NYC tech week
                    <img src={EditIcon} alt="Icon" />
                  </div> */}
                </div>
                <div className="balance-clients-profile-info-container">
                  <div>
                    <p className="balance-clients-profile-info-title">Member since</p>
                    <p className="balance-clients-profile-info-date">{new Date(clientData?.[0]?.timeSubmitted).toLocaleDateString() || '01/01/2000'}</p>
                  </div>
                  <div>
                    <p className="balance-clients-profile-info-title">Assigned to</p>
                    <p className="balance-clients-profile-info-name">{isSharedClient ? sharedData?.originalBankerName : userName || 'You'}</p>
                  </div>
                </div>
              </div>
            </>
            <div className="balance-clients-profile-middle-section">
              {/* view all, documents, applications buttons */}
              <div className="balance-clients-profile-button-container">
                <Button
                  onClick={() => {
                    setSelectedButton('View all');
                  }}
                  buttonName="View all"
                  className={`balance-clients-profile-view-all-button ${selectedButton === 'View all' ? 'selected-button' : ''}`}
                />
                <Button
                  onClick={() => {
                    setSelectedButton('Document');
                  }}
                  buttonName="Documents"
                  className={`balance-clients-profile-documents-button ${selectedButton === 'Document' ? 'selected-button' : ''}`}
                />
                <Button
                  onClick={() => {
                    setSelectedButton('Application');
                  }}
                  buttonName="Applications"
                  className={`balance-clients-profile-applications-button ${selectedButton === 'Application' ? 'selected-button' : ''}`}
                />
              </div>
              {/* search bar */}
              {/* <div className="balance-clients-profile-search-container"> // removed for Yem NYC tech week
                <Button icon={SearchIcon} iconPosition="before" iconStyle="balance-clients-profile-search-icon" className="balance-clients-profile-search-button" />
                <input
                  type="search"
                  id="search-templates"
                  placeholder="Search file name"
                  className="balance-clients-profile-search-input"
                  // value={searchTerm}
                  // onChange={handleSearch}
                />
              </div> */}
            </div>
            {/* buttons view all, documents, applications */}
            <div className="balance-clients-profile-grid-container">
              {/* grid header */}
              <div className="balance-grid-clients-profile-header-container">
                <div className="balance-grid-clients-profile-title-header">
                  <p>All files</p>
                </div>
                {/* <div className="balance-grid-clients-button-container">  // removed for Yem NYC tech week
                  <Button icon={ExportIcon} iconPosition="before" iconStyle="balance-clients-profile-header-icon" className="balance-clients-profile-header-button" />
                  <Button icon={ShareIcon} iconPosition="before" iconStyle="balance-clients-profile-header-icon" className="balance-clients-profile-header-button" />
                  <TemplatePopups />
                </div> */}
              </div>
              {/* grid table */}
              <div className={`${selectedButton === 'Document' ? 'document-table' : 'balance-grid-clients-profile-table'}`}>
                {selectedButton !== 'Document' ? (
                  <div className="balance-grid-clients-header">
                    {/* <input type="checkbox" className="balance-grid-checkbox" /> */}
                    Application name
                  </div>
                ) : (
                  <div className="balance-grid-clients-header">
                    {/* <input type="checkbox" className="balance-grid-checkbox" /> */}
                    Document name
                  </div>
                )}
                {selectedButton !== 'Document' && <div className="balance-grid-clients-header">Application status</div>}
                <div className="balance-grid-clients-header">Application Type</div>
                <div className="balance-grid-clients-header">Submission date</div>
                {selectedButton !== 'Document' && <div className="balance-grid-clients-header">Related documents</div>}
                <div className="balance-grid-clients-header"></div>
                {/* grid record */}
                {filteredData?.map((item, index) => (
                  <React.Fragment key={item?.uniqueId || index + 100}>
                    {/* The below conditional is a bit confusing, but it's just 'if this is a client that has been shared, and the application has been shared, or if this isnt a shared client, meaning it is YOUR client, then render the application */}
                    {(isSharedClient && sharedData.appIsShared) || !isSharedClient
                      ? selectedButton !== 'Document' && (
                          <React.Fragment>
                            <div className="balance-grid-cell balance-grid-title">
                              {/* <input type="checkbox" className="balance-grid-checkbox" /> */}
                              <div>
                                <span className="balance-grid-clients-profile-file-name">{item?.formTitle || 'Application'}</span>
                              </div>
                            </div>
                            {selectedButton !== 'Document' && (
                              <div className="balance-grid-cell ">
                                <span className={`balance-grid-clients-profile-status ${getTypeStyle(item?.applicationStatus)}`}>{item?.applicationStatus || 'Under Review'}</span>
                              </div>
                            )}
                            <div className="balance-grid-cell balance-grid-clients-profile-type">
                              <span>{item?.templateType || 'Business'}</span>
                            </div>
                            <div className="balance-grid-cell balance-grid-clients-profile-date">
                              <span>{new Date(item?.updatedDate || item?.timeSubmitted).toLocaleDateString()}</span>
                            </div>
                            {selectedButton !== 'Document' && <div className="balance-grid-cell balance-grid-clients-profile-updated"></div>}
                            <div className="balance-grid-cell">
                              <Button icon={DotIcon} iconPosition="after" className="balance-grid-dot-button" onClick={(e) => handleClickDot(item.id, e)} />
                              {/* dropdown */}
                              {openDropdown[item.id] && (
                                <Dropdown
                                  item={openDropdown[item.id]}
                                  openDropdown={openDropdown}
                                  dropdownRef={dropdownRef}
                                  dropdownButtons={!isSharedClient ? [
                                    {
                                      label: 'View',
                                      onClick: () =>
                                        handleClickDocumentPopup(
                                          'Application',
                                          // item.document,
                                          item
                                        ),
                                    },
                                    {
                                      label: 'Share',
                                      onClick: () => handleClickShareTeamPopup(item, null, 'Application'),
                                    },
                                    // {
                                    //   label: "Share",
                                    //   onClick: () => handleClickShareTeamPopup(item),
                                    // },
                                    {
                                      label: 'Change Status',
                                      onClick: () => handleClickStatus(item),
                                    },
                                    // { label: "Delete" },
                                  ] : [
                                  {
                                    label: 'View',
                                    onClick: () =>
                                      handleClickDocumentPopup(
                                        'Application',
                                        // item.document,
                                        item
                                      ),
                                  },
                                  {
                                    label: 'Share',
                                    onClick: () => handleClickShareTeamPopup(item, null, 'Application'),
                                  },
                                ]}
                                  isClientsPage={true}
                                />
                              )}
                            </div>
                          </React.Fragment>
                        )
                      : ''}
                    {selectedButton !== 'Application' &&
                      item?.uploadedFiles.map((fileUpload, index) => (
                        <React.Fragment key={fileUpload?.timeSaved || index}>
                          {(isSharedClient && sharedData.documentsShared && sharedData?.documentsShared.includes(fileUpload.uniqueFilename)) || !isSharedClient ? (
                            <React.Fragment>
                              <div className="balance-grid-cell balance-grid-title">
                                <input type="checkbox" className="balance-grid-checkbox" />
                                <div>
                                  <span className="balance-grid-clients-profile-file-name">{fileUpload?.originalFilename || 'Document'}</span>
                                </div>
                              </div>
                              {selectedButton !== 'Document' && <div className="balance-grid-cell "></div>}
                              <div className="balance-grid-cell balance-grid-clients-profile-type">
                                <span>Document</span>
                              </div>
                              <div className="balance-grid-cell balance-grid-clients-profile-date">
                                <span>{new Date(fileUpload?.timeSaved).toLocaleDateString()}</span>
                              </div>
                              {selectedButton !== 'Document' && <div className="balance-grid-cell balance-grid-clients-profile-updated"></div>}
                              <div className="balance-grid-cell">
                                <Button icon={DotIcon} iconPosition="after" className="balance-grid-dot-button" onClick={(e) => handleClickDot(fileUpload?.timeSaved, e)} />
                                {/* dropdown */}
                                {openDropdown[fileUpload?.timeSaved] && (
                                  <Dropdown
                                    item={openDropdown[fileUpload?.timeSaved]}
                                    openDropdown={openDropdown}
                                    dropdownRef={dropdownRef}
                                    dropdownButtons={[
                                      {
                                        label: 'View',
                                        onClick: () =>
                                          handleClickDocumentPopup(
                                            'Document',
                                            // item.document,
                                            item.pdfID,
                                            fileUpload
                                          ),
                                      },
                                      {
                                        label: 'Share',
                                        onClick: () => handleClickShareTeamPopup(item, fileUpload, 'Document'),
                                      },
                                      // { label: "Delete" },
                                    ]}
                                    isClientsPage={true}
                                  />
                                )}
                              </div>
                            </React.Fragment>
                          ) : (
                            ''
                          )}
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                ))}
              </div>
              {/* grid footer */}
              <div className="balance-grid-footer">
                <div className="balance-grid-footer-pages">Page 1 of 1</div>
                <div className="balance-grid-footer-button">
                  <Button buttonName="Previous" className="balance-grid-footer-previous-button" />
                  <Button buttonName="Next" className="balance-grid-footer-next-button" />
                </div>
              </div>
            </div>
            {isViewOpen && (
              <>
                {documentType === 'Document' && (
                  <Modal width="50rem" onClose={closeDocumentPopup} isDocumentModal={true} documentType={documentType}>
                    <DocumentPopup idNum={pdfIdNum} fileInfo={fileInfo} />
                  </Modal>
                )}
                {documentType === 'Application' && (
                  <Modal width="80rem" onClose={closeDocumentPopup} isDocumentModal={true} documentType={documentType}>
                    <ApplicationPopup formData={applicationSectionData} />
                  </Modal>
                )}
              </>
            )}
            {isShareTeamOpen && (
              <Modal width="32rem" onClose={closeShareTeamPopup}>
                <ShareTeam isDocumentModal={true} onClose={closeShareTeamPopup} isClientModal={true} clientProfileData={selectedClientShareData} documentType={documentType} />
              </Modal>
            )}
            {isChangeStatusOpen && (
              <Modal width="36rem" onClose={closeChangeStatus}>
                <ChangeStatusPopup onClose={closeChangeStatus} onSubmit={handleUpdateStatus} isClientsPage={true} />
              </Modal>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default ClientsProfile;
