import './DocumentUploadInput.css';

const DocumentUploadInputCompleted = ({fileData}) => {
    let data = '';
    let filename = '';
    if (fileData) {  // this is super hacky but JSON.parse doesn't like how mongoDB stored this data so we just have to use string manip
        data = fileData.slice(21);
        filename = data.slice(0, data.indexOf('\"'));
    }

    return (
        <div className='document-upload-container'>
            <span
                className='document-style-button'
            >{filename ? <div className='document-upload-input-text document-upload-input-filename'>{filename}</div> : <div className='document-upload-input-text document-upload-input-add'>None</div>}</span>
        </div>
    )
}

export default DocumentUploadInputCompleted;