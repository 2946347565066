import useModalStore from '../../store/useModalStore';
import Button from '../Button/Button';
import './Modal.css';
import XIcon from '../../assets/Icon/X_Icon_Dark.svg';

const Modal = ({ children, width, isDocumentModal, documentType, onClose, loader }) => {
  const { closeModal } = useModalStore();
  const setSelectedTemplate = useModalStore((state) => state.setSelectedTemplate);

  const handlePopupClose = () => {
    setSelectedTemplate('');
    closeModal();
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className="balance-modal-container">
      <div className={`${!isDocumentModal ? 'balance-popup-container' : 'balance-modal-document-container'}`} style={{ width }}>
        {/* document modal */}
        {isDocumentModal && (
          <div className="balance-modal-document-header">
            <div className="balance-modal-document-header-space"></div>
            <div className="balance-modal-document-title-container">
              <p className="balance-modal-document-title">{documentType === 'Document' ? 'Document' : 'Application'}</p>
            </div>
            <div className="balance-modal-document-close-button-container">
              {!loader && <Button icon={XIcon} alt="Icon" iconPosition="before" onClick={handlePopupClose} className="balance-modal-document-close-button" />}{' '}
            </div>
          </div>
        )}
        {/* not document modal */}
        {!isDocumentModal && (
          <div className="balance-popup-close-button-container">
            {!loader && <Button icon={XIcon} alt="Icon" iconPosition="before" onClick={handlePopupClose} className="balance-popup-close-button" />}{' '}
          </div>
        )}
        {children}
        <div className="balance-modal-button-visible">
          {isDocumentModal && !loader && <Button buttonName="Close" onClick={handlePopupClose} className="balance-document-popup-button balance-global-blue-button balance-global-movable-button" />}
        </div>
      </div>
    </div>
  );
};

export default Modal;
