import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import useUserStore from '../../store/useUserStore';
import useModalStore from '../../store/useModalStore';
import Button from '../../components/Button/Button';
import Search from '../../components/Search/Search';
import TemplatePopups from '../../pages/Hub/Templates/CreateTemplates/TemplatePopups';
import './PageTopLayout.css';
import ShareIcon from '../../assets/Icon/Share_Icon.svg';
import AddIcon from '../../assets/Icon/Add_Icon_White.svg';
// import FilterButton from '../../components/Button/FilterButton';
// import CalendarIcon from '../../assets/Icon/Calendar_Icon.svg';

/* following components are shared
- CreateTemplatesInitial
- CreateTemplates
- Clients
- Tasks
*/

const PageTopLayout = ({
  handleSearch,
  searchTerm,
  searchTermMyApplications,
  searchTermShared,
  searchTermAssigned,
  searchTermArchived,
  layout,
  isTasksPage,
  isClientsPage,
  isCreateTemplatesInitialPage,
  isCreateTemplatesPage,
  selectedStatusTab,
  setSelectedStatusTab,
  handleClickShare,
  activeStatusTab,
  setActiveStatusTab,
  selectedCategory,
  setSelectedCategory,
}) => {
  const setSelectedTemplate = useModalStore((state) => state.setSelectedTemplate);
  const { taskPermissions } = useUserStore();

  const navigate = useNavigate();

  // reset create template popup to CreatePopup
  useEffect(() => {
    setSelectedTemplate(null);
  }, [setSelectedTemplate]);

  useEffect(() => {
    if (isCreateTemplatesInitialPage) {
      const hash = window.location.hash.substring(1);
  
      if (hash === 'MyApplications') {
        setActiveStatusTab('My Applications');
        setSelectedStatusTab('My Applications');
      } else if (hash === 'Shared') {
        setActiveStatusTab('Shared');
        setSelectedStatusTab('Shared');
      } else {
        setActiveStatusTab('Templates');
        setSelectedStatusTab('Templates');
      }
    }
  
    if (isTasksPage && !window.location.hash) {
      window.location.hash = '#MyTasks';
    }
  }, [isCreateTemplatesInitialPage, isTasksPage, setActiveStatusTab, setSelectedStatusTab]);  

  // dropdown selection
  const options = [
    { value: 'All', label: 'All' },
    { value: 'Retail', label: 'Retail' },
    { value: 'Commercial', label: 'Commercial' },
  ];

  // search term in task page
  const getSearchTermTasks = () => {
    switch (selectedStatusTab) {
      case 'My Tasks':
        return searchTerm;
      case 'Assigned':
        return searchTermAssigned;
      case 'Archived':
        return searchTermArchived;
      default:
        return '';
    }
  };

  const searchTermTasks = getSearchTermTasks();
  // console.log('Search keyword:', searchTermTasks);

  return (
    <div className="balance-create-templates-layout-container">
      <div className="balance-create-templates-layout-inner-container">
        {/* left container */}
        <div className="balance-create-templates-layout-left-container">
          {layout.map((item) => (
            <div key={item.id} className="balance-create-templates-layout-left-container">
              {/* title */}
              <h1 className="balance-create-templates-layout-title balance-global-title">{item.title}</h1>
              <p className="balance-create-templates-layout-sub-title balance-clients-sub-title">{item.description}</p>
            </div>
          ))}
          {/* status tabs */}
          {isClientsPage && (
            <div className="balance-clients-status-tab-container">
              <div
                onClick={() => {
                  setActiveStatusTab('All');
                  setSelectedStatusTab('All');
                }}
                className={`item-line ${activeStatusTab === 'All' ? 'active-tab' : ''}`}
              >
                <a href="#All">All</a>
              </div>
              <div
                onClick={() => {
                  setActiveStatusTab('Approved');
                  setSelectedStatusTab('Approved');
                }}
                className={`item-line ${activeStatusTab === 'Approved' ? 'active-tab' : ''}`}
              >
                <a href="#Approved">Approved</a>
              </div>
              <div
                onClick={() => {
                  setActiveStatusTab('Under Review');
                  setSelectedStatusTab('Under Review');
                }}
                className={`item-line ${activeStatusTab === 'Under Review' ? 'active-tab' : ''}`}
              >
                <a href="#UnderReview">Under Review</a>
              </div>
              <div
                onClick={() => {
                  setActiveStatusTab('Declined');
                  setSelectedStatusTab('Declined');
                }}
                className={`item-line ${activeStatusTab === 'Declined' ? 'active-tab' : ''}`}
              >
                <a href="#Declined">Declined</a>
              </div>
            </div>
          )}
          {/* tasks page tabs */}
          {isTasksPage && (
            <div className="balance-clients-status-tab-container">
              <div
                onClick={() => {
                  setActiveStatusTab('My Tasks');
                  setSelectedStatusTab('My Tasks');
                }}
                className={`item-line ${activeStatusTab === 'My Tasks' ? 'active-tab' : ''}`}
              >
                <a href="#MyTasks">My Tasks</a>
              </div>
              <div
                onClick={() => {
                  setActiveStatusTab('Assigned');
                  setSelectedStatusTab('Assigned');
                }}
                className={`item-line ${activeStatusTab === 'Assigned' ? 'active-tab' : ''}`}
              >
                <a href="#Assigned">Assigned</a>
              </div>
              <div
                onClick={() => {
                  setActiveStatusTab('Archived');
                  setSelectedStatusTab('Archived');
                }}
                className={`item-line ${activeStatusTab === 'Archived' ? 'active-tab' : ''}`}
              >
                <a href="#Archived">Archived</a>
              </div>
            </div>
          )}
          {isCreateTemplatesInitialPage && (
            <div className="balance-clients-status-tab-container balance-create-templates-initial-status-bar">
              {/* templates */}
              <div
                onClick={() => {
                  setActiveStatusTab('Templates');
                  setSelectedStatusTab('Templates');
                }}
                className={`item-line ${activeStatusTab === 'Templates' ? 'active-tab' : ''}`}
              >
                <a href="#Templates">Templates</a>
              </div>
              {/* my applications tab */}
              <div
                onClick={() => {
                  setActiveStatusTab('My Applications');
                  setSelectedStatusTab('My Applications');
                }}
                className={`item-line ${activeStatusTab === 'My Applications' ? 'active-tab' : ''}`}
              >
                <a href="#MyApplications">My Applications</a>
              </div>
              {/* shared tab */}
              <div
                onClick={() => {
                  setActiveStatusTab('Shared');
                  setSelectedStatusTab('Shared');
                }}
                className={`item-line ${activeStatusTab === 'Shared' ? 'active-tab' : ''}`}
              >
                <a href="#Shared">Shared</a>
              </div>
            </div>
          )}
          {/* search bar */}
          {isCreateTemplatesInitialPage && activeStatusTab === 'Templates' && (
            // select dropdown
            <CreatableSelect value={selectedCategory} className="balance-templates-select-dropdown" options={options} onChange={(selectedOption) => setSelectedCategory(selectedOption)} />
          )}
          {isCreateTemplatesInitialPage && (activeStatusTab === 'My Applications' || activeStatusTab === 'Shared') && (
            // search bar
            <div className="balance-create-templates-layout-search-container">
              <Search
                placeholder={selectedStatusTab === 'My Applications' ? 'Search for templates' : 'Search for templates or coworker name'}
                searchTerm={selectedStatusTab === 'My Applications' ? searchTermMyApplications : searchTermShared}
                handleSearch={handleSearch}
              />
            </div>
          )}
          {/* {isCreateTemplatesInitialPage && activeStatusTab === 'Shared' && (
            // select dropdown
            <CreatableSelect placeholder="Select" className="balance-templates-select-dropdown" />
          )} */}
          {isCreateTemplatesPage && (
            <div className="balance-create-templates-layout-search-container">
              <Search placeholder="Search for templates" searchTerm={searchTerm} handleSearch={handleSearch} />
            </div>
          )}
          {isClientsPage && (
            <div className="balance-clients-search-container">
              <Search placeholder="Search by application name" searchTerm={searchTerm} handleSearch={handleSearch} />
            </div>
          )}
          {isTasksPage && (
            <div className="balance-clients-search-container">
              <Search placeholder="Search" searchTerm={searchTermTasks} handleSearch={handleSearch} />
            </div>
          )}
        </div>
        {/* right container */}
        <div className="balance-create-templates-layout-right-container">
          {/* top */}
          {!isClientsPage && !isTasksPage && !(isCreateTemplatesInitialPage && activeStatusTab === 'Templates') && (
            <div className="balance-create-templates-layout-top-button-container">
              <Button
                buttonName="Share"
                icon={ShareIcon}
                iconPosition="before"
                iconStyle="balance-share-icon"
                onClick={handleClickShare}
                className="balance-create-templates-layout-top-button same-style-button balance-global-movable-button"
              />
              {/* new button and new button logic */}
              <TemplatePopups />
            </div>
          )}
          {/* assign task button */}
          {isTasksPage && taskPermissions.canShare && (
            <div className="balance-create-templates-layout-top-button-container">
              <Button
                buttonName="Assign Task"
                icon={AddIcon}
                iconPosition="before"
                iconStyle="balance-share-icon"
                onClick={() => navigate('/Hub/Tasks/Assign-Task')}
                className="balance-create-templates-layout-assign-button same-style-button balance-global-movable-button"
              />
            </div>
          )}
          {/* bottom */}
          <div className={`${isClientsPage ? 'balance-clients-button-container' : 'balance-create-templates-layout-bottom-button-container'}`}>
            {/* {!isClientsPage && (
              <Button
                buttonName='Dec 6, 2023 - Dec 13, 2023'
                icon={CalendarIcon}
                iconPosition='before'
                iconStyle='balance-layout-calendar-icon'
                className='balance-create-templates-layout-bottom-button same-style-button'
              />
            )}
            {isClientsPage && (
              <Button
                buttonName='Last Month'
                icon={CalendarIcon}
                iconPosition='before'
                iconStyle='balance-layout-calendar-icon'
                className='balance-create-templates-layout-bottom-button same-style-button'
              />
            )}
            )} */}
            {/* <FilterButton /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTopLayout;
