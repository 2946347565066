import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import useUserStore from '../../../store/useUserStore';
import './Settings.css';

const Settings = () => {
  const [visible, setVisible] = useState(false);
  const { isAdmin } = useUserStore();

  const navigate = useNavigate();

  const handleClickAccountSettings = () => {
    setVisible((prevOpen) => !prevOpen);
  };

  return (
    <div className="balance-settings-container">
      {/* title */}
      <h1 className="balance-settings-title">Settings</h1>
      <p className="balance-settings-description">Manage settings in the Balance application.</p>
      {/* line */}
      <div className="balance-settings-line"></div>
      {/* user access permissions button */}
      <div className="balance-settings-links-container">
        <div className="balance-settings-links-top-container">
          <div className="balance-settings-button-container">
            <Button buttonName="User Profile" onClick={() => navigate('/Hub/Settings/User-Profile')} className="balance-settings-button" />
          </div>
          <div className="balance-settings-button-container">
            <Button buttonName="Notifications" onClick={() => navigate('/Hub/Settings/Notifications')} className="balance-settings-button" />
          </div>
          <div className="balance-settings-button-container">
            <Button buttonName="Account Settings" onClick={handleClickAccountSettings} className="balance-settings-button" />
          </div>
          <div className={`container-animation ${visible ? 'visible' : ''}`}>
            <div className="balance-settings-account-settings-sub-container">
              <div className="balance-settings-account-settings-sub-nav" onClick={() => navigate('/Hub/Settings/Account')}>
                Account Management
              </div>
              <div className="balance-settings-account-settings-sub-nav" onClick={() => navigate('/Hub/Settings/AccountDeactivation')}>
                Account Deactivation
              </div>
              <div className="balance-settings-account-settings-sub-nav" onClick={() => navigate('/Hub/Settings/PaymentMethods')}>
                Payment Methods
              </div>
              <div className="balance-settings-account-settings-sub-nav" onClick={() => navigate(isAdmin ? '/Hub/Settings/User-Permissions' : '/Hub/Settings/User-Permissions/User-Access')}>
                User Permissions
              </div>
              {isAdmin && (
                <div className="balance-settings-account-settings-sub-nav" onClick={() => navigate('/Hub/Settings/InviteUsers')}>
                  Invite Users
                </div>
              )}
            </div>
          </div>
          <div className="balance-settings-button-container">
            <Button buttonName="Integrations" onClick={() => navigate('/Hub/Settings/Integrations')} className="balance-settings-button" />
          </div>
          <div className="balance-settings-button-container">
            <Button buttonName="Support and Feedback" onClick={() => navigate('/Hub/Settings/Support')} className="balance-settings-button" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
