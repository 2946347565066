import { useState, useLayoutEffect, useRef } from 'react';
import useFormBuilderStore from '../../../../../store/useFormBuilderStore';
import InputField from '../../CreateTemplates/InputField';
import './Section.css';
import PoweredLogo from '../../../../../assets/Logo/Powered_By_Balance_Logo.svg';

const Section = ({ section, sectionIndex, showPoweredLogo }) => {
  const [rows, setRows] = useState();
  const { setSectionTitle, setSectionDesc, setSectionIndex, setQuestionIndex, selectedSectionIndex } = useFormBuilderStore();

  // ref
  // const containerRef = useRef(null);

  // const handleClickContainer = () => {
  //   containerRef.current.focus();
  //   // console.log(containerRef.current);
  // };

  // ref
  const sectionTitleRef = useRef([]);
  const sectionDescRef = useRef(null);

  const handleEditSectionTitle = (e) => {
    setSectionTitle(sectionIndex, e.target.value);
  };

  // enter key and save section
  const handleTitleKeyDownSave = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sectionTitleRef.current.blur();
    }
  };

  const handleEditSectionDesc = (e) => {
    setSectionDesc(sectionIndex, e.target.value);
    adjustTextareaHeight();
  };

  // enter key and save section
  const handleDescKeyDownSave = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sectionDescRef.current.blur();
    }
  };

  const handleSectionClick = () => {
    setSectionIndex(sectionIndex);
    setQuestionIndex(null);
  };

  const isPreviewActive = window.location.pathname === '/Hub/CreateTemplatesInitial/Preview';

  // flexiable height text area
  const adjustTextareaHeight = () => {
    const textarea = sectionDescRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
      setRows(Math.max(1, textarea.value.split('\n').length));
    }
  };
  useLayoutEffect(() => {
    adjustTextareaHeight();
  }, [section.sectionDesc]);

  return (
    <div
      // ref={containerRef}
      onClick={handleSectionClick}
      className={`balance-form-builder-create-page-container`}
    >
      <div
        className={`balance-form-builder-create-page-initial-add ${
          sectionIndex === selectedSectionIndex && `balance-form-builder-create-page-initial-add-selected-section`
        }`}
      >
        {/* display powered by balance logo */}
        {showPoweredLogo && (
          <div className="balance-form-builder-create-page-initial-powered-logo-container">
            <img src={PoweredLogo} alt="Icon" className="balance-form-builder-create-page-initial-powered-logo" />
          </div>
        )}
        {!isPreviewActive ? (
          <>
            {section?.sectionDesc.length > 50 ? (
              <h1 className="balance-form-builder-create-page-initial-add-title-long-length">
                <textarea
                  ref={(element) => (sectionTitleRef.current = element)}
                  type="text"
                  value={section?.sectionTitle || ''}
                  onChange={(e) => handleEditSectionTitle(e)}
                  onKeyDown={(e) => handleTitleKeyDownSave(e)}
                  placeholder="Add your Section Title here"
                  className={`balance-form-builder-create-page-initial-add-title-long-length`}
                />
              </h1>
            ) : (
              <h1 className="balance-form-builder-create-page-initial-add-title-short-length">
                <textarea
                  ref={(element) => (sectionTitleRef.current = element)}
                  type="text"
                  value={section?.sectionTitle || ''}
                  onChange={(e) => handleEditSectionTitle(e)}
                  onKeyDown={(e) => handleTitleKeyDownSave(e)}
                  placeholder="Add your Section Title here"
                  className={`balance-form-builder-create-page-initial-add-title-short-length`}
                />
              </h1>
            )}
            {section?.sectionDesc.length > 50 ? (
              <h3 className="balance-form-builder-create-page-initial-add-desc-long-length">
                <textarea
                  ref={(element) => (sectionDescRef.current = element)}
                  type="text"
                  value={section?.sectionDesc || ''}
                  onChange={(e) => handleEditSectionDesc(e)}
                  onKeyDown={(e) => handleDescKeyDownSave(e)}
                  placeholder="Add your description here..."
                  className={`balance-form-builder-create-page-initial-add-desc-long-length`}
                  rows={rows}
                />
              </h3>
            ) : (
              <h3 className="balance-form-builder-create-page-initial-add-desc-short-length">
                <textarea
                  ref={(element) => (sectionDescRef.current = element)}
                  type="text"
                  value={section?.sectionDesc || ''}
                  onChange={(e) => handleEditSectionDesc(e)}
                  onKeyDown={(e) => handleDescKeyDownSave(e)}
                  placeholder="Add your description here..."
                  className={`balance-form-builder-create-page-initial-add-desc-short-length`}
                />
              </h3>
            )}
          </>
        ) : (
          // * * * preview page
          <>
            {section?.sectionDesc.length > 50 ? (
              <textarea
                value={section?.sectionTitle || ''}
                className={`balance-form-builder-create-page-initial-add-title-long-length`}
                readOnly
              />
            ) : (
              <textarea
                value={section?.sectionTitle || ''}
                className={`balance-form-builder-create-page-initial-add-title-short-length`}
                readOnly
              />
            )}
            {section?.sectionDesc.length > 50 ? (
              <textarea
                rows={5}
                value={section?.sectionDesc || ''}
                className={`balance-form-builder-create-page-initial-add-desc-long-length`}
                readOnly
              />
            ) : (
              <input
                type="text"
                value={section?.sectionDesc || ''}
                className={`balance-form-builder-create-page-initial-add-desc-short-length`}
                readOnly
              />
            )}
          </>
        )}
        {/* build input form */}
        <InputField
          currentSectionInputs={section.sectionInputs}
          currentSectionIndex={sectionIndex}
          isPreviewActive={isPreviewActive}
        />
      </div>
    </div>
  );
};

export default Section;
