import React from 'react';
import { nanoid } from 'nanoid';
import { Icon } from '@iconify/react';
import Button from '../../../../components/Button/Button';
import AppImage from '../../../../assets/Image/TaskManager/App.png';
import './TaskManager.css';

const TaskManager = () => {
  const data1 = [
    {
      id: nanoid(),
      sub_title: 'Simplify Task Assignment and Delegation',
      desc: 'Effortlessly assign tasks to yourself or your team, set deadlines, and prioritize work to ensure smooth and efficient workflow management. The Task Workflow Manager enables you to:',
      feature_title_1: 'Assign tasks to the right person',
      feature_title_2: 'Set clear deadlines for each task',
      feature_title_3: 'Prioritize tasks to keep your team focused on what matters most',
    },
    {
      id: nanoid(),
      sub_title: 'Track Progress with Real-Time Status Updates',
      desc: 'Stay on top of every task with real-time tracking and status updates. Monitor the progress of assigned tasks, update statuses (e.g., pending, in progress, completed), and keep an eye on all task-related activities. With the Task Workflow Manager, you can:',
      feature_title_1: 'View the current status of every task',
      feature_title_2: 'Receive real-time updates on task progress',
      feature_title_3: 'Maintain a clear overview of all ongoing activities',
    },
    {
      id: nanoid(),
      sub_title: 'Never Miss a Deadline with Automated Notifications',
      desc: 'Ensure timely completion of tasks with automated notifications and reminders. The system sends alerts about upcoming deadlines and task updates, keeping everyone informed and on track. Key features include:',
      feature_title_1: 'Automated notifications for approaching deadlines',
      feature_title_2: 'Reminders for pending tasks',
      feature_title_3: 'Updates on task progress and status changes',
    },
  ];

  return (
    <div className="balance-features-task-manager-container">
      {/* section 1 */}
      <div className="balance-features-task-manager-section-1">
        <div className="balance-features-task-manager-section-1-inner-container balance-features-task-manager-section-1-left-container">
          <h1 className="balance-features-task-manager-title">Task Workflow Manager</h1>
          <h3 className="balance-features-task-manager-sub-title">Enhance Efficiency and Collaboration</h3>
          <p className="balance-features-task-manager-desc">
            The Task Workflow Manager is your ultimate tool for improving efficiency and collaboration in account opening and
            onboarding processes. Empower your team with streamlined task management and ensure every task is completed on time,
            every time.
          </p>
          {/* <Button buttonName="Quick Demo" className="balance-features-task-manager-button balance-global-movable-button" /> */}
        </div>
        <div className="balance-features-task-manager-section-1-inner-container balance-features-task-manager-right-container"></div>
      </div>
      {/* section 2 */}
      <div className="balance-features-task-manager-section-2">
        <div className="balance-features-task-manager-section-2-left-container">
          <div className="balance-features-task-manager-section-2-title-container">
            <h1 className="balance-features-task-manager-section-2-title">Features</h1>
          </div>
          <div>
            {data1.map((feature, index) => (
              <div className="balance-features-task-manager-section-2-inner-container">
                <h1 className="balance-features-task-manager-section-2-sub-title">{feature.sub_title}</h1>
                <p className="balance-features-task-manager-section-2-desc">{feature.desc}</p>
                <div className="balance-features-task-manager-box-container">
                  <div className="feature-box">
                    <div className="feature-box-title-container">
                      <Icon icon="icon-park-outline:check-one" className="feature-box-icon" />
                      <h3>{feature.feature_title_1}</h3>
                    </div>
                    <p className="feature-box-desc">{feature.feature_desc_1}</p>
                  </div>
                  <div className="feature-box">
                    <div className="feature-box-title-container">
                      <Icon icon="icon-park-outline:check-one" className="feature-box-icon" />
                      <h3>{feature.feature_title_2}</h3>
                    </div>
                    <p className="feature-box-desc">{feature.feature_desc_2}</p>
                  </div>
                  <div className="feature-box">
                    <div className="feature-box-title-container">
                      <Icon icon="icon-park-outline:check-one" className="feature-box-icon" />
                      <h3>{feature.feature_title_3}</h3>
                    </div>
                    <p className="feature-box-desc">{feature.feature_desc_3}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="balance-features-task-manager-section-2-inner-container balance-features-task-manager-section-2-right-container">
          <img src={AppImage} alt="Image" className="image" />
        </div>
      </div>
      {/* section 3 */}
      <div className="balance-features-task-manager-section-3">
        <div className="balance-features-task-manager-section-3-left-container"></div>
        <div className="balance-features-task-manager-section-3-right-container">
          <h1 className="balance-features-task-manager-section-3-title">Explore</h1>
          <p className="balance-features-task-manager-section-3-desc">
            Explore how the Task Workflow Manager can transform your workflow and elevate your account opening and onboarding
            processes. Start streamlining your tasks today!
          </p>
          <div className="balance-features-task-manager-section-2-button-container">
            {/* <Button
              buttonName="Get started"
              className="balance-features-task-manager-section-3-button balance-global-movable-button"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskManager;
