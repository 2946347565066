import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import ServerClient from '../../../services/api-client.js';
import useUserStore from '../../../store/useUserStore.js';
import Button from '../../../components/Button/Button.js';
import './UserProfile.css';
import './Settings.css';

const UserProfile = () => {
  const { userName, userEmail, userId } = useUserStore();

  const navigate = useNavigate();

  return (
    <div className="user-profile-settings-container balance-settings-container">
      <div className="balance-settings-back-button-container" onClick={() => navigate('/Hub/Settings')}>
        <Icon icon="ic:sharp-arrow-back-ios" className="balance-settings-back-button" />
      </div>
      <div className="user-profile-settings-title-container">
        <h1 className="balance-settings-title">User Profile</h1>
        <p className="balance-settings-description">Access information related to your Balance profile.</p>
        {/* line */}
        <div className="balance-settings-line"></div>
      </div>
      <div className="user-profile-settings-info-container">
        <p className="user-profile-settings-text">Name: {userName}</p>
        <p className="user-profile-settings-text">Email: {userEmail}</p>
        <ChangePassword />
      </div>
      <div className="user-profile-tfa-container">
        <TFA userId={userId} />
      </div>
    </div>
  );
};

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessfulChange, setIsSuccessfulChange] = useState(null);

  const { userId } = useUserStore();

  const handleChangePassword = async () => {
    setIsSuccessfulChange(null);
    try {
      if (!oldPassword || oldPassword.length < 6 || !newPassword || newPassword.length < 6) {
        setIsSuccessfulChange(false);
        return;
      }
      setIsLoading(true);
      const serverClient = new ServerClient('/api/changePassword');
      const req = { oldPassword, newPassword, userId };
      const res = await serverClient.post(req);
      if (res?.status === 200) {
        setIsSuccessfulChange(true);
      } else {
        setIsSuccessfulChange(false);
      }
    } catch (err) {
      console.log('err is: ', err);
      setIsSuccessfulChange(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="user-profile-settings-change-password-container">
      <h3 className="user-profile-subheading">Change your password</h3>
      <div className="user-profile-settings-input-container">
        <label className="user-profile-settings-password-label" htmlFor="user-profile-current-pw">
          Current Password:{' '}
        </label>
        <input
          type="password"
          id="user-profile-current-pw"
          className={`balance-global-input ${isSuccessfulChange === false ? 'balance-global-input-error' : ''}`}
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />
      </div>
      <div className="user-profile-settings-input-container">
        <label className="user-profile-settings-password-label" htmlFor="user-profile-new-pw">
          New Password:{' '}
        </label>
        <input
          type="password"
          id="user-profile-new-pw"
          className={`balance-global-input ${isSuccessfulChange === false ? 'balance-global-input-error' : ''}`}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </div>
      {isSuccessfulChange === false && <p className="user-profile-settings-unsuccessful-change">Invalid credentials.</p>}
      {!isLoading ? (
        <Button
          onClick={handleChangePassword}
          buttonName={`${isSuccessfulChange ? 'Successful change!' : 'Change Password'}`}
          className={`balance-global-blue-button ${isSuccessfulChange ? 'user-profile-successful-pw-change-button' : undefined}`}
        />
      ) : (
        <Button buttonName="Loading..." className={`balance-global-blue-button balance-global-disabled-button`} disabled={true} />
      )}
    </div>
  );
};

const TFA = (userId) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { userTFAFlag, setUserTFAFlag } = useUserStore();

  useEffect(() => {
    setIsChecked(userTFAFlag);
  }, []);

  const toggleTFA = async () => {
    setIsLoading(true);
    try {
      await new Promise((r) => setTimeout(r, 1500));
      const serverClient = new ServerClient('/api/toggleTFA');
      const res = await serverClient.post(userId);

      setIsChecked(!isChecked);
      setUserTFAFlag(!isChecked);
    } catch (err) {
      console.log('err is: ', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="user-profile-tfa">
      <h3 className="user-profile-subheading">Two-Factor Authentication</h3>
      <p className="user-profile-subheading-text">Turning on TFA will cause your email to be sent a code every time your email is used to log in.</p>
      <div className="user-profile-checkbox-container">
        <input type="checkbox" id="tfa-checkbox" className="balance-permissions-checkbox" checked={isChecked} disabled={isLoading} onChange={() => toggleTFA()} />
        <label htmlFor="tfa-checkbox" className="user-profile-checkbox-label">
          {!isLoading ? 'Two-Factor Authentication' : 'Loading...'}
        </label>
      </div>
      {/* Must enter your password to toggle TFA on and off.
                Have a state variable to denote if they've verified their password already.
                Allow them to toggle on and off. Have a flag in supabase you toggle on and off for if TFA is active for their account.
                If it is active, then (as of now), send them an email with a 4 digit code to verify. Create a new input in the login section that asks for this code. */}
    </div>
  );
};

export default UserProfile;
