import React from 'react';
import { nanoid } from 'nanoid';
import { Icon } from '@iconify/react';
import Button from '../../../../components/Button/Button';
import './ClientDataHub.css';
import AppImage from '../../../../assets/Image/ClientDataHub/App.png';

const ClientDataHub = () => {
  const data1 = [
    {
      id: nanoid(),
      sub_title: 'Centralized Cloud Database',
      feature_title_1: 'Unified Data Management',
      feature_desc_1:
        'The Client Data Hub serves as the core of your commercial banking onboarding operations. It securely stores all client data in a structured format, ensuring easy access and standardized information management across your organization.',
      icon_1: 'majesticons:data-line',
    },
    {
      id: nanoid(),
      sub_title: 'Interdepartmental Data Sharing',
      feature_title_1: 'Break Down Silos',
      feature_desc_1:
        'Enable seamless data sharing among different banking departments involved in the onboarding process. Teams such as risk management, compliance, legal and client relationship management can access and contribute to a shared pool of client information, enhancing collaboration and efficiency.',
      icon_1: 'tabler:share',
    },
    {
      id: nanoid(),
      sub_title: 'Integrations',
      feature_title_1: 'Seamless Software Integration',
      feature_desc_1:
        'The Client Data Hub integrates effortlessly with leading technology vendor software used in commercial banking onboarding. This enhanced interoperability ensures that data flows seamlessly between our central cloud database and various specialized software solutions.',
      icon_1: 'carbon:data-share',
    },
    {
      id: nanoid(),
      sub_title: 'Real-time Collaboration',
      feature_title_1: 'Enhanced Team Collaboration',
      feature_desc_1:
        'Foster real-time collaboration with built-in tools for communication and document sharing. Team members can collaborate on client files and share important updates within the platform, ensuring a streamlined and efficient onboarding process.',
      icon_1: 'zondicons:time',
    },
  ];

  return (
    <div className="balance-features-client-data-hub-container">
      {/* section 1 */}
      <div className="balance-features-client-data-hub-section-1">
        <div className="balance-features-client-data-hub-section-1-inner-container balance-features-client-data-hub-left-container">
          <h1 className="balance-features-client-data-hub-title">Client Data Hub</h1>
          <p className="balance-features-client-data-hub-desc">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Officiis tempora obcaecati quia eum, a ullam quas totam
            temporibus tempore nihil suscipit deserunt, quidem eos dignissimos enim! Sapiente ipsa id asperiores.
          </p>
          {/* <Button buttonName="Quick Demo" className="balance-features-client-data-hub-button balance-global-movable-button" /> */}
        </div>
        <div className="balance-features-client-data-hub-section-1-inner-container balance-features-client-data-hub-right-container"></div>
      </div>
      {/* section 2 */}
      <div className="balance-features-client-data-hub-section-2">
        <div className="balance-features-client-data-hub-left-container">
          <div className="balance-features-client-data-hub-section-2-title-container">
            <h1 className="balance-features-client-data-hub-section-2-title">Features</h1>
          </div>
          <div>
            {data1.map((feature, index) => (
              <div className="balance-features-client-data-hub-section-2-inner-container">
                <h1 className="balance-features-client-data-hub-section-2-sub-title">{feature.sub_title}</h1>
                <p className="balance-features-client-data-hub-section-2-desc">{feature.desc}</p>
                <div className="balance-features-client-data-hub-box-container">
                  <div className="feature-box">
                    <div className="feature-box-title-container">
                      <Icon icon={feature.icon_1} className="feature-box-icon" />
                      <h3>{feature.feature_title_1}</h3>
                    </div>
                    <p className="feature-box-desc">{feature.feature_desc_1}</p>
                  </div>
                  <div className="feature-box">
                    <div className="feature-box-title-container">
                      <Icon icon={feature.icon_2} className="balance-client-data-hub-feature-box-icon" />
                      <h3>{feature.feature_title_2}</h3>
                    </div>
                    <p className="feature-box-desc">{feature.feature_desc_2}</p>
                  </div>
                  <div className="feature-box">
                    <div className="feature-box-title-container">
                      <Icon icon={feature.icon_3} className="feature-box-icon" />
                      <h3>{feature.feature_title_3}</h3>
                    </div>
                    <p className="feature-box-desc">{feature.feature_desc_3}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="balance-features-client-data-hub-section-2-inner-container balance-features-client-data-hub-section-2-right-container">
          <img src={AppImage} alt="Image" className="image" />
        </div>
      </div>
      {/* section 3 */}
      <div className="balance-features-client-data-hub-section-3">
        <div className="balance-features-client-data-hub-section-3-left-container"></div>
        <div className="balance-features-client-data-hub-section-3-right-container">
          <h1 className="balance-features-client-data-hub-section-3-title">Experience</h1>
          <p className="balance-features-client-data-hub-section-3-desc">
            Experience the power of a centralized client data hub, designed to enhance collaboration, productivity, and efficiency
            in financial services account opening and onboarding processes.
          </p>
          <div className="balance-features-client-data-hub-section-2-button-container">
            {/* <Button
              buttonName="Get started"
              className="balance-features-client-data-hub-section-3-button balance-global-movable-button"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientDataHub;
