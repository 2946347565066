import useFormBuilderStore from "../../../../store/useFormBuilderStore";
// import BlankInputObjectTypes from './BlankInputObjects';
import "./InputField.css";
import { useRef } from "react";
import Select from "react-select";

const InputField = ({ type, currentSectionInputs, currentSectionIndex, isPreviewActive }) => {
  // store
  const { setQuestionTitle, setQuestionIndex } = useFormBuilderStore();

  const questionTitleRef = useRef([]);

  const handleEditQuestionTitle = (e, sectionIndex, questionIndex) => {
    setQuestionTitle(sectionIndex, questionIndex, e.target.value);
  };

  // const handleQuestionClick = (index) => {
  //   setQuestionIndex(index);
  // }

  const handleQuestionTitleKeyDown = (e, questionIndex) => {
    if (e.key === "Enter") {
      e.preventDefault();
      questionTitleRef.current[questionIndex].blur();
      // sections[index].sectionTitle = e.target.value;
      // console.log("target value is: ", e.target.value);
    }
  };

  return (
    <>
      {/* original preview page in form builder */}
      {!isPreviewActive
        ? currentSectionInputs.map((input, index) => {
            if (input.inputType === "shortText") {
              return (
                <div key={index} className="balance-form-builder-input-field-add-input-container">
                  <label htmlFor={type} x className="balance-form-builder-input-label-container">
                    {/* <p className='balance-form-builder-input-label-text'>{input.inputTitle || 'Question Title here...'}</p> */}
                    <div className="balance-form-builder-input-title-container">
                      <span>{input?.inputIsRequired && "* "}</span>
                      <input
                        id={`${type}-${index} title`}
                        ref={(element) => (questionTitleRef.current[index] = element)}
                        type="text"
                        value={`${input?.inputTitle || ""}`}
                        placeholder="Question Title Here..."
                        onChange={(e) => handleEditQuestionTitle(e, currentSectionIndex, index)}
                        onKeyDown={(e) => handleQuestionTitleKeyDown(e, index)}
                        className="balance-form-builder-input-label-question-title-input"
                      />
                    </div>
                    <input
                      id={`${type}-${index}`}
                      type="text"
                      value={input?.inputPlaceholder}
                      // onChange={(e) => handleEditQuestionTitle(e, currentSectionIndex, index)}
                      className="balance-form-builder-input-field-add-input short-text-width"
                    />
                  </label>
                </div>
              );
            } else if (input.inputType === "longText") {
              return (
                <div key={index} className="balance-form-builder-input-field-add-input-container">
                  <label htmlFor={type} className="balance-form-builder-input-label-container">
                    <div className="balance-form-builder-input-title-container">
                      <span>{input?.inputIsRequired && "* "}</span>
                      <input
                        id={`${type}-${index} title`}
                        ref={(element) => (questionTitleRef.current[index] = element)}
                        type="text"
                        value={input.inputTitle || ""}
                        placeholder="Question Title Here..."
                        onChange={(e) => handleEditQuestionTitle(e, currentSectionIndex, index)}
                        onKeyDown={(e) => handleQuestionTitleKeyDown(e, index)}
                        className="balance-form-builder-input-label-question-title-input"
                      />
                    </div>
                    <input
                      id={`${type}-${index}`}
                      type="text"
                      value={input.inputPlaceholder}
                      // onChange={(e) => handleEditQuestionTitle(e, currentSectionIndex, index)}
                      className="balance-form-builder-input-field-add-input long-text-width"
                    />
                  </label>
                </div>
              );
            } else if (input.inputType === "shortDropD") {
              return (
                <div key={index} className="balance-form-builder-input-field-add-input-container">
                  <label htmlFor={type} className="balance-form-builder-input-label-container">
                    <div className="balance-form-builder-input-title-container">
                      <span>{input?.inputIsRequired && "* "}</span>
                      <input
                        id={`${type}-${index} title`}
                        ref={(element) => (questionTitleRef.current[index] = element)}
                        type="text"
                        value={input.inputTitle || ""}
                        placeholder="Question Title Here..."
                        onChange={(e) => handleEditQuestionTitle(e, currentSectionIndex, index)}
                        onKeyDown={(e) => handleQuestionTitleKeyDown(e, index)}
                        className="balance-form-builder-input-label-question-title-input"
                      />
                    </div>
                  </label>

                  <Select
                    id={`${type}-${index}`}
                    options={input.inputChoices}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    placeholder={input.inputPlaceholder || "--"}
                    className="balance-form-builder-input-dropdown-short"
                  />
                </div>
              );
            } else if (input.inputType === "longDropD") {
              return (
                <div key={index} className="balance-form-builder-input-field-add-input-container">
                  <label htmlFor={type} className="balance-form-builder-input-label-container">
                    <div className="balance-form-builder-input-title-container">
                      <span>{input?.inputIsRequired && "* "}</span>
                      <input
                        id={`${type}-${index} title`}
                        ref={(element) => (questionTitleRef.current[index] = element)}
                        type="text"
                        value={input.inputTitle || ""}
                        placeholder="Question Title Here..."
                        onChange={(e) => handleEditQuestionTitle(e, currentSectionIndex, index)}
                        onKeyDown={(e) => handleQuestionTitleKeyDown(e, index)}
                        className="balance-form-builder-input-label-question-title-input"
                      />
                    </div>
                  </label>
                  <Select
                    id={`${type}-${index}`}
                    options={input.inputChoices}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    placeholder={input.inputPlaceholder || "--"}
                    className="balance-form-builder-input-dropdown-long"
                  />
                </div>
              );
            } else if (input.inputType === "documents") {
              return (
                <div className="balance-form-builder-input-field-add-input-container">
                  <label htmlFor={type} className="balance-form-builder-input-label-container">
                    <div className="balance-form-builder-input-title-container">
                      <span>{input?.inputIsRequired && "* "}</span>
                      <input
                        id={`${type}-${index} title`}
                        ref={(element) => (questionTitleRef.current[index] = element)}
                        type="text"
                        value={input.inputTitle || ""}
                        placeholder="Question Title Here..."
                        onChange={(e) => handleEditQuestionTitle(e, currentSectionIndex, index)}
                        onKeyDown={(e) => handleQuestionTitleKeyDown(e, index)}
                        className="balance-form-builder-input-label-question-title-input"
                      />
                    </div>
                  </label>
                  <div className="balance-form-input-field-add-document-container">
                    <p className="balance-form-input-field-add-document-text">Add+</p>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })
        : // * * * read only preview page
          currentSectionInputs.map((input, index) => {
            if (input.inputType === "shortText") {
              return (
                <div key={index} className="balance-form-builder-input-field-add-input-container">
                  <label htmlFor={type} x className="balance-form-builder-input-label-container">
                    {/* <p className='balance-form-builder-input-label-text'>{input.inputTitle || 'Question Title here...'}</p> */}
                    <div className="balance-form-builder-input-title-container">
                      <span>{input?.inputIsRequired && "* "}</span>
                      <input
                        // id={`${type}-${index} title`}
                        // ref={(element) =>
                        //   (questionTitleRef.current[index] = element)
                        // }
                        // type='text'
                        value={`${input?.inputTitle || ""}`}
                        // placeholder='Question Title Here...'
                        // onChange={(e) =>
                        //   handleEditQuestionTitle(e, currentSectionIndex, index)
                        // }
                        // onKeyDown={(e) => handleQuestionTitleKeyDown(e, index)}
                        className="balance-form-builder-input-label-question-title-input"
                        readOnly
                      />
                    </div>
                    <input
                      // id={`${type}-${index}`}
                      // type='text'
                      value={input?.inputPlaceholder}
                      // onChange={(e) => handleEditQuestionTitle(e, currentSectionIndex, index)}
                      className="balance-form-builder-input-field-add-input short-text-width"
                      readOnly
                    />
                  </label>
                </div>
              );
            } else if (input.inputType === "longText") {
              return (
                <div key={index} className="balance-form-builder-input-field-add-input-container">
                  <label htmlFor={type} className="balance-form-builder-input-label-container">
                    <div className="balance-form-builder-input-title-container">
                      <span>{input?.inputIsRequired && "* "}</span>
                      {/* input label */}
                      <input
                        // id={`${type}-${index} title`}
                        // ref={(element) =>
                        //   (questionTitleRef.current[index] = element)
                        // }
                        // type='text'
                        value={input.inputTitle || ""}
                        // placeholder='Question Title Here...'
                        // onChange={(e) =>
                        //   handleEditQuestionTitle(e, currentSectionIndex, index)
                        // }
                        // onKeyDown={(e) => handleQuestionTitleKeyDown(e, index)}
                        className="balance-form-builder-input-label-question-title-input"
                        readOnly
                      />
                    </div>
                    {/* value user input */}
                    <input
                      // id={`${type}-${index}`}
                      // type='text'
                      value={input.inputPlaceholder}
                      // value={input.inputUserAnswer || ''}
                      // onChange={(e) => handleEditQuestionTitle(e, currentSectionIndex, index)}
                      className="balance-form-builder-input-field-add-input long-text-width"
                      readOnly
                    />
                  </label>
                </div>
              );
            } else if (input.inputType === "shortDropD") {
              return (
                <div key={index} className="balance-form-builder-input-field-add-input-container">
                  <label htmlFor={type} className="balance-form-builder-input-label-container">
                    <div className="balance-form-builder-input-title-container">
                      <span>{input?.inputIsRequired && "* "}</span>
                      <input
                        // id={`${type}-${index} title`}
                        // ref={(element) =>
                        //   (questionTitleRef.current[index] = element)
                        // }
                        // type='text'
                        value={input.inputTitle || ""}
                        // placeholder='Question Title Here...'
                        // onChange={(e) =>
                        //   handleEditQuestionTitle(e, currentSectionIndex, index)
                        // }
                        // onKeyDown={(e) => handleQuestionTitleKeyDown(e, index)}
                        className="balance-form-builder-input-label-question-title-input"
                        readOnly
                      />
                    </div>
                  </label>
                  <Select
                    id={`${type}-${index}`}
                    options={input.inputChoices}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    placeholder={input.inputPlaceholder || "--"}
                    className="balance-form-builder-input-dropdown-short"
                  />
                </div>
              );
            } else if (input.inputType === "longDropD") {
              return (
                <div key={index} className="balance-form-builder-input-field-add-input-container">
                  <label htmlFor={type} className="balance-form-builder-input-label-container">
                    <div className="balance-form-builder-input-title-container">
                      <span>{input?.inputIsRequired && "* "}</span>
                      <input
                        // id={`${type}-${index} title`}
                        // ref={(element) =>
                        //   (questionTitleRef.current[index] = element)
                        // }
                        // type='text'
                        value={input.inputTitle || ""}
                        // placeholder='Question Title Here...'
                        // onChange={(e) =>
                        //   handleEditQuestionTitle(e, currentSectionIndex, index)
                        // }
                        // onKeyDown={(e) => handleQuestionTitleKeyDown(e, index)}
                        className="balance-form-builder-input-label-question-title-input"
                        readOnly
                      />
                    </div>
                  </label>
                  <Select
                    id={`${type}-${index}`}
                    options={input.inputChoices}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    placeholder={input.inputPlaceholder || "--"}
                    className="balance-form-builder-input-dropdown-long"
                  />
                </div>
              );
            } else if (input.inputType === "documents") {
              return (
                <div className="balance-form-builder-input-field-add-input-container">
                  <label htmlFor={type} className="balance-form-builder-input-label-container">
                    <div className="balance-form-builder-input-title-container">
                      <span>{input?.inputIsRequired && "* "}</span>
                      <input
                        value={input.inputTitle || ""}
                        // placeholder='Question Title Here...'
                        // onChange={(e) =>
                        //   handleEditQuestionTitle(e, currentSectionIndex, index)
                        // }
                        // onKeyDown={(e) => handleQuestionTitleKeyDown(e, index)}
                        className="balance-form-builder-input-label-question-title-input"
                        readOnly
                      />
                    </div>
                  </label>
                  <div className="balance-form-input-field-add-document-container">
                    <p className="balance-form-input-field-add-document-text">Add+</p>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
    </>
  );
};

export default InputField;
