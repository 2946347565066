import { useState, useRef } from 'react';

const useDropdown = () => {
  const [openDropdown, setOpenDropdown] = useState({});

  // ref
  const dropdownRef = useRef(null);

  const handleClickDot = (uniqueId, e) => {
    e.stopPropagation();
    console.log('uniqueId', uniqueId);
    const button = e.currentTarget;
    // console.log(button);
    if (button) {
      const updateDropdownPosition = () => {
        const buttonRect = button.getBoundingClientRect();
        setOpenDropdown((prev) => ({
          ...prev,
          [uniqueId]: {
            [uniqueId]: !prev[uniqueId]?.[uniqueId],
            style: {
              position: 'absolute',
              top: `${buttonRect.bottom + window.scrollY}px`,
              right: `calc(100% - ${buttonRect.right}px)`,
              zIndex: '100',
              display: prev[uniqueId]?.[uniqueId] ? 'none' : 'flex',
            },
          },
        }));
      };
      // to update dropdown position, will follow when screen is resized
      updateDropdownPosition();
      window.addEventListener('resize', updateDropdownPosition);

      return () => {
        window.removeEventListener('resize', updateDropdownPosition);
      };
    }
  };

  // close dropdown
  const closeDropdown = (uniqueId) => {
    // console.log('closeDropdown uniqueId', uniqueId);
    setOpenDropdown(() => ({
      [uniqueId]: false,
    }));
  };

  // dropdown open, close from the outside
  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setOpenDropdown(false);
    }
  };

  return { openDropdown, setOpenDropdown, handleClickDot, closeDropdown, handleClickOutside, dropdownRef };
};

export default useDropdown;
