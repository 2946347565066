import React from 'react';

const Button = ({ onClick, className, buttonName, icon, iconPosition, iconStyle, disabled }) => {
  return (
    <button onClick={onClick} className={className} disabled={disabled}>
      {iconPosition === 'before' && icon && <img src={icon} alt='Icon' className={iconStyle} />}
      {buttonName}
      {iconPosition === 'after' && icon && <img src={icon} alt='Icon' className={iconStyle} />}
    </button>
  );
};

export default Button;
