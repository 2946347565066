import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import useUserStore from '../../../store/useUserStore';
import { useDomainCheck, useSendSignupEmails } from '../../../hooks/useEmailHooks';
import { useCheckUserAndDeactivated } from '../../../hooks/useUserHooks';
import Button from '../../../components/Button/Button';
import Modal from '../../../components/Popup/Modal';
import SentPopup from '../../../components/Popup/SentPopup';
import SendingLoader from '../../../components/Loading/SendingLoader';
import SingleButtonPopup from '../../../components/Popup/SingleButtonPopup';
import './InviteUsers.css';
import './Settings.css';
import XIcon from '../../../assets/Icon/X_Icon_Dark.svg';

const InviteUsers = () => {
  const [emails, setEmails] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [invalidEmail, setInvalidEmail] = useState('');
  const [isSendPopuOpen, setSendPopupOpen] = useState(false);
  const [isSendLoaderOpen, setSendLoaderOpen] = useState(false);
  const [isSent, setSent] = useState(false);
  const [status, setStatus] = useState(''); // sending success or failed
  const { mutate: domainCheckMutate } = useDomainCheck();
  const { mutate: sendSignupEmailsMutate } = useSendSignupEmails();
  const { mutate: checkUserAndDeactivatedMutate } = useCheckUserAndDeactivated();
  const { userEmail } = useUserStore();

  const navigate = useNavigate();

  // console.log('inputValue', inputValue);
  // console.log('emails', emails);
  // console.log('userEmail', userEmail);

  // enter key, comma key, space key
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ',' || e.key === ' ') {
      e.preventDefault();
      const email = e.target.value.trim();
      if (email && !emails.includes(email)) {
        setEmails([...emails, email]);
        setInputValue('');
      }
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setInvalidEmail('');
  };

  const removeEmail = (remove) => {
    setEmails(emails.filter((email) => email !== remove));
  };

  // send invite
  const onSendInvite = () => {
    if (emails.length === 0) {
      setInvalidEmail('Email is required.');
      return;
    }

    if (emails.length > 10) {
      setInvalidEmail('Maximum 10 users.');
    }

    domainCheckMutate(
      { userEmail, emails },
      {
        onSuccess: (data) => {
          if (data.error) {
            // console.log('Domain does not match.');
            setInvalidEmail('Domain does not match.');
          } else {
            // console.log('Domains OK');
            checkUserAndDeactivatedMutate(
              { emails },
              {
                onSuccess: (data) => {
                  const invalidEmails = data.filter((user) => user.exists || user.deactivated).map((user) => user.email);
                  if (invalidEmails.length > 0) {
                    setInvalidEmail('Already in use or deactivated.');
                  } else {
                    openPopup();
                  }
                },
                onError: (error) => {
                  console.log('Error checking exist & deactivated users:', error);
                },
              }
            );
          }
        },
        onError: (error) => {
          setInvalidEmail('Domain does not match.');
          console.log('Domain does not match.', error);
        },
      }
    );
  };

  // confirm send invite
  const onConfirmSendInvite = async () => {
    const imagePath = '/Logo.png';

    try {
      const response = await fetch(imagePath);
      if (!response.ok) {
        throw new Error('Failed to fetch image.');
      }

      const blob = await response.blob();
      // console.log('Blob:', blob);

      if (blob.type.startsWith('text')) {
        throw new Error('Fetched file is not an image.');
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const imageData = reader.result;
        // console.log('Base64:', imageData);

        setSendLoaderOpen(true);
        closePopup();

        // to trigger error popup test use
        // const invalidEmails = [...emails, 'invalid-email'];

        sendSignupEmailsMutate(
          { emails, image: imageData },
          // { emails: invalidEmails, image: imageData }, // to trigger error popup test use
          {
            onSuccess: () => {
              console.log('Invitation sent successfully.');
              setSendLoaderOpen(false);
              setSent(true);
              setEmails([]);
              setStatus('success');
            },
            onError: (error) => {
              console.log('Failed sending invitation.', error);
              setSendLoaderOpen(false);
              setSent(true);
              setStatus('error');
            },
          }
        );
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.log('Failed image:', error);
    }
  };

  const openPopup = () => {
    setSendPopupOpen(true);
  };
  const closePopup = () => {
    setSendPopupOpen(false);
  };
  const closeSent = () => {
    setSent(false);
  };

  return (
    <div className="balance-settings-container balance-invite-users-container">
      <div className="balance-settings-back-button-container" onClick={() => navigate('/Hub/Settings')}>
        <Icon icon="ic:sharp-arrow-back-ios" className="balance-settings-back-button" />
      </div>
      <div>
        <h1 className="balance-settings-title">Invite Users</h1>
        <p className="balance-settings-description">Invite and manage team members with ease.</p>
        <div className="balance-settings-line"></div>
      </div>
      <div className="balance-invite-users-contents-container">
        <div className="balance-invite-users-contents-inner-container">
          <h3 className="balance-invite-users-contents-title">Invite your team members</h3>
          <p className="balance-invite-users-contents-description">Add new members to your team by sending them an invitation.</p>
          <div className="balance-invite-users-contents-input-container">
            <div className="tag-container">
              {emails.map((email) => (
                <div className="tag" key={email}>
                  {email}
                  <span className="remove-tag" onClick={() => removeEmail(email)}>
                    <img src={XIcon} alt="XIcon" style={{ width: '10px', height: '10px' }} />
                  </span>
                </div>
              ))}
              <input type="text" className="tag-input" placeholder={emails.length === 0 ? 'Enter your team emails' : ''} value={inputValue} onChange={handleInputChange} onKeyDown={handleKeyDown} />
            </div>
            {invalidEmail && <p className="balance-invite-users-label-error-info">{invalidEmail}</p>}
            <div className="balance-invite-users-send-button-container">
              <Button buttonName="Send Invite" className="balance-invite-users-send-button" onClick={onSendInvite} />
            </div>
          </div>
        </div>
      </div>
      {isSendPopuOpen && (
        <Modal width="35rem" onClose={closePopup}>
          <SentPopup onClose={closePopup} onConfirm={onConfirmSendInvite} buttonName="Send Invite" checkConfirm="Are you sure you want to send the invitations?" />
        </Modal>
      )}
      {isSendLoaderOpen && (
        <Modal width="32rem" onClose={closePopup} loader={true}>
          <SendingLoader onClose={closeSent} />
        </Modal>
      )}
      {isSent && (
        <Modal width="32rem" onClose={closePopup} loader={true}>
          <SingleButtonPopup onClose={closeSent} status={status} />
        </Modal>
      )}
    </div>
  );
};

export default InviteUsers;
