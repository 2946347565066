import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import ServerClient from '../services/api-client';
import useUserStore from '../store/useUserStore';

// retrieve templates permissions
export function useUserTemplatesPermissionsData() {
    const { userId } = useUserStore();
  
    return useQuery({
      queryKey: ['getUserTemplatesPermissionsData', userId],
      queryFn: async () => {
        try {
          const serverClient = new ServerClient('/api/retrieveUserTemplatesPermissionsData');
          const res = await serverClient.post({ userId });
          return res.data.data[0];
        } catch (error) {
          throw new Error('Error fetching data');
        }
      },
      onError: (error) => {
        console.error('Error fetching data:', error);
      },
    });
  }
  
  // retrieve clients permissions
  export function useUserClientPermissionsData() {
    const { userId } = useUserStore();
  
    return useQuery({
      queryKey: ['getUserClientPermissionsData', userId],
      queryFn: async () => {
        try {
          const serverClient = new ServerClient('/api/retrieveUserClientPermissionsData');
          const res = await serverClient.post({ userId });
          return res.data.data.data[0];
        } catch (err) {
          throw new Error('Error fetching client permissions data');
        }
      },
      onError: (error) => {
        console.log('Error fetching data: ', error);
      },
    });
  }
  
  // retrieve tasks permissions
  export function useUserTaskPermissionsData() {
    const { userId } = useUserStore();
  
    return useQuery({
      queryKey: ['getUserTaskPermissionsData', userId],
      queryFn: async () => {
        try {
          const serverClient = new ServerClient('/api/retrieveUserTaskPermissionsData');
          const res = await serverClient.post({ userId });
          return res.data.data.data[0];
        } catch (err) {
          throw new Error('Error fetching task permissions data');
        }
      },
      onError: (error) => {
        console.log('Error fetching data: ', error);
      },
    });
  }  