import axios from 'axios';

const axiosClient = axios.create({
    baseURL: "https://linkappnyc.com",
    // baseURL: "http://localhost:5000",
    // withCredentials: false,
});

class ServerClient {
    constructor(endpoint) {
        this.endpoint = endpoint;
    }

    getToken() {
        if (localStorage.getItem('balance-account')) {
            return JSON.parse(localStorage.getItem('balance-account')).state.userToken;
        } else {
            return 'non-user';
        }
    }

    get(params) {
        console.log('reached get api with params: ', params);
        const userToken = this.getToken();
        const config = {
            headers: {
                Authorization: `Bearer ${userToken ? userToken : ''}`,
            },
            body: {
                data: params,
            }
        }
        return axiosClient.get(this.endpoint, params, config).then((response) => response);
    }
    post(params) {
        // console.log("attempting to send a post with params: ", params);
        const userToken = this.getToken();
        const config = {
            headers: {
                Authorization: `Bearer ${userToken ? userToken : ''}`,
            },
            body: {
                data: params,
            },
        }
        return axiosClient.post(this.endpoint, params, config).then((response) => response);
    }
}

export default ServerClient;