import { create } from 'zustand';

const useNotificationStore = create((set, get) => ({
  notifications: [],
  unreadCount: 0,

  // notifications
  setNotifications: (data) =>
    set((state) => {
      const unreadCount = data.filter((notification) => !notification.isRead).length;
      return {
        notifications: Array.isArray(data) ? data : [],
        unreadCount: unreadCount,
      };
    }),

  // unreadCount
  setUnreadCount: (count) => set({ unreadCount: count }),

  addNotification: (notification) => {
    set((state) => ({
      notifications: [notification, ...state.notifications],
      unreadCount: state.unreadCount + (notification.isRead ? 0 : 1),
    }));
  },

  // prevent duplicates
  hasNotification: (notificationId) => {
    const { notifications } = get();
    return notifications.some((notification) => notification.uniqueId === notificationId);
  },

  markNotificationAsRead: (notificationId) =>
    set((state) => {
      // console.log('markNotificationAsRead:', notificationId);s
      const updatedNotifications = state.notifications.map((notification) => (notification.uniqueId === notificationId ? { ...notification, isRead: true } : notification));
      const updatedUnreadCount = updatedNotifications.filter((notification) => !notification.isRead).length;
      console.log('Unread count:', updatedUnreadCount);
      return {
        notifications: updatedNotifications,
        unreadCount: updatedUnreadCount,
      };
    }),
}));

export default useNotificationStore;
