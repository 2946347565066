import './CheckoutReturn.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ServerClient from '../services/api-client';
import FormLogo from '../components/Form/FormLogo';

const CheckoutReturn = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [checkoutInfo, setCheckoutInfo] = useState({});
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const uuid = urlParams.get('id');

        const upgradeAccount = async (email) => {
            try {
                const serverClient = new ServerClient('/api/changeAccountPlan');
                const body = { email: email, plan: 'Pro' };
                const res = await serverClient.post(body);
                return res;
            } catch (err) {
                console.log('err is: ', err);
            }
        }

        // not using react-query to align with others
        const saveAndDisplayUserName = async (name) => {
            try {
                const serverClient = new ServerClient('api/saveAndDisplayUserName');
                const body = { name, email: checkoutInfo.customer_email, sessionId: uuid };
                await serverClient.post(body);
            } catch (err) {
                console.log('Error saving.', err);
            }
        };

        const sendCheckoutId = async () => {
            try {
                setIsLoading(true);
                const serverClient = new ServerClient('/api/getStripeByCheckoutId');
                const body = { id: uuid };
                const res = await serverClient.post(body);
                setCheckoutInfo(res.data);
                if (res.data?.status === 'paid') {
                    const res2 = await upgradeAccount(res.data?.customer_email);
                    console.log('res2 is: ', res2);
                    if (checkoutInfo.customer_name) {
                        await saveAndDisplayUserName(checkoutInfo.customer_name);
                    }
                } else {
                    setError('Could not upgrade account.');
                }
            } catch (err) {
                console.log('err is: ', err);
            } finally {
                setIsLoading(false);
            }
        }
        sendCheckoutId();
    }, [checkoutInfo.customer_name]);

    return (
        <div className='balance-checkout-return-container'>
            {!isLoading ? <div className='balance-checkout-column-container balance-checkout-info-container'>
                <h2 className='balance-checkout-page-title'>{checkoutInfo ? `${checkoutInfo?.customer_name}, thank ` : `Thank`} you for subscribing to Balance!</h2>
                {
                    checkoutInfo && 
                    <p className='balance-checkout-subtext'>The account registed under <b>{`${checkoutInfo?.customer_email}`}</b> has been upgraded.</p>
                }
                <p className='balance-checkout-login-link' onClick={() => navigate('/Login')}>Log in</p>
            </div> : <div className='balance-checkout-column-container balance-checkout-info-container'>
                        <h1 className='balance-checkout-loading'>Loading...</h1>
                     </div>}
            <div className='balance-checkout-column-container'>
                <FormLogo />
            </div>
        </div>
    )
}

export default CheckoutReturn;