import useUserStore from '../../store/useUserStore';
import Button from '../../components/Button/Button';
import './Dropdown.css';

const Dropdown = ({ item, dropdownRef, dropdownButtons, isCreatedByCurrentUser, selectedStatusTab, isTemplatesPage, isClientsPage, isTasksPage }) => {
  const dropdownStyle = item ? item.style : {};

  const { templatePermissions, clientPermissions, taskPermissions } = useUserStore();

  // * * * test use
  // console.log('templatePermissions.canDelete:', templatePermissions.canDelete);
  // console.log('templatePermissions.canRead:', templatePermissions.canRead);
  // console.log('isCreatedByCurrentUser', !templatePermissions.canRead || !isCreatedByCurrentUser);

  return (
    <div className="balance-dropdown-container" ref={dropdownRef} style={dropdownStyle}>
      {isTemplatesPage &&
        dropdownButtons.map((button, index) => (
          <Button
            key={index}
            onClick={button.onClick}
            buttonName={button.label}
            className="balance-dropdown-button"
            disabled={
              !isCreatedByCurrentUser &&
              // template permissions
              ((button.label === 'Edit' && !templatePermissions.canEdit) ||
                (button.label === 'Share' && !templatePermissions.canShare) ||
                (button.label === 'Delete' && !templatePermissions.canDelete))
            }
          />
        ))}
      {isClientsPage &&
        dropdownButtons.map((button, index) => (
          <Button
            key={index}
            onClick={button.onClick}
            buttonName={button.label}
            className="balance-dropdown-button"
            disabled={
              !isCreatedByCurrentUser &&
              // clients permissions
              ((button.label === 'Change Status' && !clientPermissions.canEdit) ||
                (button.label === 'Share' && !clientPermissions.canShare) ||
                (button.label === 'Delete' && !clientPermissions.canDelete))
            }
          />
        ))}
      {isTasksPage &&
        dropdownButtons.map((button, index) => (
          <Button
            key={index}
            onClick={button.onClick}
            buttonName={button.label}
            className="balance-dropdown-button"
            disabled={
              !isCreatedByCurrentUser &&
              // tasks permissions
              ((button.label === 'Change Status' && !taskPermissions.canEdit) || (button.label === 'Delete' && !taskPermissions.canDelete))
            }
          />
        ))}
      {!isTasksPage &&
        !isClientsPage &&
        !isTemplatesPage &&
        dropdownButtons.map((button, index) => <Button key={index} onClick={button.onClick} buttonName={button.label} className="balance-dropdown-button" />)}
    </div>
  );
};

export default Dropdown;
