import { useState } from 'react';
import useFormBuilderStore from '../../../../store/useFormBuilderStore';
import {
  getAddQuestionsModalTopData,
  getAddQuestionsModalBottomData,
} from '../../../../lib/data/getPopupData';
import Button from '../../../../components/Button/Button';
import './AddFormModal.css';
import XIcon from '../../../../assets/Icon/X_Icon_Dark.svg';

const AddFormModal = ({ handleClickCreateInput }) => {
  const [isDragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

  const { setShowFormModal } = useFormBuilderStore();

  // dragable modal
  const handleMouseDown = (e) => {
    if (e.target.classList.contains('balance-add-questions-modal-container')) {
      setDragging(true);
      const offsetX = e.clientX - position.x;
      const offsetY = e.clientY - position.y;
      setDragOffset({ x: offsetX, y: offsetY });
    }
  };
  const handleMouseMove = (e) => {
    if (isDragging) {
      const newX = e.clientX - dragOffset.x;
      const newY = e.clientY - dragOffset.y;
      setPosition({ x: newX, y: newY });
    }
  };
  const handleMouseUp = () => {
    if (isDragging) {
      setDragging(false);
    }
  };
  const handleClickDuringDrag = (e) => {
    if (
      isDragging &&
      e.target.classList.contains('balance-add-questions-modal-container')
    ) {
      // e.stopPropagation();
      e.preventDefault();
    }
  };

  return (
    <div
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseUpCapture={handleClickDuringDrag}
      className='balance-add-questions-modal-container'
      style={{
        transform: `translate(${position.x}px, ${position.y}px)`,
        cursor: isDragging ? 'grabbing' : 'auto',
      }}
    >
      <div className='balance-add-questions-modal-button-container'>
        <Button
          icon={XIcon}
          iconPosition='after'
          className='balance-add-questions-modal-close-button'
          onClick={() => setShowFormModal(false)}
        />
      </div>
      {/* top list */}
      <div className='balance-add-questions-modal-inner-container'>
        {getAddQuestionsModalTopData().map((item) => (
          <div
            key={item.id}
            className='balance-add-questions-modal-lists-container'
          >
            <p className='balance-add-questions-modal-lists-title'>
              {item.title}
            </p>
            {item.lists.map((list) => (
              <div key={list.id} onClick={() => handleClickCreateInput(list.type)}>
                <p className='balance-add-questions-modal-lists-link'>
                  {list.name}
                </p>
              </div>
            ))}
          </div>
        ))}
      </div>
      {/* bottom list */}
      <div className='balance-add-questions-modal-inner-container inner-bottom'>
        {getAddQuestionsModalBottomData().map((item) => (
          <div
            key={item.id}
            className='balance-add-questions-modal-lists-container'
          >
            <p className='balance-add-questions-modal-lists-title'>
              {item.title}
            </p>
            {item.lists.map((list) => (
              <div
                key={list.id}
                onClick={() => handleClickCreateInput(list.type)}
              >
                <p className='balance-add-questions-modal-lists-link'>
                  {list.name}
                </p>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddFormModal;