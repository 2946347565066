import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useResetPasswordEmail, useResetPassword, useInvalidLink } from '../../../hooks/useEmailHooks';
import './ForgotForm.css';
import BalanceSmallLogo from '../../../assets/Logo/Balance_Small_Logo.svg';
import Button from '../../../components/Button/Button';
import ThankYouPage from '../../FormPortal/ThankYouPage';
import Loading from '../../../components/Loading/Loading';

const ForgotForm = ({ formTitle, subText, questionText, isResetPassword }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [invalidEmail, setInvalidEmail] = useState('');
  const [invalidPassword, setInvalidPassword] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isLoadingReset, setLoadingReset] = useState(false);
  const [isThankYouPage, setThankYouPage] = useState(false);
  const [passwordResetCompleted, setPasswordResetCompleted] = useState(false);
  const [isInvalidLink, setInvalidLink] = useState(false);
  const [isCheckingTokenLoading, setCheckingTokenLoading] = useState(true); // prevent the blink page

  const { mutate: resetPasswordEmailMutate } = useResetPasswordEmail();
  const { mutate: resetPasswordMutate } = useResetPassword();
  const { mutate: invalidLinkMutate } = useInvalidLink();

  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get('token');

  useEffect(() => {
    if (isResetPassword && token) {
      setCheckingTokenLoading(true);

      invalidLinkMutate(
        { token },
        {
          onSuccess: () => {
            console.log('Valid token.');
            setCheckingTokenLoading(false);
          },
          onError: () => {
            console.log('Invalid token.');
            setInvalidLink(true);
            setCheckingTokenLoading(false);
          },
        }
      );
      // if no token
    } else {
      setInvalidLink(true);
      setCheckingTokenLoading(false);
    }
  }, [token, invalidLinkMutate]);

  if (isCheckingTokenLoading) {
    return (
      <div className="balance-forgot-form-container">
        <div className="balance-forgot-form-inner-container">
          <Loading />
        </div>
      </div>
    );
  }

  if (isResetPassword && isInvalidLink) {
    return (
      <div className="balance-forgot-form-container">
        <div className="balance-forgot-form-inner-container balance-forgot-form-invalid-link">
          <p className="balance-forgot-form-invalid-message">This link is invalid or expired.</p>
        </div>
      </div>
    );
  }

  // forgot email, password
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    setInvalidEmail('');
  };
  const onSubmitEmail = async (e) => {
    e.preventDefault();
    console.log('Submit email.');

    if (!email) {
      setInvalidEmail('Email required.');
      return;
    }

    setLoading(true);

    const imagePath = '/Logo.png';

    try {
      const response = await fetch(imagePath);
      if (!response.ok) {
        throw new Error('Failed to fetch image.');
      }
      console.log('Fetched response:', response);

      const blob = await response.blob();
      // console.log('Blob:', blob);

      if (blob.type.startsWith('text')) {
        throw new Error('Fetched file is not an image.');
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const imageData = reader.result;
        // console.log('Base64:', imageData);

        resetPasswordEmailMutate(
          { email, image: imageData },
          {
            onSuccess: (data) => {
              setEmail('');
              setThankYouPage(true);
              setLoading(false);
              console.log('Reset password sent.', data);
            },
            onError: (error) => {
              setInvalidEmail('Email not found.');
              setLoading(false);
              console.error('Error sending reset password email:', error);
            },
          }
        );
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      setInvalidEmail(error.message);
      setLoading(false);
      console.error('Error handling image:', error);
    }
  };

  if (isThankYouPage) {
    return <ThankYouPage message="Please check your email inbox to continue to reset the password and exit this window." />;
  }

  // reset password
  const validatePassword = (password) => {
    const minLength = /.{6,}/;
    const number = /[0-9]/;
    const uppercase = /[A-Z]/;
    const specialChar = /[_!@#$%]/;

    if (!minLength.test(password)) {
      return 'Must be at least 6 characters.';
    }
    if (!number.test(password)) {
      return 'Must contain at least one number.';
    }
    if (!uppercase.test(password)) {
      return 'Must contain at least one uppercase letter.';
    }
    if (!specialChar.test(password)) {
      return 'Must contain a special character (_!@#$%).';
    }
    return '';
  };
  const handleChangePassword = (e) => {
    setPassword(e.target.value);
    setInvalidPassword('');
  };
  const handleChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    setInvalidPassword('');
  };
  const onSubmitPassword = (e) => {
    e.preventDefault();
    console.log('Submit passowrd reset.');

    setLoadingReset(true);

    const passwordRules = validatePassword(password);
    if (passwordRules) {
      setInvalidPassword(passwordRules);
      setLoadingReset(false);
      return;
    }
    if (password !== confirmPassword) {
      setInvalidPassword('Passwords do not match.');
      setLoadingReset(false);
      return;
    }
    resetPasswordMutate(
      { token, password },
      {
        onSuccess: (data) => {
          setPasswordResetCompleted(true);
          setPassword('');
          setConfirmPassword('');
          setLoadingReset(false);
          console.log('Password reset OK.', data);
        },
        onError: (error) => {
          setInvalidEmail('Failed reset password.');
          setLoadingReset(false);
          console.error('Error reset password:', error);
        },
      }
    );
  };

  return (
    <div className="balance-forgot-form-container">
      <div className="balance-forgot-form-inner-container">
        <div className="balance-forgot-form-logo-container">
          <img src={BalanceSmallLogo} alt="Logo" />
        </div>
        <div className="balance-forgot-form-title-container">
          <h1 className="balance-forgot-form-title">{formTitle}</h1>
          {!isResetPassword && <p className="balance-forgot-form-sub-title">{subText}</p>}
        </div>
        {/* reset password */}
        {isResetPassword ? (
          <div>
            <div className="balance-forgot-form-new-password-rules">
              <ul>
                <li>6 characters minimum</li>
                <li>At least 1 number</li>
                <li>At least 1 uppercase character</li>
                <li>Contains a special character _!@#$%</li>
              </ul>
            </div>
            <form onSubmit={onSubmitPassword} className="balance-global-form">
              <label htmlFor="password" className="balance-global-label balance-reset-password-label" value={password} onChange={handleChangePassword}>
                New password
                {invalidPassword && <p className="balance-label-error-info balance-reset-password-label-error-info">{invalidPassword}</p>}
              </label>
              <input
                type="password"
                id="password"
                placeholder="Enter your new password"
                className="balance-global-input"
                value={password}
                onChange={handleChangePassword}
                disabled={passwordResetCompleted}
              />
              <label htmlFor="confirm-password" className="balance-global-label">
                Confirm new password
              </label>
              <input
                type="password"
                id="confirm-password"
                placeholder="Re-enter the password"
                className="balance-global-input"
                value={confirmPassword}
                onChange={handleChangeConfirmPassword}
                disabled={passwordResetCompleted}
              />
              <Button
                buttonName={isLoadingReset ? 'Loading...' : 'Reset password'}
                className={`balance-global-blue-button balance-global-movable-button ${isLoadingReset ? 'balance-global-disabled-button' : undefined} ${
                  passwordResetCompleted ? 'balance-global-disabled-button' : undefined
                }`}
              />
              <div className="balance-forgot-form-question-container">
                <p className="balance-global-gray-question">{questionText}</p>
              </div>
            </form>
          </div>
        ) : (
          // forgot email, password page inputs
          <form onSubmit={onSubmitEmail} className="balance-global-form">
            <label htmlFor="email" className="balance-global-label">
              Email
              {invalidEmail && <p className="balance-label-error-info">{invalidEmail}</p>}
            </label>
            <input type="text" id="email" placeholder="Enter your email" className="balance-global-input" value={email} onChange={handleChangeEmail} />
            <Button
              buttonName={isLoading ? 'Loading...' : 'Submit'}
              className={`balance-global-blue-button balance-global-movable-button ${isLoading ? 'balance-global-disabled-button' : undefined}`}
            />
            <div className="balance-forgot-form-question-container">
              <p className="balance-global-gray-question">{questionText}</p>
              <Button onClick={() => navigate('/Login')} buttonName="Sign in" className="balance-global-blue-navigate-button" />
            </div>
          </form>
        )}
        {passwordResetCompleted && (
          <div className="balance-reset-password-login-button-container">
            <p className="balance-global-gray-question">Your password has been changed successfully.</p>
            <Button buttonName="Login" onClick={() => navigate('/Login')} className="balance-global-blue-navigate-button balance-reset-password-login-button" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotForm;
