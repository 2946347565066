import React from 'react';
import { nanoid } from 'nanoid';
import Button from '../../../components/Button/Button';
import ProgressBar from './ProgressBar';
import BalanceLogo from '../../../assets/Logo/Balance_Big_Logo.svg';
import Deco from '../../../assets/Logo/Deco.png'
import './Security.css';

const Security = () => {
  const dataSection2 = [
    {
      id: nanoid(),
      subtitle: 'Encryption in Transit',
      desc: 'Our servers are TLS encrypted. This ensures that all data transmitted between your device and our servers is protected against interception and unauthorized access.',
    },
    {
      id: nanoid(),
      subtitle: 'Encryption at Rest',
      desc: 'We use AES (Advanced Encryption Standard) to encrypt user data stored in our database. This means that even if the physical storage media were accessed by an unauthorized party, the data would remain secure and unreadable without the proper decryption key.',
    },
    {
      id: nanoid(),
      subtitle: 'Cloud Security',
      desc: 'Our infrastructure is hosted on the AWS Cloud, which is known for its industry-standard data security measures. AWS provides a robust and secure environment, adhering to best practices for cloud security.',
    },
  ];

  const dataSection3 = [
    {
      id: nanoid(),
      subtitle: 'SOC 2 Compliance',
      desc: 'We are committed to ensuring our systems and processes meet the stringent requirements of SOC 2 compliance. This includes rigorous controls over security, availability, processing integrity, confidentiality, and privacy of customer data.',
    },
    {
      id: nanoid(),
      subtitle: 'GDPR Compliance',
      desc: 'We are committed to ensuring our systems and processes meet the requirements of General Data Protection Regulation (GDPR), we prioritize the privacy and protection of personal data for our users within the European Union. Our practices ensure that we handle all personal data with the highest level of care and transparency.',
    },
    {
      id: nanoid(),
      subtitle: 'CCPA Compliance',
      desc: 'We are committed to ensuring our systems and processes meet the requirements of the California Consumer Privacy Act (CCPA), ensuring the rights and privacy of our users in California. This includes giving users control over their personal information and maintaining transparency about our data collection practices.',
    },
  ];

  return (
    <div className="balance-security-container">
      {/* section 1 */}
      <div className="balance-security-section-1-diagonal-background">
        <div className="balance-security-section-1 content">
          <div className="balance-security-main-container balance-security-left-container">
            <h1 className="balance-security-title">
              Our Commitment to
              <br />
              Data Security and Protection
            </h1>
            <p className="balance-security-desc">
              At Balance, safeguarding your data is our top priority. We understand the importance of maintaining robust security
              measures to protect your sensitive information. Our commitment to data security and protection is demonstrated
              through our adherence to industry-leading standards and regulatory compliance.
            </p>
            {/* <Button buttonName="Contact us" className="balance-security-button balance-global-movable-button" /> */}
          </div>
          <div className="balance-security-main-container balance-security-right-container">
            <img src={BalanceLogo} alt="Image" className="balance-security-logo" />
          </div>
        </div>
      </div>
      {/* section 2 */}
      <div className="balance-security-section-2">
        <div>
          <h2 className="balance-security-section-2-title">Data Encryption and Protection</h2>
        </div>
        <div className="balance-security-section-2-contents">
          <ProgressBar />
          <div>
            {dataSection2.map((detail, index) => (
              <div
                key={detail.id}
                style={{
                  marginTop: index === 1 ? '5rem' : index === 2 ? '4rem' : '0',
                }}
              >
                <h4 className="balance-security-section-2-sub-title">{detail.subtitle}</h4>
                <p>{detail.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* section 3 */}
      <div className="balance-security-section-3">
        <div>
          <h2 className="balance-security-section-2-title">Regulatory Compliance</h2>
        </div>
        <div className="balance-security-section-2-contents">
          <ProgressBar />
          <div>
            {dataSection3.map((detail, index) => (
              <div
                key={detail.id}
                style={{
                  marginTop: index === 1 ? '5rem' : index === 2 ? '4rem' : '0',
                }}
              >
                <h4 className="balance-security-section-2-sub-title">{detail.subtitle}</h4>
                <p>{detail.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* section 4 */}
      <div className="balance-security-section-4">
        <h1 className="balance-security-section-4-title">Commitment to Continuous Improvement</h1>
        <p className='balance-security-section-4-desc'>
          We are dedicated to continuously enhancing our security measures to establish trust and transparency with our users.
        </p>
      </div>
      {/* section 5 */}
      <div className="balance-security-section-5">
        <img src={Deco} alt="Image" className='balance-security-deco' />
        <h1 className="balance-security-section-5-title">Contact Us</h1>
        <p className='balance-security-section-5-desc'>
          If you have any questions or concerns about our security practices, please do not hesitate to contact us. We are here to
          provide you with the information and support you need to feel confident in the security of your data.
        </p>
      </div>
    </div>
  );
};

export default Security;
