import React from 'react';
import './Toggle.css';

const Toggle = ({ onChange, state }) => {
  return (
    <div>
      <label className="toggle-switch">
        <input type="checkbox" onChange={onChange} checked={state} />
        <span className="toggle-slider"></span>
      </label>
    </div>
  );
};

export default Toggle;
