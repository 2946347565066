import './Error.css';

const Error = () => {
  return (
    <div className='balance-error-container'>
      <h1>Error has occured.</h1>
    </div>
  );
};

export default Error;
