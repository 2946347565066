import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import PriceCard from './PriceCard';
import './Pricing.css';
import BalanceLogo from '../../../assets/Logo/Balance_Big_Logo.svg';

const Pricing = () => {
  const navigate = useNavigate();

  return (
    <div className="balance-pricing-container">
      <div className="balance-pricing-button-container" onClick={() => navigate('/')}>
        <Icon icon="ic:sharp-arrow-back-ios" className="balance-pricing-back-button" />
      </div>
      <div className="balance-pricing-background">
        <img src={BalanceLogo} alt="Logo" className="balance-pricing-logo" />
      </div>
      <div className="balance-pricing-cards-container">
        <PriceCard />
      </div>
    </div>
  );
};

export default Pricing;
