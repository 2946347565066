import { useState, useRef, useEffect } from 'react';
import useFormBuilderStore from '../../../../../store/useFormBuilderStore';
import Button from '../../../../../components/Button/Button';
import './SideLeft.css';
import AddIcon from '../../../../../assets/Icon/Add_Icon_White.svg';
import CheckIcon from '../../../../../assets/Icon/Check_Icon.svg';
import UpChevron from '../../../../../assets/Icon/Chevron_Up_Icon.svg';
import DownChevron from '../../../../../assets/Icon/Chevron_Down_Icon.svg';

const SideLeft = ({
  handleClickAddSection,
  handleClickAddQuestion,
  // handleChangeEditSection,
  // editSection,
}) => {
  // const [selectedSection, setSelectedSection] = useState(0);

  // store
  const { leftSidebarOpen, sections, setSectionIndex, setQuestionIndex, selectedSectionIndex, selectedQuestionIndex, setSectionTitle, setQuestionTitle, removeSection, removeQuestion } = useFormBuilderStore();
  // ref
  const sectionRef = useRef([]);
  const questionRef = useRef([]);
  const [openCloseSidebarSections, setOpenCloseSidebarSections] = useState([]);
  const didMount = useRef(false);

  useEffect(() => {
    // currently, this runs when any character is typed when creating a title, not good.
    if (didMount.current) {
      setOpenCloseSidebarSections([...openCloseSidebarSections, true]);
      console.log('RERENDER sidebar array is: ', [...openCloseSidebarSections, true]);
    } else {
      didMount.current = true;
    }
  }, [sections]);

  useEffect(() => {
    setOpenCloseSidebarSections(Array(...Array(sections.length)).map(() => true));
    console.log('MOUNT sidebar array is: ', Array(...Array(sections.length)).map(() => true));
  }, []);

  const toggleSidebarLeftSection = (index) => {
    setOpenCloseSidebarSections([...openCloseSidebarSections.slice(0, index), !openCloseSidebarSections[index], ...openCloseSidebarSections.slice(index + 1)]);
  }

  // clickable section
  const handleClickSection = (index) => {
    setSectionIndex(index);
    setQuestionIndex(null);
  };

  const handleClickQuestion = (index, questionIndex) => {
    setQuestionIndex(questionIndex);
    setSectionIndex(index);
  }

  const handleEditSectionTitle = (e, index) => {
    setSectionTitle(index, e.target.value);
  }

  // enter key and save section
  const handleKeyDownSave = (e, index, selectedRef) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      selectedRef.current[index].blur();
      // sections[index].sectionTitle = e.target.value;
      // console.log("target value is: ", e.target.value);
    }
  };

  const handleEditQuestionTitle = (e, sectionIndex, questionIndex) => {
    setQuestionTitle(sectionIndex, questionIndex, e.target.value);
  }

  const handleSectionDelete = (sectionIndex) => {
    removeSection(sectionIndex);
    setSectionIndex(0);
    console.log('Setting section index to 0!');
  }

  const handleQuestionDelete = (sectionIndex, questionIndex) => {
    removeQuestion(sectionIndex, questionIndex);
    setSectionIndex(0);
  }

  return (
    <div
      className={`balance-global-sidebar-left-container ${
        leftSidebarOpen ? '' : 'closed'
      }`}
    >
      <div className='balance-global-sidebar-left-title-container'>
        {/* title */}
        <p className='balance-global-sidebar-left-title'>Content</p>
        {/* add buttons */}
        <div className='balanace-global-sidebar-left-add-button-container'>
          {/* + button */}
          <Button
            icon={AddIcon}
            iconPosition='after'
            className='balance-global-sidebar-left-add-button'
            onClick={handleClickAddQuestion}
          />
          {/* add section button */}
          <Button
            buttonName='Add Section'
            className='balance-global-sidebar-left-add-section-button'
            onClick={handleClickAddSection}
          />
        </div>
      </div>
      {/* sections */}
      {sections.map((section, index) => (
        <div key={index} className={index === selectedSectionIndex ? 'balance-global-sidebar-left-section-selected' : undefined}>
        <div
          
          className={`balance-global-sidebar-left-section-container ${
            selectedSectionIndex === index ? 'selected-section-background' : ''
          }`}
          onClick={() => handleClickSection(index)}
        >
          <p className='balance-global-sidebar-left-section-title'>
            <span className='balance-global-sidebar-left-section-delete' onClick={() => handleSectionDelete(index)}>{sections.length > 1 ? 'x' : undefined}</span>
            <span>{index}</span>
            <input
              ref={(element) => (sectionRef.current[index] = element)}
              type='text'
              value={section?.sectionTitle || ''}
              onChange={(e) => handleEditSectionTitle(e, index)}
              onKeyDown={(e) => handleKeyDownSave(e, index, sectionRef)}
              placeholder='Enter Title here'
              className={`balance-global-sidebar-left-section-section-edit-name ${
                selectedSectionIndex === index ? 'selected-section-background' : ''
              }`}
            />
          </p>
          {selectedSectionIndex === index && selectedQuestionIndex === null && <img
            src={CheckIcon}
            alt='CheckIcon'
            className='balance-global-sidebar-left-check-icon'
          />}
          {section.sectionInputs.length > 0 && <img src={openCloseSidebarSections[index] ? UpChevron : DownChevron} alt='DownIcon' onClick={() => toggleSidebarLeftSection(index)} className={`global-balance-sidebar-left-chevron-icon-down`} />}
        </div>
        {
          section.sectionInputs.length > 0 && 
          <div className='balance-global-sidebar-left-inputs-dropdown'>
            {openCloseSidebarSections[index] && section.sectionInputs.map((input, questionIndex) => {
              return (
                <div key={questionIndex + 100} className={`balance-global-sidebar-left-inputs-dropdown-individual-container ${index !== selectedSectionIndex ? '' : questionIndex !== selectedQuestionIndex ? '' : `balance-global-sidebar-left-input-selected-question`}`} onClick={() => handleClickQuestion(index, questionIndex)}>
                <div className={`balance-global-sidebar-left-inputs-dropdown-individual-input `}>
                  <span className='balance-global-sidebar-left-section-delete' onClick={() => handleQuestionDelete(index, questionIndex)}>x</span>
                  <span>{letters[questionIndex % 26]}.</span>
                  <input
                    ref={(element) => (questionRef.current[questionIndex] = element)}
                    type='text'
                    value={input?.inputTitle || ''}
                    onChange={(e) => handleEditQuestionTitle(e, index, questionIndex)}
                    onKeyDown={(e) => handleKeyDownSave(e, questionIndex, questionRef)}
                    placeholder='Question Title here'
                    className={`balance-global-sidebar-left-inputs-dropdown-individual-input-title`}
                  />
                </div>
                  {selectedSectionIndex === index && selectedQuestionIndex === questionIndex && <img
                    src={CheckIcon}
                    alt='CheckIcon'
                    className='balance-global-sidebar-left-check-questions-icon'
                  />}
                </div>
              )
            })}
          </div>
          }
        </div>
      ))}
    </div>
  );
};

const letters = "abcdefghijklmnopqrstuvwxyz";

export default SideLeft;
