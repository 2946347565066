import { nanoid } from 'nanoid';
import useUserStore from '../../store/useUserStore';
import DashboardIcon from '../../assets/Icon/Dashboard_Icon.svg';
import NotificationIcon from '../../assets/Icon/Notification_Icon.svg';
import TemplateIcon from '../../assets/Icon/Template_Icon.svg';
import ClientsIcon from '../../assets/Icon/Clients_Icon.svg';
import SettingsIcon from '../../assets/Icon/Settings_Icon.svg';
import ReportingIcon from '../../assets/Icon/Reporting_Icon.svg';
import SupportIcon from '../../assets/Icon/Support_Icon.svg';

export function GetNavData() {
  return [
    {
      id: nanoid(),
      iconType: 'svg',
      icon: DashboardIcon,
      textType: 'non-iconify',
      text: 'Dashboard',
      link: '/Hub/Dashboard',
    },
    {
      id: nanoid(),
      iconType: 'svg',
      icon: NotificationIcon,
      textType: 'non-iconify',
      text: 'Notifications',
      link: '/Hub/Notifications',
    },
    {
      id: nanoid(),
      iconType: 'svg',
      icon: TemplateIcon,
      textType: 'non-iconify',
      text: 'Applications',
      link: '/Hub/CreateTemplatesInitial#Templates',
    },
    {
      id: nanoid(),
      iconType: 'iconify',
      iconName: 'material-symbols:task-alt-rounded',
      textType: 'iconify',
      text: 'Tasks',
      link: '/Hub/Tasks',
    },
    {
      id: nanoid(),
      iconType: 'svg',
      icon: ClientsIcon,
      textType: 'non-iconify',
      text: 'Client Hub',
      link: '/Hub/Clients',
    },
    // {
    //   id: nanoid(),
    //   icon: ReportingIcon,
    //   text: 'Reporting',
    //   link: '/Hub/Reporting',
    // },
  ];
}

export function GetNavSubData() {
  // const { isAdmin } = useUserStore();

  return [
    // {
    //   id: nanoid(),
    //   icon: SupportIcon,
    //   text: 'Support',
    //   link: '/Hub/Support',
    // },
    {
      id: nanoid(),
      icon: SettingsIcon,
      text: 'Settings',
      link: '/Hub/Settings',
    },
  ];
}
