import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ServerClient from '../../../../services/api-client';
import { nanoid } from 'nanoid';
import { useRetrieveTemplates } from '../../../../hooks/useTemplateHooks';
import useFormBuilderStore from '../../../../store/useFormBuilderStore';
import useUserStore from '../../../../store/useUserStore';
// toast
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// hooks
import {
  useGetOwnTemplates,
  useGetSharedTemplates,
  useDeleteOwnTemplates,
  useDeleteSharedTemplates,
  usePrebuiltTemplates,
} from '../../../../hooks/useTemplateHooks';
import { useUserTemplatesPermissionsData } from '../../../../hooks/usePermissionHooks';
import useDropdown from '../../../../hooks/useDropdown';
// components
import PageTopLayout from '../../../../components/Layout/PageTopLayout';
import Button from '../../../../components/Button/Button';
import Loading from '../../../../components/Loading/Loading';
import Error from '../../../../components/Loading/Error';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import Modal from '../../../../components/Popup/Modal';
import ShareTeam from '../../../../components/Popup/ShareTeam';
import DeletePopup from '../../../../components/Popup/DeletePopup';
// css
import '../../../../components/Grid/Grid.css';
// svg
import DotIcon from '../../../../assets/Icon/3Dot_Button_Icon.svg';

const CreateTemplatesInitial = () => {
  const layout = [
    {
      id: nanoid(),
      title: 'Applications',
      description: 'Create, review, and manage your client facing templates here.',
    },
  ];

  const [searchTerm, setSearchTerm] = useState(''); // used in other page
  const [searchTermMyApplications, setSearchTermMyApplications] = useState(''); // search keywords for my applications tab
  const [searchTermShared, setSearchTermShared] = useState(''); // search keywords for shared tab
  const [filteredData, setFilteredData] = useState([]); // filtered data
  const [activeStatusTab, setActiveStatusTab] = useState('Templates');
  const [isShareTeamOpen, setShareTeamOpen] = useState(false); // share team popup
  const [shareTeamTemplateId, setShareTeamTemplateId] = useState('');
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false); // delete popup
  const [deleteWhichTemplate, setDeleteWhichTemplate] = useState(null); // delete action when clicked on delete
  const [selectedCategory, setSelectedCategory] = useState({ value: 'All', label: 'All' }); // dropdown category
  // store
  const { success, setSuccess } = useFormBuilderStore(); // toast, saved form data from mongo
  const {
    selectedData,
    setSelectedData,
    selectedEditData,
    setSelectedEditData,
    selectedOpenData,
    setSelectedOpenData,
    resetTemplate,
    setLinkData,
    selectedStatusTab,
    setSelectedStatusTab,
  } = useFormBuilderStore(); // selected checkbox or edit dropdown
  const { userId, setTemplatePermissions, userName } = useUserStore();
  // custom hook
  const { openDropdown, handleClickDot, handleClickOutside, dropdownRef } = useDropdown();
  // tanstack hook
  const { data, isLoading, isError } = useGetOwnTemplates();
  const { data: sharedData, isLoading: sharedIsLoading, isError: sharedIsError } = useGetSharedTemplates();
  const { data: userTemplatesData } = useUserTemplatesPermissionsData(); // user permissions
  const { mutate: deleteOwnMutate } = useDeleteOwnTemplates(); // delete own templates
  const { mutate: deleteSharedMutate } = useDeleteSharedTemplates(); // delete shared templates
  const { data: retrieveTemplates } = useRetrieveTemplates();
  const { mutate: savePrebuiltTemplatesMutate } = usePrebuiltTemplates();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let filteredTemplates = [];
    if (selectedStatusTab === 'My Applications') {
      filteredTemplates = data;
    } else if (selectedStatusTab === 'Shared') {
      filteredTemplates = sharedData;
    } else if (selectedStatusTab === 'Templates') {
      filteredTemplates = retrieveTemplates;
    }

    if (selectedCategory.value !== 'All') {
      filteredTemplates = filteredTemplates?.filter((template) => template.templateCategory === selectedCategory.value);
    }

    setFilteredData(filteredTemplates);
    // console.log(`Current tab: ${selectedStatusTab}, Display data:`, filteredTemplates);
  }, [selectedStatusTab, data, sharedData, retrieveTemplates, selectedCategory]);

  // set dropdown category to "all" initially
  // useEffect(() => {
  //   setSelectedCategory({ value: 'All', label: 'All' });
  // }, []);

  // permissions load
  useEffect(() => {
    if (userTemplatesData) {
      // console.log('userTemplatesData:', userTemplatesData);
      const { canEditTemplates, canDeleteTemplates, canShareTemplates } = userTemplatesData;
      setTemplatePermissions({
        canEdit: canEditTemplates,
        canDelete: canDeleteTemplates,
        canShare: canShareTemplates,
      });
    }
  }, [userTemplatesData, setTemplatePermissions]);

  // make the # link work
  useEffect(() => {
    const hash = location.hash.substring(1);
    // console.log('hash', hash);
    if (hash === 'MyApplications') {
      setSelectedStatusTab('My Applications');
      setActiveStatusTab('My Applications');
    } else if (hash === 'Shared') {
      setSelectedStatusTab('Shared');
      setActiveStatusTab('Shared');
    } else {
      setSelectedStatusTab('Templates');
      setActiveStatusTab('Templates');
    }
  }, [location.hash]);

  // reset the form once you return to the main screen
  useEffect(() => {
    resetTemplate();
    setSelectedOpenData([]);
  }, []);

  // share button
  const handleClickShare = async () => {
    // const generateLink = async (selectedData) => {
    //   try {
    //     console.log('selectedData is: ', selectedData);
    //     console.log('userId is: ', userId);
    //     const serverClient = new ServerClient('/api/generatedLink');
    //     const res = await serverClient.post({ selectedData, userId });
    //     return res.data.link;
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //     throw error;
    //   }
    // };
    if (selectedData?.length === 1) {
      setLinkData({formId: selectedData, bankerId: userId });
      navigate('/Share#Email-Form');

      // const generatedLink = await generateLink(selectedData);
      // console.log('generated link:', generatedLink);
      
      // setSelectedData(selectedData);
      setSelectedData([]);
    } else if (selectedData?.length > 1) {
      alert('Select a single Application to share.');
    } else {
      alert('Select an Application to share.');
    }
  };

  // toast when success : )
  useEffect(() => {
    if (success) {
      if (selectedEditData?.length === 0) {
        toast('Application Successfully Submitted.', {
          position: 'top-right',
          autoClose: 3000,
          closeButton: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          transition: Slide,
        });
      } else {
        toast('Application Successfully Updated.', {
          position: 'top-right',
          autoClose: 3000,
          closeButton: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          transition: Slide,
        });
      }
      setSuccess(null);
    } else if (success === 'Failed') {
      toast.error('Failed to submit application.', {
        position: 'top-right',
        autoClose: 3000,
        closeButton: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        transition: Slide,
      });
      setSuccess(null);
    }
  }, [success, setSuccess, selectedEditData, selectedStatusTab]);

  // * * * if you want to see the fail toast, trigger error here
  // useEffect(() => {
  //   if (success) {
  //     toast('Application Successfully Submitted.', {
  //       position: 'top-right',
  //       autoClose: 3000,
  //       closeButton: false,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: false,
  //       transition: Slide,
  //     });
  //   } else {
  //     toast.error('Failed to submit application.', {
  //       position: 'top-right',
  //       autoClose: 3000,
  //       closeButton: false,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: false,
  //       transition: Slide,
  //     });
  //   }
  //   setSuccess(null);
  // }, [success, selectedEditData, selectedStatusTab, setSuccess]);

  // filter search
  const handleSearch = (e) => {
    const keyword = e.target.value.toLowerCase();
    // filtering : )
    if (selectedStatusTab === 'My Applications') {
      setSearchTermMyApplications(keyword);
      const filtered = keyword ? data.filter((item) => item.formTitle.toLowerCase().includes(keyword)) : data;
      setFilteredData(filtered);
    }
    if (selectedStatusTab === 'Shared') {
      setSearchTermShared(keyword);
      const filtered = keyword
        ? sharedData.filter(
            (item) => item.formTitle.toLowerCase().includes(keyword) || item.userName.toLowerCase().includes(keyword)
          )
        : sharedData;
      setFilteredData(filtered);
    }
  };

  // checkbox
  const handleChangeCheckbox = (id) => {
    if (selectedData.includes(id)) {
      setSelectedData(selectedData.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedData([...selectedData, id]);
    }
  };

  // open
  const handleClickOpen = (id) => {
    // console.log('View:', id);
    if (selectedOpenData?.length === 1 && selectedOpenData[0] === id) {
      setSelectedOpenData([]);
    } else {
      setSelectedOpenData([id]);
      navigate(`/Hub/CreateTemplatesInitial/Preview?id=${id}`);
    }
  };
  // edit
  const handleClickEdit = async (id) => {
    if (selectedEditData.length === 1 && selectedEditData[0] === id) {
      setSelectedEditData([]);
    } else {
      setSelectedEditData([id]);
      navigate(`/Hub/CreateTemplatesInitial/Form-Builder?id=${id}`);
    }
  };
  // share
  const handleClickShareTeamPopup = (id) => {
    setShareTeamTemplateId(id);
    setShareTeamOpen(true);
  };
  const closeShareTeamPopup = () => {
    setShareTeamOpen(false);
  };
  // * * * delete * * *
  // which uuid?, open popup when click on delete dropdown
  const handleClickDelete = (uuid) => {
    setDeleteWhichTemplate(uuid);
    // console.log('which uuid?', uuid);
    setDeletePopupOpen(true);
  };
  // delete action
  const handleClickDeleteTemplates = async () => {
    try {
      if (selectedStatusTab === 'My Applications') {
        await deleteOwnMutate({ uuid: deleteWhichTemplate });
      } else {
        await deleteSharedMutate({ uuid: deleteWhichTemplate });
      }
      // delete
      setFilteredData((prev) => prev.filter((template) => template.uniqueId !== deleteWhichTemplate));
      // close popup
      closeDeletePopup();
    } catch (error) {
      console.error('Error deleting template', error);
    }
  };
  // close delete popup
  const closeDeletePopup = () => {
    setDeletePopupOpen(false);
  };

  // loading, error screens
  if (isLoading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }
  if (isError) {
    return (
      <div>
        <Error />
      </div>
    );
  }

  // save prebuilt templates
  const handleSavePrebuiltTemplates = (templateId) => {
    savePrebuiltTemplatesMutate(
      { templateId, userId, userName },
      {
        onSuccess: () => {
          toast('Application successfully saved in "My Applications".', {
            position: 'top-right',
            autoClose: 3000,
            closeButton: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            transition: Slide,
          });
        },
        onError: () => {
          toast('Failed to save an application.', {
            position: 'top-right',
            autoClose: 3000,
            closeButton: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            transition: Slide,
          });
        },
      }
    );
  };

  // * * * test use
  // const targetID = '660e0cac4341ac47413b6dd2';
  // const testData = templatesData.find((data) => data._id === targetID);

  return (
    <>
      <PageTopLayout
        handleSearch={handleSearch}
        searchTerm={searchTerm}
        searchTermMyApplications={searchTermMyApplications}
        searchTermShared={searchTermShared}
        layout={layout}
        isClientsPage={false}
        isCreateTemplatesInitialPage={true}
        selectedStatusTab={selectedStatusTab}
        setSelectedStatusTab={setSelectedStatusTab}
        handleClickShare={handleClickShare}
        activeStatusTab={activeStatusTab}
        setActiveStatusTab={setActiveStatusTab}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      {/* templates table */}
      <>
        {selectedStatusTab === 'My Applications' || selectedStatusTab === 'Shared' ? (
          // * * * for my applications, shared tab
          <div className="balance-create-templates-initial-container" onClick={handleClickOutside}>
            <div className="balance-grid-initial-table">
              <div className="balance-grid-header">
                {/* <input type="checkbox" className="balance-grid-checkbox" /> */}
                Application
                {/* <img src={ArrowDown} alt="Icon" /> */}
              </div>
              <div className="balance-grid-header">Country</div>
              <div className="balance-grid-header">Date created</div>
              <div className="balance-grid-header">Last updated</div>
              <div className="balance-grid-header">Created by</div>
              <div className="balance-grid-header"></div>
              {filteredData?.map((template, index) => {
                const isCreatedByCurrentUser = template.userName === userName;
                // const isPrebuiltTemplate = template.templateCategory !== undefined;
                // console.log(isCreatedByCurrentUser);
                const dropdownButtons = [
                  {
                    label: 'Open',
                    onClick: () => handleClickOpen(template?.uniqueId),
                  },
                  {
                    label: 'Edit',
                    onClick: () => handleClickEdit(template?.uniqueId),
                  },
                  {
                    label: 'Share',
                    onClick: () => handleClickShareTeamPopup(template?.uniqueId),
                  },
                  { label: 'Delete', onClick: () => handleClickDelete(template?.uniqueId) },
                ];
                return (
                  <React.Fragment key={index}>
                    <div className="balance-grid-cell">
                      <input
                        id={`linked-${index}`}
                        type="checkbox"
                        className="balance-grid-checkbox"
                        checked={selectedData.includes(template.uniqueId)}
                        onChange={() => handleChangeCheckbox(template.uniqueId)}
                      />
                      <div className="balance-grid-title-title-container">
                        <label htmlFor={`linked-${index}`} className="balance-grid-title">
                          {template.formTitle}
                        </label>
                      </div>
                    </div>
                    <div className="balance-grid-cell">
                      <div className="balance-country">
                        <span className="balance-country-name">{template.selectedCountry?.value}</span>
                      </div>
                      {/* <span className='balance-country-name'>{template.selectedCountry?.text}</span> */}
                      {/* <img src={template.country} alt='Icon' /> */}
                    </div>
                    <div className="balance-grid-cell balance-grid-initial-created">
                      {new Date(template.publishDate).toLocaleDateString()}
                    </div>
                    <div className="balance-grid-cell balance-grid-updated">
                      {template.updatedDate !== '-' ? new Date(template.updatedDate).toLocaleDateString() : '-'}
                    </div>
                    <div className="balance-grid-cell balance-grid-initial-user">
                      <p>{template.userName}</p>
                    </div>
                    <div className="balance-grid-cell">
                      <Button
                        icon={DotIcon}
                        iconPosition="after"
                        className="balance-grid-dot-button"
                        onClick={(e) => handleClickDot(index, e)}
                      />
                      {/* dropdown */}
                      {openDropdown[index] && (
                        <Dropdown
                          item={openDropdown[index]}
                          openDropdown={openDropdown}
                          dropdownRef={dropdownRef}
                          dropdownButtons={dropdownButtons}
                          isCreatedByCurrentUser={isCreatedByCurrentUser}
                          selectedStatusTab={selectedStatusTab}
                          isTemplatesPage={true}
                        />
                      )}
                    </div>
                  </React.Fragment>
                );
              })}
              {filteredData?.length === 0 && (
                <div
                  style={{
                    display: 'grid',
                    gridColumn: 'span 5',
                    placeItems: 'center',
                    color: 'gray',
                    fontStyle: 'italic',
                    padding: '1.5rem',
                  }}
                >
                  No templates to display
                </div>
              )}
            </div>
            {/* table footer */}
            <div className="balance-grid-footer">
              <div className="balance-grid-footer-pages">Page 1 of 1</div>
              <div className="balance-grid-footer-button">
                <Button buttonName="Previous" className="balance-grid-footer-previous-button" />
                <Button buttonName="Next" className="balance-grid-footer-next-button" />
                <ToastContainer
                  toastStyle={{
                    color: '#000000',
                    fontWeight: '600',
                    textAlign: 'center',
                    background: '#d1ffd9',
                  }}
                />
              </div>
            </div>
            {isShareTeamOpen && (
              <Modal width="32rem" onClose={closeShareTeamPopup}>
                <ShareTeam isDocumentModal={true} onClose={closeShareTeamPopup} templateId={shareTeamTemplateId} />
              </Modal>
            )}
            {isDeletePopupOpen && (
              <Modal width="32rem" onClose={closeDeletePopup}>
                <DeletePopup
                  onClose={closeDeletePopup}
                  checkConfirm="Are you sure you want to delete?"
                  buttonName="Delete"
                  onConfirm={handleClickDeleteTemplates}
                />
              </Modal>
            )}
          </div>
        ) : (
          // * * * for templates tab
          <div className="balance-create-templates-initial-container">
            <div className="balance-grid-initial-templates-tab-table">
              <div className="balance-grid-header">Template name</div>
              <div className="balance-grid-header"></div>
              {filteredData?.map((template, index) => {
                // const sectionsData = JSON.parse(template.sectionsData);
                return (
                  <React.Fragment key={index}>
                    <div className="balance-grid-cell">
                      <div className="balance-grid-title-container">
                        <span className="balance-grid-title">{template.formTitle}</span>
                        {/* {sectionsData.map((section, sectionIndex) => ( */}
                        <span>For US applications</span>
                        {/* ))} */}
                      </div>
                    </div>
                    <div className="balance-grid-cell">
                      <Button
                        buttonName="View"
                        className="balance-grid-button-1 balance-global-movable-button"
                        onClick={() => handleClickOpen(template?.uniqueId)}
                      />
                      <Button
                        buttonName="Make an application"
                        className="balance-grid-button-2 balance-global-movable-button"
                        onClick={() => handleSavePrebuiltTemplates(template?.uniqueId)}
                      />
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
            {/* table footer */}
            <div className="balance-grid-footer">
              <div className="balance-grid-footer-pages">Page 1 of 1</div>
              <div className="balance-grid-footer-button">
                <Button buttonName="Previous" className="balance-grid-footer-previous-button" />
                <Button buttonName="Next" className="balance-grid-footer-next-button" />
                <ToastContainer
                  toastStyle={{
                    color: '#000000',
                    fontWeight: '600',
                    textAlign: 'center',
                    background: '#d1ffd9',
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default CreateTemplatesInitial;
