import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ServerClient from '../../../../../services/api-client';
import useFormBuilderStore from '../../../../../store/useFormBuilderStore';
import Button from '../../../../../components/Button/Button';
import './Preview.css';
import '../FormBuilderLayout.css';
import Arrow from '../../../../../assets/Icon/Arrow_Left_Dark.svg';
import MobileView from './MobileView';
import DesktopView from './DesktopView';

const Preview = () => {
  const [activeDeviceView, setActiveDeviceView] = useState('Desktop');
  const [formData, setFormData] = useState([]);

  const { selectedOpenData, setSections, setSectionIndex, selectedStatusTab } = useFormBuilderStore();

  const navigate = useNavigate();

  // based on url id
  const urlParams = new URLSearchParams(window.location.search);
  const openId = urlParams.get('id');

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        if (selectedOpenData && selectedOpenData?.length > 0) {
          const serverClient = new ServerClient('/api/getTemplateById');
          const targetId = selectedOpenData[0];
          const res = await serverClient.post({ id: targetId });

          const title = res.data?.[0]?.formTitle;
          setFormData(title);

          const sectionsData = await JSON.parse(res.data?.[0]?.sectionsData);
          if (sectionsData) {
            setSections(sectionsData);
            setSectionIndex(0);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchFormData();
  }, [selectedOpenData]);

  return (
    <div className="balance-preview-container">
      {/* header */}
      <header className="balance-preview-header">
        <div>
          {!openId ? (
            <img
              src={Arrow}
              className="balance-preview-back-arrow"
              alt="Icon"
              onClick={() => navigate('/Hub/CreateTemplatesInitial/Form-Builder')}
            />
          ) : (
            <img
              src={Arrow}
              className="balance-preview-back-arrow"
              alt="Icon"
              onClick={() => {
                if (selectedStatusTab === 'My Applications') {
                  navigate('/Hub/CreateTemplatesInitial#MyApplications');
                } else if (selectedStatusTab === 'Shared') {
                  navigate('/Hub/CreateTemplatesInitial#Shared');
                } else {
                  navigate('/Hub/CreateTemplatesInitial#Templates');
                }
              }}
            />
          )}
        </div>
        {!openId && (
          <div>
            <p className="balance-preview-header-title">Live Preview</p>
          </div>
        )}

        <div>
          {openId && (
            <div>
              <p className="balance-preview-header-title">{formData || 'Live Preview'}</p>
            </div>
          )}
        </div>
        <div className="space"></div>
      </header>
      <div className="balance-grid-preview-content balance-grid-layout-content">
        <div className="preview-page-rendering">
          {activeDeviceView === 'Mobile' && <MobileView />}
          {activeDeviceView === 'Desktop' && <DesktopView />}
        </div>
        <div className="balance-grid-layout-sidebar-nav-icon-container">
          {/* mobile, desktop */}
          {!openId && (
            <div className="balance-preview-device-button-container">
              <Button
                buttonName="Mobile"
                onClick={() => {
                  setActiveDeviceView('Mobile');
                }}
                className={`balance-preview-mobile-button ${activeDeviceView === 'Mobile' ? 'view-clicked' : ''}`}
              />
              <Button
                buttonName="Desktop"
                onClick={() => {
                  setActiveDeviceView('Desktop');
                }}
                className={`balance-preview-desktop-button ${activeDeviceView === 'Desktop' ? 'view-clicked' : ''}`}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Preview;
