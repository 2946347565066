import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ServerClient from '../../../../../services/api-client';
import useFormBuilderStore from '../../../../../store/useFormBuilderStore';
import CreatableSelect from 'react-select/creatable';
import Button from '../../../../../components/Button/Button';
import Modal from '../../../../../components/Popup/Modal';
import './EmailForm.css';
import BalanceBlueIcon from '../../../../../assets/Logo/Balance_Logo.svg';
import FormSentPopup from '../../../../../components/Popup/FormSentPopup';
import FormSentFailedPopup from '../../../../../components/Popup/FormSentFailedPopup';
import LoadingPopup from '../../../../../components/Popup/LoadingPopup';

const EmailForm = () => {
  // const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState('');
  const [isSentModalOpen, setSentModalOpen] = useState(false);
  const [errorSendingForm, setErrorSendingForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { linkData } = useFormBuilderStore();
  // const link = useFormBuilderStore((state) => state.link);

  // ref to reset form
  const resetRef = useRef();

  const navigate = useNavigate();

  // react hook form
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitted },
  } = useForm({ mode: 'onChange' });

  // useEffect(() => {
  //   axios
  //     .get('http://localhost:5000/emails')
  //     .then((res) => {
  //       setEmails(res.data);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetch emails:', error);
  //     });  // change this to serverClient eventually!
  // }, []);

  // email user selects
  const handleChangeSelect = (selectedOption) => {
    // console.log(selectedOption);
    setSelectedEmail(selectedOption);
    setValue('email', selectedOption.value);
  };

  // submit
  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      // const serverClient = new ServerClient('/api/generatedLink');
      // const res = await serverClient.post({ selectedData: data.selectedData });
      // const generatedLink = uuidv4();
      // const link = `balanceapp.io/Form/${generatedLink}`;
      // set generated link
      // if (res.ok) {
      //   const { link } = await res.json();
      //   setFormLink(link);
      // }

      const emailData = {
        selectedEmail: data.email,
        subject: data.subject,
        description: data.description,
        linkData: linkData,
      };

      // ready to send email
      const serverClient = new ServerClient('/api/send-email');
      const sendEmailResponse = await serverClient.post(emailData);

      // if email sent
      if (sendEmailResponse.status === 200) {
        // open sent modal
        setIsLoading(false);
        setSentModalOpen(true);
        // reset the form
        reset();
      } else {
        console.error('Failed to send email');
      }
    } catch (error) {
      setErrorSendingForm(true);
      reset();
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // navigate to templates landing page
  const closeSentModal = () => {
    navigate('/Hub/CreateTemplatesInitial');
  };

  return (
    <div className='balance-share-tab-email-container'>
      <form
        ref={resetRef}
        onSubmit={handleSubmit(onSubmit)}
        className='balance-share-tab-form'
      >
        {/* select dropdown */}
        <label htmlFor='email' className='balance-share-tab-label'>
          Client
          {isSubmitted && !errors.email && (
            <span className='balance-validation-error-marker'>*</span>
          )}
        </label>
        <CreatableSelect
          id='email'
          value={selectedEmail}
          placeholder='Select or type email...'
          onChange={handleChangeSelect}
          // options={emails.map((user) => ({
          //   value: user.email,
          //   label: user.email,
          // }))}
        />
        {/* subject */}
        <label htmlFor='subject' className='balance-share-tab-label'>
          Subject
          {isSubmitted && errors.subject && (
            <span className='balance-validation-error-marker'>*</span>
          )}
        </label>
        <input
          id='subject'
          type='text'
          placeholder='Subject'
          className='balance-share-tab-input'
          {...register('subject', { required: true })}
        />
        {/* description */}
        <label htmlFor='description' className='balance-share-tab-label'>
          Description
          {isSubmitted && errors.description && (
            <span className='balance-validation-error-marker'>*</span>
          )}
        </label>
        <textarea
          id='description'
          cols='30'
          rows='5'
          placeholder='Enter a description...'
          className='balance-share-tab-description'
          {...register('description', { required: true })}
        ></textarea>
        <Button
          buttonName='Send'
          className='balance-global-blue-button balance-global-movable-button'
        />
        <p className='balance-share-tab-name'>
          Powered by
          <img
            src={BalanceBlueIcon}
            alt='Icon'
            className='balance-share-tab-logo'
          />
        </p>
      </form>
      {/* popup after email sent */}
      {isSentModalOpen && (
        <Modal onClose={closeSentModal}>
          <FormSentPopup />
        </Modal>
      )}
      {errorSendingForm && (
        <Modal onClose={closeSentModal}>
          <FormSentFailedPopup />
        </Modal>
      )}
      {isLoading && (
        <Modal onClose={closeSentModal}>
          <LoadingPopup />
        </Modal>
      )}
    </div>
  );
};

export default EmailForm;
