import React from 'react';
import { nanoid } from 'nanoid';
import { Icon } from '@iconify/react';
import Button from '../../../../components/Button/Button';
import './ApplicationBuilder.css';
import AppImage from '../../../../assets/Image/ApplicationBuilder/App.png';

const ApplicationBuilder = () => {
  const data1 = [
    {
      id: nanoid(),
      sub_title: 'Template Creation',
      desc: "Create application templates effortlessly with our automated system. Our intuitive interface guides you through the process, allowing you to quickly generate templates for various banking services. Whether it's for retail or commercial clients, our tool makes template creation a breeze.",
      feature_title_1: 'Automated Templates',
      feature_title_2: 'Intuitive Interface',
      feature_desc_1: 'Swiftly generate templates tailored to different banking services.',
      feature_desc_2: 'User-friendly design ensures a smooth template creation process.',
      icon_1: 'gg:template',
      icon_2: 'material-symbols:mobile-friendly-rounded',
    },
    {
      id: nanoid(),
      sub_title: 'Customization',
      desc: 'Tailor your templates to fit the unique needs of your business and clients. Customize fields, add branding elements, and incorporate compliance requirements with ease.',
      feature_title_1: 'Flexible Customization',
      feature_title_2: 'Brand Integration',
      feature_title_3: 'Compliance Ready',
      feature_desc_1: 'Adjust templates to meet specific business requirements.',
      feature_desc_2: 'Seamlessly incorporate your branding elements.',
      feature_desc_3: 'Ensure all templates meet regulatory standards.',
      icon_1: 'gridicons:customize',
      icon_2: 'ic:outline-business',
      icon_3: 'grommet-icons:compliance',
    },
    {
      id: nanoid(),
      sub_title: 'Reusability',
      desc: 'Maximize efficiency by reusing and repurposing templates across various banking services. Our tool allows you to maintain consistency while saving time and effort.',
      feature_title_1: 'Efficient Reuse',
      feature_title_2: 'Consistency Guaranteed',
      feature_desc_1: 'Apply templates across different services to streamline operations.',
      feature_desc_2: 'Maintain uniformity in all client interactions.',
      icon_1: 'dashicons:update',
      icon_2: 'material-symbols:verified-outline',
    },
    {
      id: nanoid(),
      sub_title: 'Personalization',
      desc: 'Enhance your customer experience with personalized templates. Ensure each application adheres to standardized formats and branding, reinforcing your business identity.',
      feature_title_1: 'Standardized Formats',
      feature_title_2: 'Brand Consistency',
      feature_desc_1: 'Keep applications uniform and professional.',
      feature_desc_2: 'Strengthen your business identity with personalized templates.',
      icon_1: 'gg:format-right',
      icon_2: 'material-symbols:note-outline',
    },
  ];

  return (
    <div className="balance-features-application-builder-container">
      {/* section 1 */}
      <div className="balance-features-application-builder-section-1">
        <div className="balance-features-application-builder-section-1-inner-container balance-features-application-builder-left-container">
          <h1 className="balance-features-application-builder-title">Application Builder</h1>
          <h3 className="balance-features-application-builder-sub-title">Streamline. Customize. Personalize.</h3>
          <p className="balance-features-application-builder-desc">
            Welcome to the Application Builder, a powerful tool designed to transform the way you handle retail and commercial
            banking services. Our Application Builder simplifies the creation, customization, and reuse of application templates,
            ensuring a seamless and efficient onboarding experience.
          </p>
          {/* <Button buttonName="Quick Demo" className="balance-features-application-builder-button balance-global-movable-button" /> */}
        </div>
        <div className="balance-features-application-builder-section-1-inner-container balance-features-application-builder-right-container"></div>
      </div>
      {/* section 2 */}
      <div className="balance-features-application-builder-section-2">
        <div className="balance-features-application-builder-left-container">
          <div className="balance-features-application-builder-section-2-title-container">
            <h1 className="balance-features-application-builder-section-2-title">Features</h1>
          </div>
          <div>
            {data1.map((feature, index) => (
              <div className="balance-features-application-builder-section-2-inner-container">
                <h1 className="balance-features-application-builder-section-2-sub-title">{feature.sub_title}</h1>
                <p className="balance-features-application-builder-section-2-desc">{feature.desc}</p>
                <div className="balance-features-application-builder-box-container">
                  <div className="feature-box">
                    <div className="feature-box-title-container">
                      <Icon icon={feature.icon_1} className="feature-box-icon" />
                      <h3>{feature.feature_title_1}</h3>
                    </div>
                    <p className="feature-box-desc">{feature.feature_desc_1}</p>
                  </div>
                  <div className="feature-box">
                    <div className="feature-box-title-container">
                      <Icon icon={feature.icon_2} className="feature-box-icon" />
                      <h3>{feature.feature_title_2}</h3>
                    </div>
                    <p className="feature-box-desc">{feature.feature_desc_2}</p>
                  </div>
                  <div className="feature-box">
                    <div className="feature-box-title-container">
                      <Icon icon={feature.icon_3} className="feature-box-icon" />
                      <h3>{feature.feature_title_3}</h3>
                    </div>
                    <p className="feature-box-desc">{feature.feature_desc_3}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="balance-features-application-builder-section-2-inner-container balance-features-application-builder-section-2-right-container">
          <img src={AppImage} alt="Image" className="image" />
        </div>
      </div>
      {/* section 3 */}
      <div className="balance-features-application-builder-section-3">
        <div className="balance-features-application-builder-section-3-left-container"></div>
        <div className="balance-features-application-builder-section-3-right-container">
          <h1 className="balance-features-application-builder-section-3-title">Experience</h1>
          <p className="balance-features-application-builder-section-3-desc">
            Experience the future of banking services with our Application Builder. Simplify your onboarding process, ensure
            consistency, and provide a personalized touch for your clients. Embrace efficiency and flexibility with our innovative
            solution.
          </p>
          <div className="balance-features-application-builder-section-2-button-container">
            {/* <Button
              buttonName="Get started"
              className="balance-features-application-builder-section-3-button balance-global-movable-button"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationBuilder;
