import Button from '../Button/Button';
import './PopupGlobal.css';

const SingleButtonPopup = ({ onClose, status }) => {
  return (
    <div className="balance-confirm-popup-container">
      {status === 'success' ? (
        <div className="balance-invite-sent-container">
          <p className="balance-invite-sent-description">The invitation has been sent to your team member.</p>
          <div className="balance-invite-sent-button-container">
            <Button buttonName="OK" onClick={onClose} className="balance-invite-sent-ok-button" />
          </div>
        </div>
      ) : (
        <div className="balance-invite-error-container">
          <p className="balance-invite-error-description">Error sending the invitation. Please try again.</p>
          <div className="balance-invite-error-button-container">
            <Button buttonName="OK" onClick={onClose} className="balance-invite-error-ok-button" />
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleButtonPopup;
