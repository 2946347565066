import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import useUserStore from '../../../store/useUserStore';
import { useAccountDeactivate } from '../../../hooks/useAccountHooks';
import Button from '../../../components/Button/Button';
import Modal from '../../../components/Popup/Modal';
import DeletePopup from '../../../components/Popup/DeletePopup';
import './AccountDeactivation.css';

const AccountDeactivation = () => {
  const [isDeactivatePopupOpen, setDeactivatePopupOpen] = useState(false);
  const { userId } = useUserStore();
  const { mutate: accountDeactivateMutate } = useAccountDeactivate();
  const navigate = useNavigate();

  const handleDeactivateAccount = () => {
    accountDeactivateMutate(
      { userId },
      {
        onSuccess: () => {
          navigate('/Login');
        },
        onError: (error) => {
          if (error) {
            console.log('Failed deactivating an account.');
          } else {
            console.log('Failed deactivating an account:', error);
          }
        },
      }
    );
  };

  // popup
  const handleClickDeactivate = () => {
    setDeactivatePopupOpen(true);
  };
  const closeDeactivatePopup = () => {
    setDeactivatePopupOpen(false);
  };

  return (
    <div className="balance-settings-container">
      <div className="balance-settings-back-button-container" onClick={() => navigate('/Hub/Settings')}>
        <Icon icon="ic:sharp-arrow-back-ios" className="balance-settings-back-button" />
      </div>
      <div>
        <h1 className="balance-settings-title">Account Deactivation</h1>
        <p className="balance-settings-description">Manage your deactivation settings.</p>
        {/* line */}
        <div className="balance-settings-line"></div>

        <div className="balance-account-deactivation-contents-container">
          <div className="balance-account-deactivation-contents-inner-container">
            <h3 className="balance-account-deactivation-contents-title">Deactivate account</h3>
            <p className="balance-account-deactivation-contents-description">
              Your account and profile will be deactivated and your name and templates will be removed from most things you've shared.
            </p>
          </div>
          <div className="balance-account-deactivation-contents-button-container">
            <Button buttonName="Deactivate" className="balance-global-blue-button balance-global-movable-button balance-account-deactivation-delete-button" onClick={handleClickDeactivate} />
          </div>
        </div>
      </div>
      {isDeactivatePopupOpen && (
        <Modal width="35rem" onClose={closeDeactivatePopup}>
          <DeletePopup onClose={closeDeactivatePopup} checkConfirm="Are you sure you want to deactivate the account?" buttonName="Deactivate" onConfirm={handleDeactivateAccount} />
        </Modal>
      )}
    </div>
  );
};

export default AccountDeactivation;
