import useFormBuilderStore from '../../../../../store/useFormBuilderStore';
import { useHashTab } from '../../../../../hooks/useHashTab';
// components
import EmailForm from './EmailForm';
import LinkForm from './LinkForm';
import ShareLeft from '../../FormBuilder/SideLeft/ShareLeft';
// css
import '../../FormBuilder/FormBuilder.css';

const ShareTab = () => {
  const { activeShareTab, setActiveShareTab, leftSidebarOpen, selectedOption } =
    useFormBuilderStore();

  // set # to share tab link hook
  useHashTab(setActiveShareTab, 'Share');

  return (
    <div className='balance-form-builder-container'>
      <header className='balance-form-builder-header'>
        <div></div>
        <div
          onClick={() => setActiveShareTab('Share')}
          className={`item ${activeShareTab === 'Share' ? 'active-page' : ''}`}
        >
          <a href='#Share' className='balance-form-builder-header-middle-share'>
            Share
          </a>
        </div>
        <div></div>
      </header>
      <div
        className={`balance-grid-layout-sidebar balance-grid-layout-sidebar-left ${
          !leftSidebarOpen ? 'closed' : ''
        }`}
      >
        {activeShareTab === 'Share' && <ShareLeft />}
      </div>
      <div
        className={`balance-grid-layout-content ${
          leftSidebarOpen ? '' : 'closed'
        }`}
      >
        {/* contents */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* {selectedOption === 'Email' ? <EmailForm /> : <LinkForm />} */}
          <EmailForm />
        </div>
      </div>
    </div>
  );
};

export default ShareTab;
