// import React from 'react';
import './PopupGlobal.css';

const FormSentPopup = ({ isSupport }) => {
  return (
    <div className="balance-form-sent-popup-container">
      {isSupport ? (
        <div className="balance-form-sent-popup-support-inner-container">
          <p className="balance-form-sent-popup-message">Email successfully sent!</p>
          <p className='balance-form-sent-popup-thanks-message'>Thank you for your inquiry.<br />We will get back to you shortly.</p>
        </div>
      ) : (
        <p className="balance-form-sent-popup-message">Email successfully sent!</p>
      )}
    </div>
  );
};

export default FormSentPopup;
