import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// store
import useUserStore from '../../../store/useUserStore';
import useNotificationStore from '../../../store/useNotificationStore';
// hook
import { useRetrieveNotifications, useMarkNotificationsAsRead } from '../../../hooks/useNotificationHooks';
import { usePagination } from '../../../hooks/usePagination';
// components
import Pagination from '../../../components/Pagination/Pagination';
import Button from '../../../components/Button/Button';
import './Notifications.css';

const Notifications = () => {
  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6;
  const { data: paginationData } = usePagination(currentPage, pageSize);
  // store
  const { userId } = useUserStore();
  const { notifications, setNotifications, markNotificationAsRead } = useNotificationStore();
  const { data: retrieveNotificationsData } = useRetrieveNotifications(); // retrieve notifications
  const { mutate: markAsReadMutate } = useMarkNotificationsAsRead(); // mark as read
  const [filterNotifications, setFilterNotifications] = useState('All'); // all, unread, read filtering

  // display notifications
  useEffect(() => {
    if (retrieveNotificationsData) {
      setNotifications(retrieveNotificationsData);
    }
  }, [retrieveNotificationsData, setNotifications]);

  // handle click mark as read
  const handleMarkAsRead = (notificationId) => {
    console.log('notificationId', notificationId);
    markAsReadMutate(
      { userId, notificationId },
      {
        onSuccess: () => {
          markNotificationAsRead(notificationId);
          console.log('Notification marked as read.');
        },
      }
    );
  };

  // filtering notifications
  const filteredNotifications = notifications.filter((notification) => {
    if (filterNotifications === 'All') return true;
    if (filterNotifications === 'Unread') return !notification.isRead;
    if (filterNotifications === 'Read') return notification.isRead;
    return true;
  });

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const paginationNotifications = filteredNotifications.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  // time display
  const timeAgo = (date) => {
    const now = new Date();
    const difference = now - new Date(date);
    const seconds = Math.floor(difference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds < 90) {
      return 'Just now';
    } else if (minutes < 60) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    }
  };

  return (
    <div className="balance-notifications-container">
      <div className="balance-notifications-top-container">
        <div className="balance-notifications-title-button-container">
          <h1 className="balance-notifications-title">Notifications</h1>
          <div className="balance-notifications-button-container">
            <Button
              buttonName="All"
              className={`balance-notifications-all-button ${
                filterNotifications === 'All' ? 'active-background-gray' : ''
              }`}
              onClick={() => setFilterNotifications('All')}
            />
            <Button
              buttonName="Unread"
              className={`balance-notifications-unread-button ${
                filterNotifications === 'Unread' ? 'active-background-gray' : ''
              }`}
              onClick={() => setFilterNotifications('Unread')}
            />
            <Button
              buttonName="Read"
              className={`balance-notifications-read-button ${
                filterNotifications === 'Read' ? 'active-background-gray' : ''
              }`}
              onClick={() => setFilterNotifications('Read')}
            />
          </div>
        </div>
      </div>
      <div className="balance-notifications-contents-container">
        {paginationNotifications.length > 0 ? (
          paginationNotifications.map((notification, index) => (
            <div
              key={index}
              onClick={() => handleMarkAsRead(notification.uniqueId)}
              className={`balance-notifications-contents ${!notification.isRead ? 'unread-background' : ''}`}
            >
              <span className="balance-notifications-dot"></span>
              <span className="balance-notifications-name">
                {notification.type === 'clientApplicationSubmission'
                  ? notification.clientName
                  : notification.senderName}
              </span>
              <span className="balance-notifications-done">
                {(notification.type === 'taskAssigned' && (
                  <span>
                    assigned a
                    <span className="balance-notifications-link">
                      <Link to="/Hub/Tasks" className='notification-link'>task</Link>
                    </span>
                    to you
                  </span>
                )) ||
                  (notification.type === 'taskRejected' && (
                    <span>
                      rejected your
                      <span className="balance-notifications-link">
                        <Link to="/Hub/Tasks" className='notification-link'>task</Link>
                      </span>
                      a reason for {`"${notification.desc}"`}
                    </span>
                  )) ||
                  (notification.type === 'statusChange' && (
                    <span>
                      changed a status in your
                      <span className="balance-notifications-link">
                        <Link to="/Hub/Tasks" className='notification-link'>task</Link>
                      </span>
                      to you
                    </span>
                  )) ||
                  (notification.type === 'clientApplicationSubmission' && (
                    <span>
                      submitted your
                      <span className="balance-notifications-link">
                        <Link to="/Hub/Clients" className='notification-link'>application</Link>
                      </span>
                      to you
                    </span>
                  )) ||
                  (notification.type === 'shareTemplates' && (
                    <span>
                      shared a
                      <span className="balance-notifications-link">
                        <Link to="/Hub/CreateTemplatesInitial" className='notification-link'>template</Link>
                      </span>
                      with you
                    </span>
                  )) ||
                  (notification.type === 'Application' && (
                    <span>
                      shared an
                      <span className="balance-notifications-link">
                        <Link to="/Hub/Clients" className='notification-link'>application</Link>
                      </span>
                      with you
                    </span>
                  )) ||
                  (notification.type === 'Document' && (
                    <span>
                      shared a
                      <span className="balance-notifications-link">
                        <Link to="/Hub/Clients" className='notification-link'>document</Link>
                      </span>
                      with you
                    </span>
                  ))}
              </span>
              <p className="balance-notifications-time">{timeAgo(notification.assignedDate)} </p>
            </div>
          ))
        ) : (
          <div
            style={{ display: 'flex', justifyContent: 'center', color: 'gray', fontStyle: 'italic', marginTop: '5rem' }}
          >
            <p>No notifications to display</p>
          </div>
        )}
      </div>
      <Pagination
        currentPage={currentPage}
        handleChangePage={handleChangePage}
        pageSize={pageSize}
        totalCount={paginationData?.totalCount}
      />
    </div>
  );
};

export default Notifications;
