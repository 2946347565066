import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useModalStore from '../../../../store/useModalStore';
import useFormBuilderStore from '../../../../store/useFormBuilderStore';
import Modal from '../../../../components/Popup/Modal';
import CreatePopup from '../../../../components/Popup/CreatePopup';
import CreateTypePopup from '../../../../components/Popup/CreateTypePopup';
import CreateApplication from '../../../../components/Popup/CreateApplication';
import ShareTeam from '../../../../components/Popup/ShareTeam';
import Button from '../../../../components/Button/Button';
import AddIcon from '../../../../assets/Icon/Add_Icon_White.svg';

const TemplatePopups = () => {
  const { isModalOpen, openModal, isSelectedTemplate, closeModal } =
    useModalStore();
  const { setSelectedEditData } = useFormBuilderStore();

  const location = useLocation();

  const handleOpenModal = () => {
    openModal();
    setSelectedEditData([]);
  };

  // prevent display popup when templates tab is clicked
  // set to CreateTemplatesInitial page when templates clicked
  useEffect(() => {
    if (location.pathname.includes('/Hub/CreateTemplatesInitial')) {
      closeModal();
    }
  }, [location, closeModal]);

  // set render popup contents
  const renderNextContents = () => {
    switch (isSelectedTemplate) {
      case 'CreateTypePopup':
        return <CreateTypePopup />;
      case 'CreateApplication':
        return <CreateApplication />;
      case 'ShareTeam':
        return <ShareTeam />;
      default:
        return <CreatePopup />;
    }
  };

  // popup width
  let modalWidth = '27rem';
  if (isSelectedTemplate === 'CreateTypePopup') modalWidth = '25rem';
  else if (
    isSelectedTemplate === 'CreateApplication' ||
    isSelectedTemplate === 'ShareTeam'
  )
    modalWidth = '32rem';

  return (
    <div>
      <Button
        buttonName='New'
        icon={AddIcon}
        iconPosition='before'
        onClick={handleOpenModal}
        className='balance-create-templates-layout-new-button same-style-button balance-global-movable-button'
      />
      {isModalOpen && <Modal width={modalWidth}>{renderNextContents()}</Modal>}
    </div>
  );
};

export default TemplatePopups;
