import { create } from 'zustand';

const useTaskStore = create((set) => ({
  selectedStatusTab: 'My Tasks',
  selectedTaskId: sessionStorage.getItem('selectedTaskId') || '',
  textarea: '',

  setSelectedStatusTab: (tab) => set({ selectedStatusTab: tab }),
  setSelectedTaskId: (taskId) => {
    sessionStorage.setItem('selectedTaskId', taskId);
    set({ selectedTaskId: taskId });
  },
  setTextArea: (text) => set({ textarea: text }),
}));

export default useTaskStore;
