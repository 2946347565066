import './Footer.css';
import BalanceBlueLogo from '../../assets/Logo/Balance_Logo.svg';

const Footer = () => {
  return (
    <footer className="balance-footer-container">
      <p className="balance-footer">
        Powered by
        <img src={BalanceBlueLogo} alt="Icon" className="balance-share-tab-logo" />
      </p>
    </footer>
  );
};

export default Footer;
