import './DocumentUploadInput.css';
import S3ServerClient from '../../services/s3-client';
import { useState } from 'react';

const DocumentUploadInput = ({inputId, callbackFunc}) => {
    const [isLoading, setIsLoading] = useState();
    const [fileName, setFilename] = useState('')
    const [displayFileName, setDisplayFileName] = useState('');
    const [error, setError] = useState(false);

    const handleFileSelection = async (event) => {
        console.log('event.target.files: ', event.target?.files);
        setIsLoading(true);
        setError(false);
        try {
            const formData = new FormData();
            formData.append('file', event.target?.files[0]);
            const serverClient = new S3ServerClient('/api/saveFile');
            const res = await serverClient.post(formData);
            setFilename(res.data?.uniqueFilename);
            setDisplayFileName(res.data?.originalFilename);
            const fileData = JSON.stringify(res.data);
            callbackFunc(inputId, fileData); // return this filename to the main form
        } catch (err) {
            console.log('err in sending file: ', err);
            setError(true);
        } finally {
            setIsLoading(false);
        }
      }

    return (
        <div className='document-upload-container'>
            <input
                id={`${inputId}-hidden`}
                type='file'
                accept='image/*,.pdf'
                onChange={(e) => handleFileSelection(e)}
                style={{ display: 'none' }}
            />
            <label
                htmlFor={`${inputId}-hidden`}
                className='document-style-button'
            >{displayFileName ? <div className='document-upload-input-text document-upload-input-filename'>{displayFileName}</div> : isLoading ? <div className='document-upload-input-text document-upload-input-loading'>Loading...</div> : error ? <div className='document-upload-input-text document-upload-input-error'>Error uploading documentation. Please try again.</div> : <div className='document-upload-input-text document-upload-input-add'>Add +</div>}</label>
        </div>
    )
}

export default DocumentUploadInput;