import { useState, useEffect } from 'react';
import Switch, { switchClasses } from '@mui/joy/Switch';
import useFormBuilderStore from '../../../../../store/useFormBuilderStore';
// import Select from 'react-select';
import './SideRight.css';

const SideRight = () => {
  const [checked, setChecked] = useState(false);
  // const options = [{ value: 'Input', label: 'Input' }];
  const { selectedQuestionIndex, selectedSectionIndex, sections, toggleQuestionRequired } = useFormBuilderStore();

  useEffect(() => {
    // this is probably a ppor way to do this but I don't know a better way rn
    setChecked(sections[selectedSectionIndex]?.sectionInputs[selectedQuestionIndex]?.inputIsRequired)
  }, [selectedQuestionIndex]);

  const handleToggleClick = (e) => {
    if (selectedQuestionIndex === null) {
      console.log('selected a question first...');
      return;
    }
    // console.log(`setting Section: ${selectedSectionIndex}, Question: ${selectedQuestionIndex} as required = ${e.target.checked}`);
    setChecked(e.target.checked);
    toggleQuestionRequired(selectedSectionIndex, selectedQuestionIndex, e.target.checked);
    // console.log('target checked is: ', e.target.checked)
  }

  return (
    <div className={`balance-global-sidebar-right-container ${selectedQuestionIndex === null && `balance-global-sidebar-right-container-dulled`}`}>
      {/* * * * removed for now */}
      {/* <p className='balance-global-sidebar-right-title'>Question</p>
      <div>
        <p className='balance-global-sidebar-right-sub-title-type'>Type</p>
        <Select
          options={options}
          placeholder={null}
          className='balance-global-sidebar-right-dropdown'
        />
      </div>
      <hr />
      <div>
        <p className='balance-global-sidebar-right-sub-title-settings'>
          Settings
        </p> */}
      <div className='balance-global-sidebar-right-toggle-container'>
        <span className='balance-global-sidebar-right-toggle-required'>
          Required
        </span>
        {/* toggle switch */}
        <Switch
          checked={checked}
          onChange={(event) => handleToggleClick(event)}
          sx={(theme) => ({
            '--Switch-thumbSize': '16px',
            '--Switch-trackWidth': '34px',
            '--Switch-trackHeight': '20px',
            '--Switch-trackBackground': '#CBD5E0',
            '&:hover': {
              '--Switch-trackBackground': '#CBD5E0',
            },
            [`&.${switchClasses.checked}`]: {
              '--Switch-trackBackground': '#3182ce',
              '&:hover': {
                '--Switch-trackBackground': '#3182ce',
              },
              [`&.${switchClasses.disabled}`]: {
                '--Switch-trackBackground': '#3182ce',
              },
            },
            [`&.${switchClasses.disabled}`]: {
              '--Switch-trackBackground': '#CBD5E0',
              opacity: 0.4,
            },
            [theme.getColorSchemeSelector('dark')]: {
              '--Switch-trackBackground': 'rgba(255, 255, 255, 0.24)',
              [`&.${switchClasses.checked}`]: {
                '--Switch-trackBackground': '#90cdf4',
                '&:hover': {
                  '--Switch-trackBackground': '#90cdf4',
                },
                [`&.${switchClasses.disabled}`]: {
                  '--Switch-trackBackground': '#3182ce',
                },
              },
            },
          })}
        />
      </div>
    </div>
  );
};

export default SideRight;
