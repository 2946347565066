import { create } from 'zustand';
import { persist } from 'zustand/middleware';

// const useUserStore = create((set) => ({
//     userId: null,
//     userToken: null,
//     userEmail: null,
//     userName: null,
//     userAvatarLink: null,
//     userCompany: null,
//     setUserId: (id) => set({ userId: id }),
//     setUserToken: (token) => set({ userToken: token }),
//     setUserEmail: (email) => set({ userEmail: email }),
//     setUserName: (name) => set({ userName: name }),
//     setUserAvatar: (link) => set({ userAvatarLink: link }),
//     setUserCompany: (company) => set({ userCompany: company }),
// }))

const useUserStore = create(
  // save to local storage
  persist(
    (set, get) => ({
      userId: null,
      userToken: null,
      userEmail: null,
      userName: null,
      userAvatarLink: null,
      userCompany: null,
      userCreated: null,
      isAdmin: null,
      userTFAFlag: false,
      userPaymentPlan: 'Free',
      templatePermissions: {
        canEdit: false,
        // canRead: false,
        canDelete: false,
        canShare: false,
      },
      clientPermissions: {
        canEdit: false,
        // canRead: false,
        canDelete: false,
        canShare: false,
      },
      taskPermissions: {
        canEdit: false,
        canDelete: false,
        canShare: false,
      },
      userData: [],
      selectedUserId: '',
      taskData: [],
      setUserId: (id) => set({ userId: id }),
      setUserToken: (token) => set({ userToken: token }),
      setUserEmail: (email) => set({ userEmail: email }),
      setUserName: (name) => set({ userName: name }),
      setUserAvatar: (link) => set({ userAvatarLink: link }),
      setUserCompany: (company) => set({ userCompany: company }),
      setUserCreated: (date) => set({ userCreated: date }),
      setAdmin: (role) => set({ isAdmin: role }),
      setUserPaymentPlan: (plan) => set({ userPaymentPlan: plan }),
      UserLogout: () => {
        set(() => ({
          userToken: null,
          userId: null,
          userEmail: null,
          userName: null,
          userAvatarLink: null,
          userCompany: null,
        }));
        localStorage.removeItem('balance-account');
      },
      setTemplatePermissions: (newPermissions) => set({ templatePermissions: newPermissions }),
      setClientPermissions: (newPermissions) => set({ clientPermissions: newPermissions }),
      setTaskPermissions: (newPermissions) => set({ taskPermissions: newPermissions }),
      setUserData: (data) => set({ userData: data }), // user data
      setSelectedUserId: (id) => set({ selectedUserId: id }), // selectedId
      setTaskData: (data) => set({ taskData: data }), // task data
      setUserTFAFlag: (data) => set({ userTFAFlag: data }),
    }),
    {
      name: 'balance-account',
    }
  )
);

export default useUserStore;
