import React from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useUpDatePaymentMethod } from '../../../../hooks/useStripeHooks';
import useUserStore from '../../../../store/useUserStore';
import Button from '../../../../components/Button/Button';

const PaymentUpdateForm = ({ setMessage }) => {
  const { userEmail } = useUserStore();
  const { mutate } = useUpDatePaymentMethod();

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      setMessage({ text: 'Please try again.', type: 'error' });
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        email: userEmail,
      },
    });

    if (error) {
      console.error('Error updating card.', error);
      setMessage({ text: 'Error updating card.', type: 'error' });
    } else {
      console.log('Card updated successfully.', paymentMethod);

      mutate(
        { paymentMethodId: paymentMethod.id, userEmail },
        {
          onSuccess: () => {
            setMessage({ text: 'Card updated successfully.', type: 'success' });
          },
          onError: (mutationError) => {
            console.error('Error updating card.', mutationError);
            setMessage({ text: 'Failed to update card.', type: 'error' });
          },
        }
      );
    }
  };

  const handleChangeInput = (e) => {
      setMessage({ text: '', type: '' });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <CardElement options={{ hidePostalCode: true }} onChange={handleChangeInput} />
        <div className="balance-payment-methods-save-button-container">
          <Button
            buttonName="Save"
            className="balance-payment-methods-save-button balance-global-blue-button balance-global-movable-button"
          />
        </div>
      </form>
    </div>
  );
};

export default PaymentUpdateForm;
