import React from 'react';
import ForgotForm from '../Forgot/ForgotForm';

const ForgotUserPassword = () => {
  return (
    <div>
      <ForgotForm
        formTitle='Forgot your password?'
        subText='No worries! Enter your email below to receive a reset link.'
        questionText='Remember your password?'
      />
    </div>
  );
};

export default ForgotUserPassword;
