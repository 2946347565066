import './SendingLoader.css';

const SendingLoader = () => {
  return (
    <div className="balance-confirm-popup-container">
      <div>
        <p className='balance-sending-loader-description'>Sending your invites. Just a moment...</p>
      </div>
      <div className="sending-loader"></div>
    </div>
  );
};

export default SendingLoader;
