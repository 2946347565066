import { nanoid } from 'nanoid';
import TemplateFeature1 from '../../assets/Icon/Template_Feature_Icon_1.svg';
import TemplateFeature2 from '../../assets/Icon/Template_Feature_Icon_2.svg';
import TemplateFeature3 from '../../assets/Icon/Template_Feature_Icon_3.svg';

export function getPopupData() {
  return [
    {
      id: nanoid(),
      buttonName: 'Business Checking template',
      icon: TemplateFeature1,
    },
    {
      id: nanoid(),
      buttonName: 'Business Financing template',
      icon: TemplateFeature2,
    },
  ];
}

// first row data of add questions popup
export function getAddQuestionsModalTopData() {
  return [
    {
      id: nanoid(),
      title: 'Dropdown',
      lists: [
        { id: nanoid(), name: 'Suffix', type: 'suffixDropD' },
        { id: nanoid(), name: 'Citizenship Status', type: 'citizenshipDropD' },
        { id: nanoid(), name: 'State', type: 'stateDropD' },
        // { id: nanoid(), name: 'Country', type: 'countryDropD' },
      ],
    },
    // {
    //   id: nanoid(),
    //   title: 'Contact info',
    //   lists: [
    //     { id: nanoid(), name: 'Address' },
    //     { id: nanoid(), name: 'Email' },
    //     { id: nanoid(), name: 'Phone number' },
    //     { id: nanoid(), name: 'Website' },
    //   ],
    // },
    // {
    //   id: nanoid(),
    //   title: 'Choices',
    //   lists: [
    //     { id: nanoid(), name: 'Multiple choice' },
    //     { id: nanoid(), name: 'Yes / No' },
    //   ],
    // },
    {
      id: nanoid(),
      title: 'File upload',
      lists: [{ id: nanoid(), name: 'Documents', type: 'documents' }],
    },
  ];
}

// second row data of add questions popup
export function getAddQuestionsModalBottomData() {
  return [
    {
      id: nanoid(),
      title: 'Text',
      lists: [
        { id: nanoid(), name: 'Short text', type: 'shortText' },
        { id: nanoid(), name: 'Long text', type: 'longText' },
      ],
    },
    // {
    //   id: nanoid(),
    //   title: 'Date & scheduling',
    //   lists: [{ id: nanoid(), name: 'Date' }],
    // },
    // {
    //   id: nanoid(),
    //   title: 'Legal & consent',
    //   lists: [{ id: nanoid(), name: 'Legal' }],
    // },
  ];
}
